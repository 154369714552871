import { request } from 'Resources/AxiosUtils';
import { JSONType, Params } from 'Typings/@types';
import { Company, CompanyListicle } from './@types';
import { parseToCompany, parseToCompanyListicle } from './CompanyParsers';

class CompanyModel {
	static getAllCompany = async (params?: Params): Promise<Company[]> => {
		const res = await request<JSONType[]>({
			url: '/companies',
			method: 'GET',
			params,
		});
		return res.map(parseToCompany);
	};

	static getCompany = async (id: string, params?: Params): Promise<Company> => {
		const res = await request<JSONType>({
			url: `/companies/${id}`,
			method: 'GET',
			params,
		});
		return parseToCompany(res);
	};

	static getCompanyListicles = async (params?: Params): Promise<CompanyListicle[]> => {
		const res = await request<JSONType[]>({
			url: 'companyListicles',
			method: 'GET',
			params,
		});
		return res.map(parseToCompanyListicle);
	};

	static followCompany = async (id: string): Promise<JSONType> => {
		const subscription = await request<JSONType>({
			url: `companies/${id}/follow`,
			method: 'POST',
		});
		return subscription;
	};

	static unFollowCompany = async (followId: string): Promise<{ count: number }> => {
		const subscription = await request<{ count: number }>({
			url: `follows/${followId}/unfollow`,
			method: 'DELETE',
		});
		return subscription;
	};
}

export default CompanyModel;

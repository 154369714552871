import { action, Action, thunk, Thunk } from 'easy-peasy';
import JobListingModel from 'Models/JobListing';
import { Job } from 'Models/JobListing/@types';
import { RootStore } from 'Stores';
import { Params } from 'Typings/@types';

export interface JobListingState {
	jobList: Job[];
	job?: Job;
	getJob: Thunk<JobListingState, { listingSlug: string; params?: Params }, unknown, RootStore, Promise<Job>>;
	getJobList: Thunk<JobListingState, { params?: Params }, unknown, RootStore, Promise<Job[]>>;
	setJob: Action<JobListingState, Job>;
	setJobList: Action<JobListingState, Job[]>;
}

const JobListingStore: JobListingState = {
	jobList: [],
	job: undefined,
	getJob: thunk(async (actions, { listingSlug, params }) => {
		const job = await JobListingModel.getJobListing(listingSlug, params);
		actions.setJob(job);
		return job;
	}),
	getJobList: thunk(async (actions, { params }) => {
		const jobListings = await JobListingModel.getAllJobListing(params);
		actions.setJobList(jobListings);
		return jobListings;
	}),
	setJob: action((state, jobListing) => {
		state.job = jobListing;
	}),
	setJobList: action((state, jobList) => {
		state.jobList = jobList;
	}),
};

export default JobListingStore;

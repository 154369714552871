import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
/**
 * This component is for combining link from react-router-dom and button from material-ui. For links that lead outside the website,
 * use mui's Button component and supply href prop to it.
 */
export interface LinkButtonProps {
	path: string;
	variant?: ButtonProps['variant'];
	className?: string;
	onClick?: (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
	size?: ButtonProps['size'];
	fullWidth?: ButtonProps['fullWidth'];
	disabled?: boolean;
}

const LinkButton: FC<LinkButtonProps> = ({ path, variant = 'contained', size = 'large', children, className, onClick, fullWidth }) => {
	const classes = styles({ variant });
	return (
		<Button variant={variant} color="primary" size={size} component={Link} to={path} className={clsx(classes.button, className)} onClick={onClick} fullWidth={fullWidth}>
			{children}
		</Button>
	);
};

export default LinkButton;

const styles = makeStyles<Theme, { variant: ButtonProps['variant'] }>((theme) => ({
	button: {
		fontWeight: theme.typography.fontWeightRegular,
		'&:visited': {
			color: ({ variant }) => (variant === 'contained' ? theme.palette.common.white : theme.palette.primary.main),
		},
	},
}));

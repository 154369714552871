import { Thunk, Action, thunk, action } from 'easy-peasy';
import PostModel from 'Models/Post';
import { Post } from 'Models/Post/@types';
// eslint-disable-next-line import/no-cycle
import { RootStore } from 'Stores';
import { Params } from 'Typings/@types';

export interface PostState {
	post?: Post;
	userPosts?: Post[];
	getPost: Thunk<PostState, { postSlug: string; params?: Params }, null, RootStore, Promise<Post>>;
	getUserPosts: Thunk<PostState, { userSlug: string; params?: Params }, null, RootStore, Promise<Post[]>>;
	setPost: Action<PostState, Post | undefined>;
	setUserPosts: Action<PostState, Post[] | undefined>;
}

const PostStore: PostState = {
	post: undefined,
	userPosts: [],
	getPost: thunk(async (actions, args) => {
		const post = await PostModel.getPost(args.postSlug, args.params);
		actions.setPost(post);
		return post;
	}),
	setPost: action((state, post) => {
		state.post = post;
	}),
	getUserPosts: thunk(async (actions, args) => {
		const posts = await PostModel.getUserPosts(args.userSlug, args.params);
		actions.setUserPosts(posts);
		return posts;
	}),
	setUserPosts: action((state, payload) => {
		state.userPosts = payload;
	}),
};

export default PostStore;

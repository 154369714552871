import HelperUtils from 'Utils/helpers';
import { Analytics } from 'analytics';
import { action, Action, thunk, Thunk } from 'easy-peasy';
import isEmpty from 'lodash/isEmpty';
import { Token } from 'Models/Auth/@types';
import { ChapterModel } from 'Models/Chapter';
import MicroCourseModel from 'Models/MicroCourse';
import { MicroCourse } from 'Models/MicroCourse/@types';
import UserModel from 'Models/User';
import { User } from 'Models/User/@types';
import { parseToUser } from 'Models/User/UserParsers';
import AxiosUtils from 'Resources/AxiosUtils';
// eslint-disable-next-line import/no-cycle
import { RootStore } from 'Stores';
// import Cookie from 'universal-cookie';
// eslint-disable-next-line import/no-cycle
import Utils from 'Utils';
import PathwayModel from 'Models/Pathway';
import { Pathway } from 'Models/Pathway/@types';
// eslint-disable-next-line import/no-cycle
import { RegisterFormValues } from 'Screens/Register';

// const cookie = new Cookie();

export const COOKIES_ACCESS_TOKEN = 'access_token';
export const COOKIES_USER_ID = 'user_id';
export const COOKIES_USERID = 'userId';

export interface AuthState {
	token: Token | null;
	user: User | null;
	userActivePathways?: Pathway[];
	userOngoingMicroCourses?: MicroCourse[];
	tempToken?: Token;
	setAuthInfo: Thunk<AuthState, Partial<Token>, null, RootStore, Promise<User | null>>;
	loadAuthInfo: Thunk<AuthState, Record<string, never> | undefined, null, RootStore, Promise<User | null>>;
	login: Thunk<AuthState, { email: string; password: string }, null, RootStore, Promise<Token>>;
	signUp: Thunk<AuthState, RegisterFormValues, null, RootStore, Promise<{ token: Token; user: User }>>;
	logout: Thunk<AuthState, undefined, null, RootStore>;
	updateUser: Thunk<AuthState, Partial<User>, null, RootStore, Promise<User>>;
	publishChaptersOnLS: Thunk<AuthState, void, null, RootStore>;
	updatePassword: Thunk<AuthState, { oldPassword: string; newPassword: string }, null, RootStore, Promise<boolean>>;
	resetPassword: Thunk<AuthState, string, null, RootStore, Promise<boolean>>;
	verifyToken: Thunk<AuthState, { email: string; code: string }, null, RootStore, Promise<User>>;
	setPasswordAfterForget: Thunk<AuthState, string, null, RootStore, Promise<boolean | void>>;
	addMicroCourseToOngoing: Action<AuthState, MicroCourse>;
	setToken: Action<AuthState, Partial<Token> | null>;
	setUser: Action<AuthState, User | null>;
	removeAuthInfo: Action<AuthState, void>;
	setTempToken: Action<AuthState, Token | undefined>;
	setUserOngoingMicroCourses: Action<AuthState, MicroCourse[] | undefined>;
}

const AuthStore: AuthState = {
	token: null,
	user: null,
	userOngoingMicroCourses: [],
	tempToken: undefined, // To be used during reset password.
	setAuthInfo: thunk(async (actions, token, { getState }) => {
		if (!token || !token.id || !token.userId) {
			return null;
		}
		actions.setToken(token);
		AxiosUtils.setAuthHeader(token.id);
		try {
			const user = await UserModel.fetchMe(token.id);
			if (user) {
				actions.setUserOngoingMicroCourses(user?.userOngoingMicroCourses || []);
				actions.setUser(user);
				Utils.setUserCookies(token);
				localStorage.setItem('ajs_user_email', user.email);
				if (user.lastActivePathwayIds?.length) {
					const activePathway = await PathwayModel.getPathwayOverview(user.lastActivePathwayIds?.[user.lastActivePathwayIds?.length - 1]);
					getState().userActivePathways = [activePathway];
				}
				// getState().userActivePathways = user.lastActivePathways || [];
				if (typeof user.signUpProvider === 'string' && Date.parse(user.created) + 60 <= Date.now()) {
					Analytics.track('USER_SIGN_UP_PRESS', { userId: user.id, email: user.email, name: UserModel.getUserFullName(user), provider: 'GOOGLE' });
				}
				Analytics.identify(user);
				actions.publishChaptersOnLS();
				return user;
			}
			throw new Error('User invalid');
		} catch (err) {
			Utils.removeUserCookies();
			return null;
		}
	}),
	loadAuthInfo: thunk(async (actions) => {
		const { userId, id: token } = Utils.getCookieUser();
		if (!token || !userId) {
			return null;
		}
		return actions.setAuthInfo({ id: token, userId, ttl: 31556926 });
	}),
	login: thunk(async (actions, credentials: { email: string; password: string }) => {
		const token = await UserModel.login({ ...credentials, isWeb: true });
		await actions.setAuthInfo(token);
		return token;
	}),
	signUp: thunk(async (actions, data, { getState }) => {
		await UserModel.signUp(data, true); // Sign up returns a short lived token. We need a normal token to keep the user logged in for long.
		const normalToken: Token = await actions.login({ email: data.email, password: data.password });
		// await actions.setAuthInfo(normalToken);
		return { token: normalToken, user: getState().user ?? ({} as User) };
	}),
	logout: thunk(async (actions) => {
		await UserModel.logOut().catch(() => {});
		actions.removeAuthInfo();
	}),
	updateUser: thunk(async (actions, data, { getStoreState, getState }) => {
		const { allTags } = getStoreState().App;
		const { user: prevUserData } = getState();
		const res = await UserModel.updateUser(data);
		const updatedUserData = parseToUser(res);
		actions.setUser({ ...prevUserData, ...updatedUserData, tags: allTags.filter((tag) => updatedUserData.tagIds?.includes(tag.id)) });
		return { ...prevUserData, ...updatedUserData };
	}),
	publishChaptersOnLS: thunk(async (_actions, _data, helpers) => {
		const lSMicroCourses = MicroCourseModel.getLSMicroCourses();
		let completedChaptersList: string[] = [];
		if (!isEmpty(lSMicroCourses)) {
			Object.keys(lSMicroCourses).forEach((MicroCourseId) => {
				completedChaptersList = completedChaptersList.concat(lSMicroCourses[MicroCourseId]);
			});
			const userId = helpers.getState().user?.id || '';
			try {
				const fail = ChapterModel.markCompletedBulk({ chapterSlugs: completedChaptersList, userId });
				if (!fail) {
					localStorage.removeItem('microCourses');
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		}
	}),
	updatePassword: thunk(async (_actions, { oldPassword, newPassword }) => {
		const success = await UserModel.updatePassword(oldPassword, newPassword);
		return success;
	}),
	resetPassword: thunk(async (_actions, email) => {
		const success = await UserModel.resetPassword(email);
		return success;
	}),
	verifyToken: thunk(async (actions, { email, code }) => {
		const success = await UserModel.verifyToken({ email, token: code });
		actions.setTempToken({ userId: success.id || '', id: success.accessToken, ttl: 3600, created: new Date().toString() });
		return success;
	}),
	setPasswordAfterForget: thunk(async (actions, password, { getState }) => {
		const success = await UserModel.setPasswordAfterForget({ password }, getState().tempToken?.id || '').catch(() => {
			actions.removeAuthInfo();
		});
		actions.setTempToken(undefined);
		return success;
	}),
	addMicroCourseToOngoing: action((state, microCourse) => {
		const newUserOngoingMicroCourses = state.userOngoingMicroCourses ?? [];
		if (!HelperUtils.hasObjectWithKey(microCourse.id, newUserOngoingMicroCourses || [])) {
			state.userOngoingMicroCourses = [microCourse, ...newUserOngoingMicroCourses];
		}
	}),
	setToken: action((state, token) => {
		if (!token || !token.id || !token.userId) {
			state.token = null;
			return;
		}
		state.token = {
			id: token.id || '',
			userId: token.userId || '',
			ttl: token.ttl || 0,
			created: token.created || '',
		};
	}),
	setUser: action((state, user) => {
		state.user = user;
	}),
	removeAuthInfo: action((state) => {
		// AxiosUtils.setAuthHeader(undefined);
		// localStorage.removeItem('ajs_user_email');
		Utils.removeUserCookies();
		MicroCourseModel.clearLSMicroCourses();
		MicroCourseModel.clearLSViewedMicroCourses();
		PathwayModel.clearPathwayViewLS();
		state.token = null;
		state.user = null;
	}),
	setTempToken: action((state, payload) => {
		state.tempToken = payload;
	}),
	setUserOngoingMicroCourses: action((state, payload) => {
		state.userOngoingMicroCourses = payload;
	}),
};

export default AuthStore;

import MicroCourseModel from 'Models/MicroCourse';

const useEnrollViaEmail = (): { enrollInMicroCourse: (microCourseId: string) => Promise<boolean> } => {
	const enrollInMicroCourse = async (microCourseId: string) => {
		const res = await MicroCourseModel.enrollUserViaEmail(microCourseId);
		if (res?.subjectId === microCourseId) {
			return true;
		}
		return false;
	};
	return { enrollInMicroCourse };
};

export default useEnrollViaEmail;

import React, { FC, useMemo } from 'react';
import { MicroCourse } from 'Models/MicroCourse/@types';
import { CardWithImageClasses, CardWithImageProps } from 'Components/CardWithImage';
import { alpha, Box, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { SpecialUser, User } from 'Models/User/@types';
import { fontWeightMedium } from 'Theme';
import { THEME_PALETTE } from 'Theme/themeConstants';
import { generatePath, Link } from 'react-router-dom';
import clsx from 'clsx';
import CuratorAvatars, { CuratorAvatarsProps } from 'Components/CuratorAvatars';
import { MICRO_COURSE_PATH } from 'Routes/RouteConstants';
import Typo from 'Components/Typo';
import PictureModel from 'Models/Picture';
import UserModel from 'Models/User';
import CreatedByChetStamp from 'Components/CreatedByChetStamp';
import ABExperiment from 'Features/ABExperiment/Explore';
import Users from 'Features/EntityCard/Users';
import AboutUser from 'Features/AboutUser';
import IconText from 'Components/IconText';
import { UilLayerGroup, UilUser /* , UilBookmark */ } from '@iconscout/react-unicons';

export interface MicroCourseCardProps extends Pick<CardWithImageProps, 'ExtraComponent'> {
	microCourse: MicroCourse;
	classes?: MicroCourseCardClasses;
	rootElementClasses?: CardWithImageClasses;
	variant?: 'default' | 'list' | 'flat';
	shouldNavigateOnClick?: boolean;
	link?: string;
	showProgress?: boolean;
	shouldUseExperiment?: boolean;
	onClick?: () => void;
	curatorAvatarsInnerElementProps?: CuratorAvatarsProps['innerElementProps'];
}
interface MicroCourseCardClasses {
	root?: string;
	curatorsContainer?: string;
	tagContainer?: string;
	curatorDesc?: string;
}

const MicroCourseCard: FC<MicroCourseCardProps> = (props) => {
	const {
		microCourse = {} as MicroCourse,
		ExtraComponent,
		shouldNavigateOnClick = true,
		classes: propClasses,
		rootElementClasses,
		variant = 'default',
		shouldUseExperiment,
		onClick,
		curatorAvatarsInnerElementProps,
		link,
	} = props;
	const classes = useStyles({ variant });
	const { curators = [], tags = [], rawViewCount, viewCount, name, slug, coverImage } = microCourse;

	const curatorDescription = getCuratorDetails(microCourse.curators || [], variant === 'list');
	const totalViewCount = (rawViewCount ?? 0) + (viewCount ?? 0);

	const isCreatedByChet = curators?.some((curator) => (curator as SpecialUser).createdByChet) || false;

	const Curator = useMemo(
		() => (
			<Box width="100%" display={variant === 'default' ? 'block' : 'flex'}>
				<Box>
					{curators.length > 1 && variant === 'list' ? (
						<Typo weight="bold" className={classes.microCourseCuratorNameText} variant="caption" align="left">
							{curatorDescription.title}
						</Typo>
					) : null}
					<CuratorAvatars
						curators={curators}
						classes={{ root: variant === 'default' ? clsx(propClasses?.curatorsContainer, classes.curatorsContainer) : classes.curatorsAvatarRoot }}
						innerElementProps={curatorAvatarsInnerElementProps ?? { profileSize: 60, logoSize: 20 }}
					/>
				</Box>
				{curators.length > 1 && variant === 'list' ? null : (
					<Box pt={variant === 'default' ? 2.5 : 0} ml={variant === 'default' ? 0 : 2} className={clsx(propClasses?.curatorDesc)}>
						<Typography className={classes.microCourseCuratorNameText} variant="body1" align={variant === 'default' ? 'center' : 'left'}>
							{curatorDescription.title}
						</Typography>
						<Typography variant="subtitle2" className={classes.microCourseCuratorPositionText} align={variant === 'default' ? 'center' : 'left'}>
							{curatorDescription.subtitle}
						</Typography>
						{isCreatedByChet ? (
							<Box display="flex" justifyContent="center" mb={1}>
								<CreatedByChetStamp />
							</Box>
						) : null}
					</Box>
				)}
			</Box>
			// eslint-disable-next-line react-hooks/exhaustive-deps
		),
		[curators, variant, propClasses, curatorDescription]
	);

	const ContentInsideLink = useMemo(() => {
		return (
			<>
				{variant === 'default' || variant === 'flat' ? (
					<Paper elevation={0} className={clsx(classes.paperRoot, { [classes.stack]: variant === 'default' })}>
						{curators.length === 1 ? <AboutUser profileImageSize={72} logoSize={32} user={curators?.[0]} /> : <Users users={curators ?? []} />}
						<Box mt={1.5}>
							<Typo weight="bold" variant="body1" font="Font2">
								{microCourse.name}
							</Typo>
						</Box>
						<Box mt={1.5}>
							<Box display="flex" justifyContent="space-between">
								<IconText typoProps={{ weight: 'bold', variant: 'caption', color: 'inherit' }} icon={<UilLayerGroup size={18} />} text={`${microCourse.chapterIds.length} links`} />

								{totalViewCount ? (
									<IconText
										typoProps={{ weight: 'bold', variant: 'caption', color: 'inherit' }}
										icon={totalViewCount ? <UilUser size={18} /> : <></>}
										text={`${totalViewCount.toLocaleString()}`}
										iconOnRight
									/>
								) : null}
							</Box>
						</Box>
					</Paper>
				) : (
					<Box width="100%" display="flex">
						<Box position="relative">
							<img
								alt={microCourse?.name || ''}
								src={PictureModel.getTransformedUrl(coverImage?.url || '', MICROCOURSE_CARD_IMAGE_WIDTH, MICROCOURSE_CARD_IMAGE_HEIGHT)}
								className={classes.img}
							/>
							{tags.length ? (
								<Box className={clsx(classes.tagContainer, propClasses?.tagContainer)}>
									<Typography variant="caption">{tags[0]?.name}</Typography>
								</Box>
							) : null}
						</Box>
						<Box width="100%" height="100%" ml={2} display="flex" flexDirection="column" alignItems="flex-start">
							<Box>
								<Typo variant="h4" weight="bold" gutterBottom>
									{microCourse.name}
								</Typo>
								<Typo variant="body2">{microCourse.shortDescription}</Typo>
							</Box>
							<Box flex={1} />
							<Box width="100%" position="relative">
								{Curator}
							</Box>
						</Box>
						<Box flex={1} />
						{ExtraComponent}
					</Box>
				)}
			</>
		);
	}, [
		Curator,
		ExtraComponent,
		classes.coverImage,
		classes.coverImageContainer,
		classes.img,
		classes.root,
		classes.tagContainer,
		classes.title,
		coverImage?.url,
		microCourse.name,
		microCourse.shortDescription,
		name,
		propClasses?.tagContainer,
		rootElementClasses?.coverImage,
		rootElementClasses?.coverImageContainer,
		rootElementClasses?.root,
		rootElementClasses?.title,
		tags,
		variant,
	]);

	if (shouldUseExperiment) {
		return <ABExperiment controlPath={generatePath(MICRO_COURSE_PATH, { microCourseSlug: microCourse.slug })}>{ContentInsideLink}</ABExperiment>;
	}

	if (!shouldNavigateOnClick) {
		return <Box className={clsx(classes.link, propClasses?.root)}>{ContentInsideLink}</Box>;
	}

	return (
		<Link onClick={onClick} className={clsx(classes.link, propClasses?.root)} to={link || generatePath(MICRO_COURSE_PATH, { microCourseSlug: slug })}>
			{ContentInsideLink}
		</Link>
	);
};

export default MicroCourseCard;

export const MICROCOURSE_CARD_IMAGE_HEIGHT = 208;
export const MICROCOURSE_CARD_IMAGE_WIDTH = 320;

const useStyles = makeStyles<Theme, { variant: MicroCourseCardProps['variant'] }>((theme) => ({
	paperRoot: {
		padding: theme.spacing(2),
		height: 'fit-content',
		width: '100%',
		position: 'relative',
		boxShadow: `0px 1px 4px 0px ${alpha(THEME_PALETTE.grey['800'], 0.113)}`,
		borderRadius: 20,
	},
	stack: {
		// '&:before': {
		// 	content: "' '",
		// 	zIndex: -1,
		// 	backgroundColor: theme.palette.grey['200'],
		// 	boxShadow: `0px 4px 12px 0px ${alpha(theme.palette.common.black, 0.14)}`,
		// 	width: 'calc(100% - 16px)',
		// 	height: 100,
		// 	position: 'absolute',
		// 	bottom: -8,
		// 	left: 8,
		//  borderRadius: 20,
		// },
		'&:after': {
			content: "' '",
			backgroundColor: theme.palette.common.white,
			zIndex: -2,
			boxShadow: `0px 4px 12px 0px ${alpha(theme.palette.common.black, 0.14)}`,
			bottom: -8,
			width: 'calc(100% - 16px)',
			left: 8,
			height: 100,
			position: 'absolute',
			borderRadius: 20,
		},
	},
	link: {
		textDecoration: 'none',
		width: '100%',
		display: 'flex',
		flex: 1,
		// marginRight: '1rem',
	},
	root: {
		height: 'fit-content',
		width: 292,
		// marginRight: 16,
		boxShadow: `0px 2px 20px 0px ${theme.palette.text.secondary}26`,
		[theme.breakpoints.up('sm')]: {
			// height: 345,
			// width: 368,
			height: 295,
			width: 330,
		},
		[theme.breakpoints.up('md')]: {
			marginRight: 'unset',
		},
	},

	progressContainer: {
		width: '30%',
		margin: '5px auto 0 auto',
		'& svg': {
			color: THEME_PALETTE.blue.A100,
		},
	},
	completedText: {
		fontSize: 14,
		color: THEME_PALETTE.blue.A100,
	},
	title: {
		position: 'absolute',
		left: -8,
		zIndex: 2,
		color: theme.palette.background.paper,
		top: -105,
	},
	coverImageContainer: {
		height: 173,
		borderRadius: 'unset',
		backgroundColor: 'unset',
		overflow: 'hidden',
		[theme.breakpoints.up('sm')]: { height: 187 },
		'&::after': {
			content: "''",
			position: 'absolute',
			width: '100%',
			height: '100%',
			background: 'linear-gradient(#00000000, #000000CC)',
			left: 0,
			top: 0,
		},
	},
	tagContainer: {
		backgroundColor: THEME_PALETTE.yellow['200'],
		borderRadius: 8,
		padding: '8px 27px',
		position: 'absolute',
		boxShadow: '0px 0px 1px rgba(117, 131, 142, 0.04), 0px 2px 4px rgba(52, 60, 68, 0.16)',
		height: 26,
		display: 'flex',
		alignItems: 'center',
		boxSizing: 'border-box',
		top: (props) => (props.variant === 'default' ? -170 : 16),
		left: (props) => (props.variant === 'default' ? -8 : 8),
		opacity: 0.7,
		'& > span': {
			lineHeight: 1.2,
		},
		[theme.breakpoints.up('sm')]: {
			top: (props) => (props.variant === 'default' ? -187 : 16),
			left: (props) => (props.variant === 'default' ? -8 : 8),
		},
	},
	coverImage: {
		position: 'relative',
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	microCourseCuratorNameText: {
		fontWeight: fontWeightMedium,
		paddingTop: '0.5rem',
	},
	microCourseCuratorPositionText: {
		color: theme.palette.grey['600'],
		fontWeight: theme.typography.fontWeightRegular,
	},
	numChaptersText: {
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		fontSize: 12,
		width: '100%',
		'&>img': { height: '1.5em' },
		[theme.breakpoints.up('sm')]: {},
	},
	numViews: {
		fontSize: 12,
		lineHeight: 1.2,
		color: theme.palette.background.paper,
		height: 12,
		display: 'flex',
		alignItems: 'center',
		'&>svg': {
			height: '1em',
			width: '1em',
			marginRight: 4,
		},
	},
	viewLessonContainer: {
		position: 'absolute',
		top: -60,
		display: 'flex',
		justifyContent: 'space-between',
		width: '105%',
		marginLeft: -8,
		alignItems: 'center',
	},
	curatorsContainer: {
		position: 'absolute',
		top: -38,
		zIndex: 1, // create new stacking context
	},
	curatorsAvatarRoot: {
		justifyContent: 'flex-start',
		width: 'fit-content',
	},
}));

export const getCuratorDetails = (curators: User[], shouldShowPositionWithCompany?: boolean): { title: string; subtitle: string } => {
	// shouldShowPositionWithCompany is relevant only when curators length is 1
	switch (curators.length) {
		case 0:
			return { title: '', subtitle: '' };
		case 1:
			return {
				title: `${UserModel.getUserFullName(curators[0])}${!shouldShowPositionWithCompany && curators[0].recentCompany ? ` • ${curators[0].recentCompany}` : ''} `,
				subtitle: `${curators[0].recentJobTitle || ''}${shouldShowPositionWithCompany && curators[0].recentCompany ? ` • ${curators[0].recentCompany}` : ''}`,
			};
		case 2:
			return { title: 'Curated by 2 experts', subtitle: `${curators[0].recentJobTitle ? `${curators[0].recentJobTitle} +` : ''} ${curators[1].recentJobTitle || '1 other'} ` };
		default:
			return { title: `Curated by ${curators.length} experts`, subtitle: `${curators[0].recentJobTitle ? `${curators[0].recentJobTitle} +` : ''} ${curators.length - 1} others` };
	}
};

import React, { FC } from 'react';
import { Box, BoxProps } from '@material-ui/core';

export interface SpacerProps {
	height?: BoxProps['height'];
	width?: BoxProps['width'];
	enforceWidthInFlex?: boolean;
}

const Spacer: FC<SpacerProps> = ({ height = 1, width = 1, enforceWidthInFlex }) => {
	return <Box height={height} width={width} minWidth={enforceWidthInFlex ? width : undefined} />;
};

export default Spacer;

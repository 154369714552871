// eslint-disable-next-line import/no-cycle
import { parseToChapter } from 'Models/Chapter/ChapterParsers';
// eslint-disable-next-line import/no-cycle
import { parseToContentDuration } from 'Models/Post/PostParsers';
// eslint-disable-next-line import/no-cycle
import { parseToUser } from 'Models/User/UserParsers';
import { JSONType } from 'Typings/@types';
import { MicroCourse } from './@types';

export const parseToMicroCourse = (obj: JSONType): MicroCourse => {
	const { _coverImage: coverImage, _ogImage: ogImage } = obj ?? {};
	return {
		...obj,
		totalTime: parseToContentDuration(obj?.chapters?.reduce((accumulator: number, chapter: { contentDuration?: number }) => accumulator + (chapter.contentDuration ?? 0), 0)), // At this point contentDuration is still in minutes. It has not been converted to hours minutes and seconds
		coverImage,
		ogImage,
		...(obj?.curators ? { curators: obj?.curators.map(parseToUser) } : {}),
		...(obj?.chapters
			? { chapters: obj?.chapters?.sort((a: { id: string }, b: { id: string }) => (obj?.chapterIds || []).indexOf(a.id) - (obj?.chapterIds || []).indexOf(b.id)).map(parseToChapter) }
			: {}),
		...(obj?.viewersWithImage ? { viewersWithImage: obj?.viewersWithImage.map(parseToUser) } : {}),
	} as MicroCourse;
};

export const parseMicroCourseForm = (microCourse: Partial<MicroCourse>): JSONType => {
	// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
	const { curators, chapters, coverImage: _coverImage, ogImage: _ogImage, ...rest } = microCourse; // ignoring curators and chapters since there is no need to send them to the endpoint.
	return {
		...rest,
		_ogImage,
		_coverImage,
	};
};

import { useTheme } from '@material-ui/styles';
import { Theme, useMediaQuery } from '@material-ui/core';

export const useResponsive = (): { isMobile: boolean; isTablet: boolean; isDesktop: boolean } => {
	const theme = useTheme<Theme>();
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
	// when you do breakpoints.down('<breakpoint-name>'), mui takes the next breakpoint-name (sm, in this case) and makes a media-query with that breakpoint size
	const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	return { isMobile, isTablet, isDesktop };
};

export default useResponsive;

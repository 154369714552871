/* eslint-disable no-plusplus */
import React from 'react';
import { JSONType } from 'Typings/@types';
// eslint-disable-next-line import/no-cycle
import { history } from 'App';
import Header from 'Features/Header';
import CuratorStudioHeader from 'Features/CuratorStudio/Header';
import { NO_HEADER_PATHS } from 'Features/Header/utils';

// eslint-disable-next-line import/prefer-default-export
export const getHeader = (headerProps?: JSONType, isMobile?: boolean): React.ReactElement => {
	const headerMap: { route: RegExp; component: React.FunctionComponent<JSONType> }[] = [{ route: /^(\/?)(curator-studio)(\/?)[A-Za-z]*/, component: CuratorStudioHeader }];
	const shouldRenderHeader = !(NO_HEADER_PATHS.includes(history.location.pathname) && isMobile);
	if (!shouldRenderHeader) return <></>;
	for (let i = 0; i < headerMap.length; ++i) {
		if (headerMap[i].route.test(history.location.pathname)) {
			return React.createElement(headerMap[i].component, headerProps);
		}
	}
	return <Header />;
};

import { makeStyles, createStyles } from '@material-ui/core';
import { DialogProps, AppDialog } from 'mui-wrapper-utils';
import React, { FC, useState } from 'react';
// import {  } from 'Theme/themeConstants';

interface ContextProps {
	open: boolean;
	title: string;
	content: React.ReactNode;
	showDialog: (content: React.ReactNode, options?: Partial<IAppDialog>) => void;
	hideDialog: () => void;
}

export const AppDialogContext = React.createContext<ContextProps>({
	open: false,
	title: '',
	content: null,
	// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
	showDialog: (content: React.ReactNode, options?: Partial<IAppDialog>) => {},
	hideDialog: () => {},
});

export interface IAppDialog extends DialogProps {
	content?: React.ReactNode;
	onCloseAction?: () => void;
}

export const useAppDialog = (initialState: IAppDialog): IAppDialog & { showDialog: (content: React.ReactNode, options?: Partial<IAppDialog>) => void; hideDialog: () => void } => {
	const [dialogState, setDialogState] = useState(initialState);

	const showDialog = (content: React.ReactNode, options?: Partial<IAppDialog>) => {
		setDialogState({
			...dialogState,
			open: true,
			content,
			...options,
		});
	};
	const hideDialog = () => {
		setDialogState({
			...dialogState,
			open: false,
		});
	};
	return { ...dialogState, showDialog, hideDialog };
};

export const AppDialogProvider: FC = (props) => {
	const { children } = props;
	const classes = useStyles();
	const {
		content,
		title = '',
		showDialog,
		hideDialog,
		open,
		onCloseAction,
		...appDialogProps
	} = useAppDialog({
		handleClose: () => {},
		title: '',
		open: false,
	});
	return (
		<AppDialogContext.Provider
			value={{
				title,
				content,
				showDialog,
				hideDialog,
				open,
			}}
		>
			{children}
			<AppDialog
				{...appDialogProps}
				classes={{ ...appDialogProps.classes, container: classes.backdrop }}
				open={open}
				title={title}
				handleClose={() => {
					hideDialog();
					onCloseAction?.();
				}}
			>
				{content}
			</AppDialog>
		</AppDialogContext.Provider>
	);
};
export default AppDialogProvider;

const useStyles = makeStyles(() =>
	createStyles({
		backdrop: {
			// background:`${palette.blue['800']}cc` ,
			background: 'transparent',
		},
	})
);

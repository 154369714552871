import { useContext } from 'react';
import { AppDialogContext, IAppDialog } from '../Contexts/AppDialogContext/index';

export const useAppDialog = (): { withDialog: (content: React.ReactNode, options?: Partial<IAppDialog>) => void; hideDialog: () => void } => {
	const { showDialog, hideDialog } = useContext(AppDialogContext);

	const withDialog = (content: React.ReactNode, options?: Partial<IAppDialog>) => {
		showDialog(content, options);
	};
	return { withDialog, hideDialog };
};
export default useAppDialog;

import React, { FC } from 'react';
import { Chip, createStyles, makeStyles, Typography, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { Tag } from 'Models/Tag/@types';
import { Link } from 'react-router-dom';
import { Font1Bold } from 'Theme';
import { EXPLORE_HOME_PATH } from 'Routes/RouteConstants';
import { THEME_PALETTE } from 'Theme/themeConstants';

interface TagChipProps {
	tag: Tag;
	onClick?: (tagValue: string) => void;
	isSelected?: boolean;
	link?: string;
	size?: 'large' | 'small' | 'fullWidth';
	variant?: 'rounded' | 'pill';
	color?: 'primary' | 'secondary';
	background?: 'default' | 'darkgrey' | 'white';
}
const TagChip: FC<TagChipProps> = (props) => {
	const { tag, onClick, isSelected, link, size = 'small', variant = 'rounded', color = 'secondary', background = 'darkgrey' } = props;
	const classes = useStyles({ size, background });

	const chipLink = (() => {
		if (link === '#') return undefined;
		if (!link) return `${EXPLORE_HOME_PATH}/?filterTags=${tag.value}`;
		return link;
	})();

	if (variant === 'pill')
		return (
			<Chip
				onClick={() => onClick?.(tag.value)}
				component={link === '#' ? 'span' : Link}
				to={chipLink}
				label={
					<>
						<Typography component="span" color="inherit">
							{tag.name}
						</Typography>
					</>
				}
				className={clsx(classes.pillRoot, {
					[classes.selected]: isSelected,
				})}
				classes={{
					label: clsx(classes.chipLabel, { [classes.selectedLabel]: isSelected }),
					root: clsx({ [classes.chipLabelPrimary]: color === 'primary', [classes.chipLabelSecondary]: color === 'secondary' }),
				}}
				clickable
			/>
		);
	return (
		<Chip
			onClick={() => onClick?.(tag.value)}
			component={link === '#' ? 'span' : Link}
			to={chipLink}
			label={
				<>
					<Typography component="span" color="inherit">
						{tag.name}
					</Typography>
				</>
			}
			className={clsx(classes.roundedRoot, {
				[classes.selected]: isSelected,
			})}
			classes={{
				label: clsx(classes.chipLabel, { [classes.selectedLabel]: isSelected }),
				root: clsx({ [classes.chipLabelPrimary]: color === 'primary', [classes.chipLabelSecondary]: color === 'secondary' }),
			}}
			clickable
		/>
	);
};
export default TagChip;

const useStyles = makeStyles<Theme, { size: 'large' | 'small' | 'fullWidth'; background: 'darkgrey' | 'default' | 'white' }>((theme) =>
	createStyles({
		roundedRoot: {
			padding: theme.spacing(1.5, 3),
			borderRadius: theme.spacing(1.5),
			border: 'none',
			fontFamily: Font1Bold,
			width: ({ size }) => (size === 'fullWidth' ? '100%' : undefined),
			paddingLeft: ({ size }) => (size === 'large' ? 48 : 24),
			paddingRight: ({ size }) => (size === 'large' ? 48 : 24),
			'& span': {
				color: 'inherit',
				fontSize: 'inherit',
				fontFamily: 'inherit',
			},
			fontSize: 16,
			height: 'fit-content',
			backgroundColor: ({ background }) => getColorValue(background),
			[theme.breakpoints.down('sm')]: {
				height: () => 36, // prevent above height declaration from overriding this declaration on mobile screens
				// height: 36,
				paddingLeft: ({ size }) => (size === 'large' ? 24 : 12),
				paddingRight: ({ size }) => (size === 'large' ? 24 : 12),
			},
		},
		pillRoot: {
			userSelect: 'none',
			justifyContent: 'center',
			boxSizing: 'border-box',
			backgroundColor: theme.palette.common.white,
			borderRadius: ({ size }) => (size === 'large' ? 35 : 24),
			cursor: 'pointer',
			height: ({ size }) => (size === 'large' ? 70 : 48),
			maxWidth: '100%',
			width: ({ size }) => (size === 'fullWidth' ? '100%' : undefined),
			paddingLeft: ({ size }) => (size === 'large' ? 48 : 24),
			paddingRight: ({ size }) => (size === 'large' ? 48 : 24),
			transition: 'color ease 300ms, background-color ease 600ms, border ease 300ms',
			[theme.breakpoints.down('sm')]: {
				height: () => 36, // prevent above height declaration from overriding this declaration on mobile screens
				// height: 36,
				paddingLeft: ({ size }) => (size === 'large' ? 24 : 12),
				paddingRight: ({ size }) => (size === 'large' ? 24 : 12),
			},
		},
		chipLabelPrimary: {
			color: theme.palette.primary.main,
			'&:visited': {
				color: theme.palette.primary.main,
			},
		},
		chipLabelSecondary: {
			color: theme.palette.common.black,
			'&:visited': {
				color: theme.palette.common.black,
			},
		},
		chipLabel: {
			fontFamily: Font1Bold,
			fontSize: 16,
			letterSpacing: 'calc(0.04 * 1em)',
			'& > span': {
				fontFamily: 'inherit',
			},
			[theme.breakpoints.up('sm')]: {
				fontSize: 18,
			},
		},
		selected: {
			border: `3px solid ${theme.palette.primary.main}`,
			backgroundColor: `${theme.palette.primary.main} !important`,
			color: `${theme.palette.background.paper} !important`,
			'&:hover': {
				backgroundColor: `${theme.palette.primary.main} !important`,
				color: `${theme.palette.background.paper} !important`,
			},
			'&:focus': {
				backgroundColor: `${theme.palette.primary.main} !important`,
				color: `${theme.palette.background.paper} !important`,
			},
		},
		selectedLabel: {
			color: `${theme.palette.background.paper} !important`,
		},
	})
);

const getColorValue = (backgroundColor: TagChipProps['background']): string => {
	switch (backgroundColor) {
		case 'darkgrey':
			return THEME_PALETTE.grey['300'];
		default:
		case 'default':
			return THEME_PALETTE.background.default;
		case 'white':
			return THEME_PALETTE.common.white;
	}
};

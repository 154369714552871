import { JSONType } from 'Typings/@types';
import _set from 'lodash/set';
import { parseToChapter } from 'Models/Chapter/ChapterParsers';
import { parseToMicroCourse } from 'Models/MicroCourse/MicroCourseParsers';
import { parseToUser } from 'Models/User/UserParsers';
import { parseToPost } from 'Models/Post/PostParsers';
import { ListDocument } from 'Models/ExploreHome/@types';
import { parseToPathway } from 'Models/Pathway/PathwayParsers';
import { ExploreList } from './@types';

// eslint-disable-next-line import/prefer-default-export
export const parseToExploreList = (obj: JSONType): ExploreList => {
	let data: JSONType = { ...obj };
	if (obj?.subject?.['list-documents']) {
		data = _set(data, 'subject.listDocuments', data?.subject['list-documents'].map(parseToListDocument));
	} else if (obj['list-documents']) {
		data = _set(data, 'listDocuments', data['list-documents'].map(parseToListDocument));
	} else {
		switch (obj?.subjectType) {
			case 'MicroCourse': // This switch block is for when the app had spotlights. Currently not being used.
				data = { ...data, subject: parseToMicroCourse(data?.subject) };
				break;
			case 'user':
				data = { ...data, subject: parseToUser(data?.subject) };
				break;
			case 'post':
				data = { ...data, subject: parseToPost(data?.subject) };
				break;
			case 'Pathway':
				data = { ...data, subject: parseToPathway(data?.subject) };
				break;
			default:
				break;
		}
	}
	return { ...data } as ExploreList;
};

export const parseToListDocument = (obj: JSONType): ListDocument => {
	let data = { ...obj };
	switch (obj?.subjectType) {
		case 'Post':
			if (data?.subject.postType === 'chapter') data = { ...data, subject: parseToChapter(data?.subject) };
			else data = { ...data, subject: parseToPost(data?.subject) };
			break;
		case 'MicroCourse':
			data = { ...data, subject: parseToMicroCourse(data?.subject) };
			break;
		case 'user':
			data = { ...data, subject: parseToUser(data?.subject) };
			break;
		case 'Pathway':
			data = { ...data, subject: parseToPathway(data?.subject) };
			break;
		default:
			break;
	}
	return { ...data } as ListDocument;
};

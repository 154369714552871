import { request } from 'Resources/AxiosUtils';
import { JSONType, Params } from 'Typings/@types';
import { Post } from './@types';
import { parseToPost } from './PostParsers';

class PostModel {
	// static POST_VIEW_COOKIE_KEY = 'postView';

	static getPost = async (postSlug: string, params?: Params): Promise<Post> => {
		const res = await request({
			url: `/posts/${postSlug}`,
			params,
		});
		return parseToPost(res);
	};

	static getUserPosts = async (userSlug: string, params?: Params): Promise<Post[]> => {
		const res = await request({
			url: `/users/${userSlug}/posts`,
			params,
		});
		return res.map(parseToPost);
	};

	static getPostLinkPreview = async (params: { url: string }): Promise<Post> => {
		const res = await request({
			url: `/posts/preview-link`,
			params,
		});
		return parseToPost(res);
	};

	static createView = async (id: string, userId: string): Promise<boolean> => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [_, success] = await request<[unknown, boolean]>({
			url: `/posts/${id}/view`,
			method: 'post',
			data: {
				userId,
			},
		});
		return success;
	};

	static createRawView = async (id: string): Promise<JSONType> =>
		request<JSONType>({
			url: `/views/rvp`,
			method: 'post',
			data: {
				entityId: id,
				entityType: 'Post',
			},
		});

	static likePost = async (id: string): Promise<JSONType> =>
		request<JSONType>({
			url: `posts/${id}/like`,
			method: 'POST',
		});

	static unlikePost = async (likeId: string): Promise<JSONType | null> => {
		if (!likeId) return null;
		return request<JSONType>({
			url: `likes/${likeId}/unlike`,
			method: 'DELETE',
		});
	};

	// static isPostViewedLS = (id: string) => {
	// 	const ids = JSON.parse(localStorage.getItem(PostModel.POST_VIEW_COOKIE_KEY) || '[]') as string[];
	// 	return ids.includes(id);
	// };

	// static setPostViewLS = (id: string) => {
	// 	const ids = JSON.parse(localStorage.getItem(PostModel.POST_VIEW_COOKIE_KEY) || '[]') as string[];
	// 	if (ids.includes(id)) return;
	// 	ids.push(id);
	// 	localStorage.setItem(PostModel.POST_VIEW_COOKIE_KEY, JSON.stringify(ids));
	// };
}
export default PostModel;

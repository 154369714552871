import { action, Action, Thunk, thunk } from 'easy-peasy';
import TagModel from 'Models/Tag';
import { Tag, TagCategory, FeedTagCategoryType, MainTagCategoryType } from 'Models/Tag/@types';
import { parseToTag } from 'Models/Tag/TagParsers';
// eslint-disable-next-line import/no-cycle
import { RootStore } from 'Stores';
import { Params } from 'Typings/@types';
import _uniqWith from 'lodash/uniqWith';
import { AppConstants } from 'Models/App/@types';
import AppModel from 'Models/App';

// type ThunkAction<T = void, R = void> = Thunk<AppState, T, RootStore, Promise<R>>;
// type StaticAction<T = void> = Action<AppState, T>;
export interface AppState {
	appConstants?: AppConstants;
	allTags: Tag[];
	webOnlyTags?: Tag[]; // It used to be such that only tags with isWeb: true were shown. For now all available tags are shown. Both allTags and tags are kept to provide for quick changes.
	userFeedTags: Tag[];
	tagCategories?: TagCategory[];
	isCordova?: boolean;
	deviceType?: string;
	getAppConstants: Thunk<AppState, undefined, null, RootStore, Promise<AppConstants>>;
	setAppConstants: Action<AppState, AppConstants>;
	setTagCategories: Action<AppState, TagCategory[]>;
	setUserFeedTags: Action<AppState, Tag[]>;
	getAllTags: Thunk<AppState, Params, null, RootStore, Promise<Tag[]>>;
	setAllTags: Action<AppState, Tag[]>;
	setIsCordova: Action<AppState, boolean>;
	setDeviceType: Action<AppState, string>;
	setTags: Action<AppState, Tag[]>;
}

const AppStore: AppState = {
	appConstants: undefined,
	allTags: [],
	webOnlyTags: [],
	userFeedTags: [],
	isCordova: false,
	deviceType: undefined,
	getAllTags: thunk(async (actions, params) => {
		const res = await TagModel.getTagCategories(params);
		actions.setTagCategories(_uniqWith(res, (a, b) => a.id === b.id));
		const userFeedTagCategory = res.find((tc) => tc.type === FeedTagCategoryType);
		actions.setUserFeedTags(userFeedTagCategory?.tags || []);
		const allTags = res
			.map((tagCategory) => tagCategory.tags)
			.flat()
			?.filter((tag) => !!tag);
		const tagAll = allTags.find((tag) => tag.value === 'all');
		if (tagAll) allTags.unshift(tagAll); // Making sure tag with value all is the first tag
		actions.setAllTags(_uniqWith(allTags, (val1, val2) => val1?.id === val2?.id));

		const webOnlyTagsCategory = res.find((tc) => tc.type === MainTagCategoryType);
		const tags = sortTags(webOnlyTagsCategory?.tagIds ?? [], webOnlyTagsCategory?.tags?.map(parseToTag) || []);
		actions.setTags(tags);
		return tags;
	}),
	getAppConstants: thunk(async (actions) => {
		const appConstants = await AppModel.getAppConstants();
		actions.setAppConstants(appConstants);
		return appConstants;
	}),
	setAllTags: action((state, payload) => {
		state.allTags = payload;
	}),
	setTagCategories: action((state, payload) => {
		state.tagCategories = payload;
	}),
	setTags: action((state, payload) => {
		state.webOnlyTags = payload;
	}),
	setUserFeedTags: action((state, payload) => {
		state.userFeedTags = payload;
	}),
	setIsCordova: action((state, payload) => {
		state.isCordova = payload;
	}),
	setDeviceType: action((state, payload) => {
		state.deviceType = payload;
	}),
	setAppConstants: action((state, payload) => {
		state.appConstants = payload;
	}),
};

export default AppStore;

const sortTags = (orderedIds: string[], unOrderedTags: Tag[]) => {
	const orderedTags: Tag[] = [];
	orderedIds.forEach((id: string) => {
		const tag = unOrderedTags.find((t: Tag) => t.id === id);
		if (tag) orderedTags.push(tag);
	});
	return orderedTags;
};

import { JSONType } from 'Typings/@types';
import { Picture, ProfileImage } from './@types';

export const parseToPicture = (obj: JSONType): Picture => {
	return { ...obj } as Picture;
};

export const parseToProfileImage = (obj: JSONType): ProfileImage => {
	return { ...obj } as ProfileImage;
};

export const parseUnsplashImagesToPicture = (obj: JSONType): Picture => {
	return {
		url: obj.urls.full,
		caption: obj.description ?? obj.alt_description ?? '',
		width: obj.width,
		height: obj.height,
		id: obj.id,
		name: obj.id,
		// size: number,
		// filePath: string,
		fileType: 'image',
		thumbnailUrl: obj.urls.thumb,
		created: obj.created_at,
		updated: obj.updated_at,
	};
};

import React, { FC } from 'react';
import { Box, Checkbox, makeStyles, MenuItem, Typography } from '@material-ui/core';
import UserListModel from 'Models/UserList';
import CustomDivider from 'Components/CustomDivider';
import Spacer from 'Components/Spacer';
// eslint-disable-next-line import/no-cycle
import useCreateList from 'Hooks/useCreateList';
import { UserList, UserListItem } from 'Models/UserList/@types';
import { THEME_PALETTE } from 'Theme/themeConstants';
import { Analytics } from 'analytics';
import CustomCheckIcon from 'Components/CustomCheckIcon';
import useAsyncTask from 'Hooks/useAsyncTask';
import useToastMessage from 'Hooks/useToastMessage';

export interface UserListMenuProps {
	lists: UserList[];
	userId: string;
	subjectType: UserListItem['subjectType'];
	subjectId: string;
	setUpdatedLists: (updatedLists: UserList[]) => void;
	AnalyticsSource?: 'LIBRARY_ITEM_UPDATE_MENU' | 'SUBJECT_ITEM_UPDATE_MENU';
}

const UserListMenu: FC<UserListMenuProps> = ({ lists, userId, subjectType, subjectId, setUpdatedLists, AnalyticsSource }) => {
	const classes = styles();
	const toggleFromList = async (listId: string, toRemove: boolean): Promise<boolean> => {
		const listToUpdate = lists.find((list) => list.id === listId);
		if (!toRemove) {
			const newUserListItem = await UserListModel.addToUserList(userId, listId, { subjectType: subjectType !== 'Chapter' ? subjectType : 'Post', subjectId });
			if (newUserListItem) {
				setUpdatedLists(
					lists.map((list) => {
						if (list.id === listId) {
							return { ...list, userListItems: [...(list.userListItems ?? []), newUserListItem] };
						}
						return list;
					})
				);
				Analytics.track('LIBRARY_ADD_TO_LIST', { userId, subjectId, subjectType, listSlug: listToUpdate?.slug ?? listId ?? null });
				return true;
			}
			return false;
		}
		if (listToUpdate) {
			const userListItemIdToRemove = listToUpdate.userListItems?.find((item) => item.subjectId === subjectId)?.id;
			if (userListItemIdToRemove) {
				const res = await UserListModel.updateUserList(userId, listId, {
					listItemContent: { id: listId, userListItemIds: listToUpdate.userListItemIds.filter((itemId) => itemId !== userListItemIdToRemove) },
				});
				if (res) {
					setUpdatedLists(
						lists.map((list) => {
							if (list.id === listId) {
								return { ...list, ...res, userListItems: list.userListItems?.filter((item) => item.id !== userListItemIdToRemove) };
							}
							return list;
						})
					);
					Analytics.track('LIBRARY_REMOVE_FROM_LIST', { userId, subjectId, subjectType, listSlug: listToUpdate?.slug ?? listId ?? null });
					return true;
				}
			} else {
				// eslint-disable-next-line no-console
				console.error(`list to update does not have a list item with subject id = ${subjectId}.More info: `, { lists, listToUpdate, userListItemIdToRemove, subjectId });
				// throw new Error(`list to update does not have a list item with subject id = ${subjectId}. Check console for more info`);
				return false;
			}
		}
		return false;
	};
	const withToast = useToastMessage();
	const toggleFromListTask = useAsyncTask(toggleFromList);
	const { createNewList } = useCreateList({
		postSubmitTask: async (newList) => {
			Analytics.track('LIBRARY_CREATE_LIST', { source: AnalyticsSource ?? null, subjectType, subjectId, userId });
			withToast(
				async () => {
					const success = await toggleFromList(newList.id, false);
					setUpdatedLists(lists.concat({ ...newList, ...(success ? { userListItems: [{ subjectId, id: '', created: '', subjectType, updated: '' }] } : {}) }));
					if (!success) {
						throw new Error('Failed to add item to list');
					}
				},
				{
					disableSuccessMsg: true,
					errorToastMessage: `Failed to add ${
						// eslint-disable-next-line no-nested-ternary
						subjectType === 'Chapter' || subjectType === 'Post' ? 'link' : subjectType === 'MicroCourse' ? 'collection' : subjectType.toLowerCase()
					} to list.`,
				}
			);
		},
	});
	return (
		<>
			{lists.map((list) => {
				const isSelected = (list.userListItems?.findIndex((item) => item.subjectId === subjectId) ?? -2) > -1;
				return (
					<Box key={list.id}>
						<MenuItem
							onClick={(event) => {
								event.preventDefault();
								event.stopPropagation();
								toggleFromListTask.run(list.id, isSelected);
							}}
							button
							disabled={toggleFromListTask.status === 'PROCESSING'}
						>
							<Checkbox
								icon={<CustomCheckIcon checked={false} />}
								checkedIcon={<CustomCheckIcon checked />}
								checked={isSelected}
								onChange={(event) => {
									event.preventDefault();
									// event.stopPropagation();
									// toggleFromListTask.run(list.id, checked);
								}}
							/>
							<Box>{list.name}</Box>
						</MenuItem>
					</Box>
				);
			})}
			<Spacer height={8} />
			<CustomDivider color={THEME_PALETTE.grey.B800} />
			<MenuItem onClick={createNewList} button className={classes.newList}>
				<Typography color="inherit">Create new list</Typography>
			</MenuItem>
		</>
		// </Paper>
		// </MenuList>
	);
};

export default UserListMenu;

const styles = makeStyles((theme) => ({
	newList: {
		padding: theme.spacing(2, 3),
		color: THEME_PALETTE.blue.A100,
	},
	newListFormHeading: {},
	menu: {
		marginTop: theme.spacing(2),
		width: 260,
		paddingBottom: 0,
	},
}));

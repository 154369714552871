import React, { FC } from 'react';
import { Box, Chip, ChipProps, FormHelperText, makeStyles } from '@material-ui/core';
import { FormConfig, IFieldProps } from 'react-forms';
import { FormikProps } from 'formik';
import { NameValuePair } from 'Typings/@types';
import get from 'lodash/get';
import clsx from 'clsx';

export interface ChipSelectProps extends IFieldProps {
	fieldProps?: ChipSelectFieldProps;
}
export interface ChipSelectFieldProps {
	options: NameValuePair[];
	chipProps?: Partial<Omit<ChipProps, 'onClick' | 'clickable' | 'label' | 'variant'>>;
	selectedClass?: string | string[];
	unselectedClass?: string | string[];
	defaultClass?: string | string[];
}

const ChipSelect: FC<ChipSelectProps> = ({ fieldProps = {} as ChipSelectFieldProps, formikProps = {} as FormikProps<unknown>, fieldConfig = {} as FormConfig }) => {
	const { options, chipProps, selectedClass, defaultClass, unselectedClass } = fieldProps;
	const { valueKey } = fieldConfig;
	const values = get(formikProps, `values.${valueKey}`);
	const classes = styles();
	const error = get(formikProps, `errors.${valueKey}`);
	return (
		<>
			<FormHelperText error={!!error}>{error ?? ' '}</FormHelperText>
			<Box className={classes.tagsContainer}>
				{options?.map((option) => {
					const isSelected = values?.includes(option.value);
					return (
						<Chip
							{...chipProps}
							classes={{ ...chipProps?.classes, root: clsx(defaultClass), outlined: clsx(unselectedClass), colorPrimary: clsx(selectedClass) }}
							clickable
							color={isSelected ? 'primary' : undefined}
							variant={isSelected ? 'default' : 'outlined'}
							onClick={() => {
								if (!isSelected)
									formikProps.setFieldValue(
										valueKey,
										[...(values ?? []), option.value].filter((value) => !!value)
									);
								else
									formikProps.setFieldValue(
										valueKey,
										values?.filter((item: string) => item !== option.value)
									);
							}}
							// link="#"
							// tag={option}
							key={option.value}
							label={option.name}
						/>
					);
				})}
			</Box>
		</>
	);
};

export default ChipSelect;

const styles = makeStyles((theme) => ({
	tagsContainer: {
		gap: theme.spacing(2),
		display: 'flex',
		flexWrap: 'wrap',
		margin: '0 auto',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0, 2),
		},
	},
}));

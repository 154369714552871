import { EXPLORE_HOME_PATH, ABOUT_PATH, CURATORS_INFO_PATH, ELITE_100_PATH } from 'Routes/RouteConstants';
import { HeaderLink } from './@types';

export const HEADER_DESKTOP_HEIGHT = 72;
export const HEADER_MOBILE_HEIGHT = 56;
export const LOGO = '/chet-main-logo.svg';

// To be used in both headers sm and >md
export const loggedInHeaderLinks: HeaderLink[] = [
	{
		label: 'Courses',
		analyticsPage: 'explore',
		link: EXPLORE_HOME_PATH,
	},
	// {
	// 	label: 'Feed',
	// 	analyticsPage: 'feed',
	// 	link: FEED_PATH,
	// },
	// {
	// 	label: 'Mission',
	// 	analyticsPage: 'Mission',
	// 	link: 'https://chetnetwork.com/mission.html',
	// 	external: true,
	// 	openInNewTab: false,
	// },
	// {
	// 	label: 'Curators',
	// 	analyticsPage: 'Curators',
	// 	link: CURATORS_INFO_PATH,
	// 	external: false,
	// 	openInNewTab: false,
	// },
	// {
	// 	label: 'Blog',
	// 	analyticsPage: 'Blog',
	// 	link: 'https://chetnetwork.com/blog', // do not add a trailing slash
	// 	external: true,
	// },
	{
		label: 'Contact',
		analyticsPage: 'Contact',
		link: 'https://chetnetwork.com/contact.html',
		external: true,
		openInNewTab: false,
	},
];

export const loggedOutHeaderLinks: HeaderLink[] = [
	{
		label: 'About',
		analyticsPage: 'about',
		link: ABOUT_PATH,
		external: false,
		openInNewTab: false,
	},
	{
		label: 'Curators',
		analyticsPage: 'Curators',
		link: CURATORS_INFO_PATH,
		external: false,
		openInNewTab: false,
	},
	{
		label: 'Elite 100',
		analyticsPage: 'elite',
		link: ELITE_100_PATH,
		external: false,
		openInNewTab: false,
	},
];

/* eslint-disable camelcase */
import { User } from 'Models/User/@types';
import pick from 'lodash/pick';
import { JSONType } from 'Typings/@types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Analytics {
	let session_id: undefined | number;
	let deviceType: string | undefined;
	let isCordova: boolean | undefined = false;
	export type PAGE =
		| 'profile-micro-courses'
		| 'chet-opened'
		| 'explore'
		| 'micro-course'
		| 'chapter'
		| 'chapter-add-answer'
		| 'chapter-view'
		| 'sign-in'
		// | 'curator-micro-courses'
		| 'Mission'
		| 'Contact'
		| 'Blog'
		| 'Landing'
		| 'post-opened'
		| 'pathway'
		| 'Curators'
		| 'feed'
		| 'Login'
		| 'Register'
		| 'dashboard'
		| 'library'
		| 'Tag'
		| 'curator-profile'
		| 'about'
		| 'user-profile'
		| 'sign-up-with-email'
		| 'edit-interests-after-sign-up'
		| 'follow-curators-after-sign-up'
		| 'subscribe-microCourses-after-sign-up'
		| 'elite';

	export type EVENT =
		| 'MARK_COMPLETED'
		| 'MARK_COMPLETED_WITHOUT_SIGN_UP'
		| 'SKIP_ANSWERING_TO_VIEW_ALL_ANSWERS'
		| 'USER_LOGIN_PRESS'
		| 'USER_LOGIN_PRESS_GOOGLE'
		| 'USER_LOGIN_PRESS_APPLE'
		| 'USER_SIGN_UP_PRESS'
		| 'USER_ILL_DO_THIS_LATER_PRESS'
		| 'FIRST_TIME_ON_READ_CHAPTER_PRESS_OF_A_MICRO_COURSE'
		| 'USER_ADDED_ANSWER'
		| 'READ_CHAPTER_PRESS'
		| 'MICRO_COURSE_START'
		| 'MICRO_COURSE_COMPLETED'
		| 'AUTH_TRIGGERED'
		| 'LANDING_PAGE_TAG_PRESS'
		| 'LANDING_PAGE_EXPLORE_ALL_PRESS'
		| 'LANDING_PAGE_GET_STARTED_PRESS'
		| 'FEED_POST_VIEW'
		| 'FEED_MICRO_COURSE_VIEW'
		| 'FEED_PATHWAY_VIEW'
		| 'FEED_VIEW'
		| 'LANDING_CONTROL_CLICKED'
		| 'LANDING_LOGIN_EXPERIMENT_CLICKED'
		| 'EXPLORE_CONTROL_CLICKED'
		| 'EXPLORE_LOGIN_EXPERIMENT_CLICKED'
		| 'MICRO_COURSE_CHAPTER_CONTROL_CLICKED'
		| 'MICRO_COURSE_CHAPTER_LOGIN_EXPERIMENT_CLICKED'
		| 'LANDING_MICRO_COURSE_CLICKED'
		| 'SEARCHED'
		| 'LIBRARY_CREATE_LIST'
		| 'LIBRARY_RENAME_LIST'
		| 'LIBRARY_ADD_TO_LIST'
		| 'LIBRARY_REMOVE_FROM_LIST'
		| 'LIBRARY_DELETE_LIST'
		| 'EXPLORE_TAG_PRESS'
		| 'FILTERED_BY_CONTENT_TYPE'
		| 'FILTERED_BY_INDUSTRY'
		| 'FORGOT_PASSWORD_PRESSED'
		| 'PASSWORD_RESET_EMAIL_REQUESTED'
		| 'MY_CHET_CLICKED'
		| 'PROFILE_CLICKED'
		| 'BECOME_CURATOR_PRESSED'
		| 'CURATOR_STUDIO_PRESSED'
		| 'USER_LOGOUT_PRESSED'
		| 'EXPLORE_LIST_SCROLL'
		| 'LIST_ITEM_OPENED'
		| 'CURATOR_INFO_CLICKED'
		| 'CHAPTER_SELECTED'
		| 'CHAPTER_CLOSED_WITHOUT_MARK_COMPLETE'
		| 'READ_NEXT_CHAPTER_PRESSED_FROM_NEXT_MODAL'
		| 'BACK_TO_MICRO_COURSE_PRESSED_FROM_NEXT_MODAL'
		| 'VIEW_CHAPTER_ON_EXTERNAL_SITE_PRESSED'
		| 'EXIT_MICRO_COURSE_COMPLETION_MODAL'
		| 'SHARE_MICRO_COURSE_COMPLETION'
		| 'SHARE_FACEBOOK'
		| 'SHARE_EMAIL'
		| 'SHARE_TWITTER'
		| 'SHARE_LINKEDIN'
		| 'OPEN_PATHWAY_SECTION'
		| 'SEARCH_PAGE_CHANGED'
		| 'SOCIAL_LINK_CLICKED'
		| 'COMPANY_LINK_CLICKED'
		| 'HEADER_LOGO_PRESSED'
		| 'RETURNED_TO_SEARCH_AFTER_VIEWING_ITEM'
		| 'POST_LIKED'
		| 'POST_UNLIKED'
		| 'COMMENT_LIKED'
		| 'COMMENT_UNLIKED'
		| 'FEED_CURATOR_PROFILE_OPENED'
		| 'FEED_CHAPTER_READ_MORE_PRESSED'
		| 'FEED_POST_READ_MORE_PRESSED'
		| 'FEED_MICRO_COURSE_OPENED'
		| 'FEED_CHAPTER_COMMENT_PRESSED'
		| 'FEED_POST_COMMENT_PRESSED'
		| 'FEED_CHAPTER_LIKED'
		| 'FEED_POST_LIKED'
		| 'FEED_CHAPTER_UNLIKED'
		| 'FEED_POST_UNLIKED'
		| 'FEED_MICRO_COURSE_OPENED_FROM_CHAPTER'
		| 'USER_FOLLOWED'
		| 'USER_UNFOLLOWED'
		| 'APPLIED_TO_JOB';

	export const track = (event: Analytics.EVENT, properties: Record<string, string | null | boolean | string[]> = {}): void => {
		const { analytics } = window;
		if (!analytics) return;
		analytics.track(event, properties);
	};

	export const setDeviceType = (type: string | undefined): void => {
		deviceType = type;
	};
	export const setIsCordova = (value: boolean): void => {
		isCordova = value;
	};

	export const page = (page: Analytics.PAGE, properties: Record<string, string | null | JSONType> = {}): void => {
		const { analytics } = window;
		if (!session_id) {
			session_id = Date.now();
		}
		let props = properties;
		const extra: Record<string, string> = isCordova && deviceType ? { deviceType } : {};
		props = {
			...properties,
			integrations: { Amplitude: { session_id } },
			...extra,
		};
		if (!analytics) {
			return;
		}
		analytics.page(page, ' ', props);
	};

	export const identify = (user: User): void => {
		const { analytics } = window;
		if (!analytics) {
			return;
		}
		analytics.identify(user.id, pick(user, ['id', 'name', 'describesYou', 'recentJobTitle', 'email']), {}, () => {});
	};
	export const getCordova = (): boolean => {
		return !!isCordova;
	};
}

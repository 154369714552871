import { TextFieldProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ActionForm from 'Components/ActionForm';
import { UserList } from 'Models/UserList/@types';
import UserListModel from 'Models/UserList';
import React from 'react';
import { RowSchema } from 'react-forms';
import { Font1Semibold } from 'Theme';
// eslint-disable-next-line import/no-cycle
import { useStoreState } from 'Stores';
import * as Yup from 'yup';
import useAppDialog from './useAppDialog';

export interface CreateListConfig {
	postSubmitTask?: (list: UserList) => void | Promise<void>;
}

const useCreateList = (config?: Partial<CreateListConfig>): { createNewList: () => void } => {
	const { postSubmitTask } = config ?? {};

	const classes = styles();

	const { user } = useStoreState(({ Auth: { user } }) => ({ user }));

	const { withDialog, hideDialog } = useAppDialog();

	const submitNewListForm = async (values: Partial<UserList>): Promise<UserList> => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const newList = await UserListModel.createUserList(user?.id ?? '', values.name!); // Validation exists to ensure name is present
		if (newList) {
			await postSubmitTask?.(newList);
			hideDialog();
		}
		return newList;
	};

	const createNewList = () => {
		withDialog(
			<ActionForm<UserList>
				heading="Create a new list"
				headingProps={{ className: classes.newListFormHeading }}
				initialValues={{}}
				schema={getFormSchema(classes)}
				hideDialog={hideDialog}
				validation={newListValidation}
				onSubmit={submitNewListForm}
				submitButtonText="Create"
			/>,
			{ isActionCloseButton: false }
		);
	};
	return { createNewList };
};
export default useCreateList;

const styles = makeStyles<Theme>((theme) => ({
	label: {
		color: theme.palette.common.black,
		fontFamily: Font1Semibold,
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

const newListValidation = Yup.object({
	name: Yup.string().min(4, 'New list name should be at least 4 characters long').required('Name is required'),
});

const getFormSchema = (classes: Record<string, string>): RowSchema[] => {
	return [
		{
			type: 'text',
			valueKey: 'name',
			fieldProps: {
				label: 'List title',
				InputLabelProps: {
					classes: { shrink: classes.label, root: classes.label },
					shrink: true,
				},
			} as TextFieldProps,
		},
	];
};

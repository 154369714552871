import { JSONType } from 'Typings/@types';
import { parseToExploreList, parseToListDocument } from 'Models/ExploreList/ExploreListParsers';
import { ExploreList, ExploreListDocumentType } from 'Models/ExploreList/@types';
import { MicroCourse } from 'Models/MicroCourse/@types';
import { Post } from 'Models/Post/@types';
import { Chapter } from 'Models/Chapter/@types';
import { Pathway } from 'Models/Pathway/@types';
import { User } from 'Models/User/@types';
import { AppConstants } from 'Models/App/@types';
import { ExploreHome, ListDocument, TExploreHomeSubjectType } from './@types';

export const parseToExploreHome = (obj: JSONType): ExploreHome => {
	const data = parseToExploreList(obj);
	return { ...data } as unknown as ExploreHome;
};

export const parseForYouToExploreHomeItems = (obj: JSONType, appConstants: AppConstants): ExploreHome[] => {
	if (!obj) return [];
	const exploreHomeArray: ExploreHome[] = Object.keys(obj).map((key, index) =>
		parseToExploreHome({
			id: `1630672241291${index}${Date.now().toString()}`,
			subjectId: `1630672241291${index}`,
			subjectType: 'ExploreList' as TExploreHomeSubjectType,
			subject: {
				id: `1630672241291${index}${Date.now().toString()}`,
				name: getNameForItem(key, obj[key], appConstants),
				'list-documents': obj[key]?.filter((item: { subject: ListDocument }) => item.subject) ?? [],
				created: Date.now().toString(),
				updated: Date.now().toString(),
				type: obj[key]?.map((item: { subjectType: ExploreListDocumentType }) => item.subjectType) ?? [],
			},
			order: index,
		})
	);

	return exploreHomeArray.filter((item) => (item.subject as ExploreList)?.listDocuments?.length > 0);
};
export const getNameForItem = (key: string, obj: Array<{ tag: { name: string }; listType: string }>, appConstants: AppConstants): string => {
	if ((appConstants.FeedLabelMap as JSONType)[obj[0]?.listType]) return (appConstants.FeedLabelMap as JSONType)[obj[0]?.listType];
	// if (obj[0]?.listType === 'newestCollectionAndLinks') return 'Newest collections and links';
	// if (obj[0]?.listType.toLowerCase().includes('curator')) return 'Popular curators';
	if (obj[0]?.tag?.name) return `Top for you in ${obj[0]?.tag?.name}`;
	// eslint-disable-next-line no-console
	console.log({ obj }, 'failed to match selection criteria. Returning `Most popular on Chet`');
	return 'Most popular on Chet';
};

// eslint-disable-next-line camelcase
export const parseTagEntities = (entities: Array<(MicroCourse | Pathway | Chapter | Post | User) & { doc_type: ExploreListDocumentType }>): ListDocument[] => {
	return entities.map((entity) => parseToListDocument({ id: entity.id, subject: { ...entity }, subjectType: entity.doc_type ? entity.doc_type : determineSubjectTypeOfEntity(entity) }));
};

export const determineSubjectTypeOfEntity = (entity: MicroCourse | Pathway | Chapter | Post | User): ExploreListDocumentType => {
	if ((entity as MicroCourse)?.chapterIds?.length) {
		return 'MicroCourse';
	}
	if ((entity as Pathway)?.pathwayStepIds?.length) return 'Pathway';
	if ((entity as User)?.name || (entity as User)?.firstName) return 'user';
	if ((entity as Post)?.postType === 'chapter') return 'Chapter';
	return 'Post';
};

import React, { FC, useContext, useState } from 'react';
import { Box, Button, Drawer, IconButton, makeStyles, MenuItem, Link as MUILink, Paper } from '@material-ui/core';
import { X } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line import/no-cycle
import { useStoreActions, useStoreState } from 'Stores';
// eslint-disable-next-line import/no-cycle
import { HeaderContext } from 'Contexts/HeaderContext';
// import HeaderMenu from 'Components/HeaderMenu';
import { UilSubject } from '@iconscout/react-unicons';
import { DeviceSpecificHeaderProps } from './@types';
import { LOGO, loggedInHeaderLinks, HEADER_MOBILE_HEIGHT, loggedOutHeaderLinks } from './Constants';
// import SearchForm from './SearchForm';
// import { getMenuItems } from './utils';

interface HeaderSmProps extends DeviceSpecificHeaderProps {}
const HeaderSm: FC<HeaderSmProps> = ({ resolvedUserIcon, logoLink }) => {
	const classes = styles();
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const history = useHistory();
	const { logout } = useStoreActions(({ Auth: { logout } }) => ({ logout }));
	const { user } = useStoreState(({ Auth: { user } }) => ({ user }));
	const { additionalContent } = useContext(HeaderContext);

	const handleLogout = async () => {
		await logout();
		setMenuOpen(false);
		history.push('/');
	};

	const closeMenu = () => setMenuOpen(false);
	// // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	// const menuItems = getMenuItems(user!, handleLogout);

	return (
		<Paper className={classes.root} square>
			<Box px="12px" py={1} display="flex" alignItems="center" width="100%">
				<Box display="flex" alignItems="center" justifyContent="space-between" flex={1} width="100%">
					<Box display="flex" alignItems="center">
						<Box display="flex" alignItems="center">
							<Button aria-label="open menu" className={classes.menuButton} onClick={() => setMenuOpen(true)}>
								<UilSubject />
							</Button>
						</Box>
						<Link to={logoLink}>
							<img src={LOGO} alt="Chet logo" height={20} />
						</Link>
						<Drawer classes={{ paper: classes.paper }} anchor="left" open={menuOpen}>
							<Box pt={1} pr={1} display="flex" justifyContent="flex-end">
								<IconButton aria-label="close menu" size="small" onClick={closeMenu}>
									<X />
								</IconButton>
							</Box>
							<Box display="flex" alignItems="center" flexDirection="column" width="100%" justifyContent="center" pb={15} height="100%">
								<Box>
									{user
										? loggedInHeaderLinks.map((headerLink) =>
												headerLink.link[0] === '/' ? (
													<Link key={headerLink.link} className={classes.link} to={headerLink.link}>
														<MenuItem onClick={closeMenu}>{headerLink.label}</MenuItem>
													</Link>
												) : (
													<MUILink key={headerLink.link} className={classes.link} href={headerLink.link}>
														<MenuItem onClick={closeMenu}>{headerLink.label}</MenuItem>
													</MUILink>
												)
										  )
										: null}
									{loggedOutHeaderLinks.map((headerLink) => (
										<MUILink key={headerLink.link} className={classes.link} href={headerLink.link}>
											<MenuItem onClick={closeMenu}>{headerLink.label}</MenuItem>
										</MUILink>
									))}
									{Array.isArray(resolvedUserIcon) && resolvedUserIcon.length > 1
										? resolvedUserIcon.map((item) => (
												<MenuItem className={classes.link} key={item.key ?? Math.random().toString()} onClick={closeMenu}>
													{item}
												</MenuItem>
										  ))
										: null}
								</Box>
								{user && !_isEmpty(user) ? (
									<MenuItem onClick={handleLogout} className={classes.link}>
										Log Out
									</MenuItem>
								) : null}
							</Box>
						</Drawer>
					</Box>
					{Array.isArray(resolvedUserIcon) && resolvedUserIcon.length > 1 ? resolvedUserIcon[1] : resolvedUserIcon}
				</Box>
			</Box>
			{additionalContent}
		</Paper>
	);
};

export default HeaderSm;

const styles = makeStyles((theme) => ({
	root: {
		position: 'sticky',
		top: 0,
		left: 0,
		zIndex: 5,
		boxSizing: 'border-box',
		boxShadow: `0px 2px 0px 0px ${theme.palette.grey['200']}`,
		minHeight: HEADER_MOBILE_HEIGHT,
	},
	paper: {
		width: '100%',
	},
	link: {
		display: 'inline-flex',
		justifyContent: 'center',
		width: '100%',
		textDecoration: 'none',
		color: theme.palette.text.primary,
		'&:hover': {
			textDecoration: 'none',
		},
		'& li': {
			width: '100%',
			display: 'inline-flex',
			justifyContent: 'center',
			color: theme.palette.text.primary,
		},
	},
	menuButton: {
		padding: 0,
		borderRadius: 4,
		minWidth: 'unset',
		marginRight: 8,
		'& .MuiButton-label': {
			width: 'unset',
			color: theme.palette.text.primary,
		},
	},
}));

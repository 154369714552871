import { parseToJobListing } from 'Models/JobListing/JobListingParsers';
/* eslint-disable no-underscore-dangle */
import { JSONType } from 'Typings/@types';
import { Company, CompanyListicle } from './@types';

export const parseToCompany = (obj: JSONType): Company => {
	const jobListings = obj.jobListings?.map(parseToJobListing);
	return { ...obj, companyLogo: obj._companyLogo, jobListings } as Company;
};

export const parseToCompanyListicle = (obj: JSONType): CompanyListicle => {
	const companies = obj.companies ? obj.companies.map(parseToCompany) : undefined;
	return { ...obj, companies } as CompanyListicle;
};

import React, { ChangeEvent, ReactNode, useRef, useState } from 'react';
import { getFieldError, IFieldProps, MUIFileInput, processFilesWithCallback, TFile } from 'react-forms';
import { Box, makeStyles, createStyles, CircularProgress, Theme, Button, Typography, Tab, Fab, TextField, Grid, FormHelperText } from '@material-ui/core';
import { Picture } from 'Models/Picture/@types';
import PictureModel from 'Models/Picture';
import { parseToPicture } from 'Models/Picture/PictureParsers';
import useAsyncTask from 'Hooks/useAsyncTask';
import { JSONType } from 'Typings/@types';
import { THEME_PALETTE } from 'Theme/themeConstants';
import Typo from 'Components/Typo';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import SvgIcon from 'Components/SvgIcon';
import ICONS from 'Constants/icons.json';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spacer from './Spacer';

export interface MultiTabImagePickerFieldProps<T = unknown> {
	name: string;
	label: string;
	instruction?: string;
	buttonLabel?: string | JSX.Element;
	imageWidth: number; // This has to be a number because this is the width that will be applied for transforming the url using imageKit's api.
	imagePlaceholderHeight: number; // This has to be a number because this is the height that will be applied for transforming the url using imageKit's api.
	customParser?: (img: unknown) => Picture | string;
	rootClass?: string;
	tabConfig?: TabConfigItem<T>[];
}

export interface TabConfigItem<T = unknown> {
	id: string;
	label: ReactNode | string;
	tabContent?: ReactNode | string;
	shouldHaveSearchField?: boolean;
	onKeywordChange?: (name: string, pageNumber?: number) => Promise<{ img: string; value: T }[]>;
}

export interface MultiTabImagePickerProps<T = unknown> extends IFieldProps {
	fieldProps?: MultiTabImagePickerFieldProps<T>;
}

function MultiTabImagePicker<T>(props: MultiTabImagePickerProps<T>): React.ReactElement {
	const { fieldProps = {} as MultiTabImagePickerFieldProps, formikProps } = props;
	const {
		label = '',
		buttonLabel = 'Choose an image',
		name = '',
		imageWidth = 72,
		imagePlaceholderHeight = 250,
		customParser,
		instruction = 'Image wider than 1500 pixels work best.',
		tabConfig,
	} = fieldProps;
	const fieldError = getFieldError(name, formikProps as JSONType);
	const image: Picture | string | null | undefined = get(formikProps, `values.${name}`);
	const classes = useStyles({ imgWidth: imageWidth });
	const [images, setImages] = useState<{ img: string; value: T }[]>([]);
	const [tab, setTab] = useState<string>('upload');
	const [hasMoreImages, setHasMoreImages] = useState(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const searchTerm = useRef<string>('');
	const pictureUpload = async (prop: { imgs: TFile[]; _rem: unknown[] }) => {
		const { imgs } = prop;
		const img = await PictureModel.upload(imgs[0]);
		if (img) {
			if (!customParser) formikProps?.setFieldValue(name, parseToPicture(img));
			else formikProps?.setFieldValue(name, customParser(img));
		}
	};
	const pictureUploadTask = useAsyncTask(pictureUpload);

	const onTabChange = (_event: React.ChangeEvent<unknown>, newValue: string) => {
		setTab(newValue);
	};

	const wrapWith = (input: JSX.Element) => {
		return (
			<Box width="100%">
				<Box>
					{image && !isEmpty(image) ? (
						<Box position="relative">
							<img alt="img" src={PictureModel.getTransformedUrl(typeof image !== 'string' ? image.url : image, imageWidth, imagePlaceholderHeight)} className={classes.selectedImage} />
							<Fab
								className={classes.editIcon}
								size="small"
								color="primary"
								onClick={() => {
									formikProps?.setFieldValue(name, null);
								}}
							>
								<SvgIcon icon={ICONS.pencil} size={20} variant="light" />
							</Fab>
						</Box>
					) : (
						<TabContext value={tab}>
							<Box sx={{ border: `1px solid ${THEME_PALETTE.blue.A400}` }} borderRadius={8}>
								<Box borderBottom={`1px solid ${THEME_PALETTE.blue.A400}`}>
									<TabList onChange={onTabChange} className={classes.tabList}>
										<Tab label="Upload" {...a11yProps(0)} value="upload" disableRipple className={classes.tab} />
										{tabConfig?.map((tabConfigRef, index) => (
											<Tab key={tabConfigRef.id} label={tabConfigRef.label} value={tabConfigRef.id} {...a11yProps(index + 1)} disableRipple className={classes.tab} />
										))}
									</TabList>
								</Box>
								<TabPanel value="upload">
									<Box width="max-content" m="0 auto">
										{pictureUploadTask.status !== 'PROCESSING' ? (
											<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
												<Button color="primary" variant="contained" className={classes.btn}>
													{buttonLabel}
													{input}
												</Button>
												<Box sx={{ mt: 1 }}>
													<Typography className={classes.instruction}>{instruction}</Typography>
												</Box>
											</Box>
										) : (
											<CircularProgress />
										)}
									</Box>
								</TabPanel>
								{tabConfig?.map((tabConfigRef) => {
									const { shouldHaveSearchField = true, id, tabContent } = tabConfigRef;
									const debouncedCaller = debounce(async (search: string, page = 1) => {
										if (!search) return;
										if (tabConfigRef.onKeywordChange) {
											try {
												const res = (await tabConfigRef.onKeywordChange(search, page)) as { img: string; value: T }[];
												if (!res) {
													setHasMoreImages(false);
												}
												if (page === 1) setImages(res || []);
												else setImages([...images, ...(res || [])]);
											} catch (err) {
												setHasMoreImages(false);
											}
										}
									}, 500);
									const onchangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
										// assumption is that this is called only when page = 1
										setCurrentPage(1);
										setHasMoreImages(true);
										searchTerm.current = event.target.value;
										debouncedCaller(event.target.value);
									};

									return (
										<TabPanel key={id} value={tabConfigRef.id}>
											{shouldHaveSearchField ? (
												<TextField onChange={onchangeHandler} placeholder="Search for an image..." fullWidth variant="outlined" className={classes.searchField} />
											) : null}
											<Spacer height={24} />
											<Box maxHeight={340} minHeight={100} overflow="hidden auto">
												<InfiniteScroll
													dataLength={images.length} // This is important field to render the next data
													next={() => {
														if (searchTerm.current) debouncedCaller(searchTerm.current, currentPage + 1);
														setCurrentPage(currentPage + 1);
													}}
													hasMore={hasMoreImages}
													style={{ overflow: 'hidden' }}
													loader={
														<Box width="100%" display="flex" justifyContent="center" py={10}>
															<CircularProgress />
														</Box>
													}
												>
													<Grid container spacing={2} component={Box} height="100%" role="listbox">
														{images.map((image) => {
															return (
																<Grid
																	item
																	key={image.img}
																	lg={3}
																	role="listitem"
																	onClick={() => {
																		formikProps?.setFieldValue(name, image.value);
																	}}
																>
																	<img src={image.img} alt="" className={classes.image} />
																</Grid>
															);
														})}
													</Grid>
												</InfiniteScroll>
											</Box>
											{tabContent}
										</TabPanel>
									);
								})}
							</Box>
						</TabContext>
					)}

					{(fieldError || (pictureUploadTask.status === 'ERROR' && pictureUploadTask.message === 'request entity too large')) && (
						<FormHelperText error>{fieldError.url || (typeof fieldError !== 'object' ? fieldError : false) || pictureUploadTask.message}</FormHelperText>
					)}
				</Box>
			</Box>
		);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		files && processFilesWithCallback(files, (prop: { imgs: TFile[]; _rem: unknown[] }) => pictureUploadTask.run(prop));
	};

	return (
		<Box>
			<Box sx={{ mb: 1, ml: 1 }}>
				<Typo weight="medium">{label}</Typo>
			</Box>
			<Box width={650} display="flex" justifyContent="center" alignItems="center">
				<MUIFileInput
					fieldProps={{
						name,
						wrapWith,
						nativeInputProps: { onChange: handleChange },
					}}
					formikProps={formikProps}
				/>
			</Box>
		</Box>
	);
}

export default MultiTabImagePicker;

const useStyles = makeStyles<Theme, { imgWidth: number }>((theme) =>
	createStyles({
		btn: {
			padding: theme.spacing(1, 11),
			borderRadius: 5,
			backgroundColor: THEME_PALETTE.blue.A100,
		},
		editIcon: {
			position: 'absolute',
			backgroundColor: THEME_PALETTE.blue.A100,
			width: 55,
			height: 55,
			bottom: 10,
			right: 16,
		},
		selectedImage: {
			borderRadius: 8,
			width: ({ imgWidth }) => imgWidth,
			maxHeight: 210,
			objectFit: 'cover',
		},
		image: {
			width: '100%',
			borderRadius: 8,
			objectFit: 'cover',
			cursor: 'pointer',
		},
		tab: {
			fontSize: 16,
			minWidth: 'unset',
			'& span': {
				flexDirection: 'row',
				gap: 8,
			},
		},
		instruction: {
			color: THEME_PALETTE.grey.B600,
		},
		searchField: {
			backgroundColor: THEME_PALETTE.blue[900],
		},
		tabList: {
			'& .MuiTabs-indicator': {
				display: 'flex',
				justifyContent: 'center',
				backgroundColor: 'transparent',
				'&::before': {
					content: "' '",
					transform: 'translateY(-7px)',
					maxWidth: 75,
					width: '100%',
					backgroundColor: THEME_PALETTE.common.black,
				},
			},
		},
	})
);

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

export interface CustomIconProps {
	icon: string; // file name
	className?: string;
	size?: number | string;
	variant?: 'grey' | 'light' | 'dark' | 'primary' | 'none';
}

const resolvePath = (path: string) => {
	try {
		// eslint-disable-next-line @typescript-eslint/no-var-requires,  import/no-dynamic-require, global-require
		return require(`../Assets/icons/${path}`).default;
	} catch (error) {
		return '';
	}
};

// Fix this later
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomIcon = React.forwardRef<any, CustomIconProps>((props, ref) => {
	const { icon, className, variant = 'grey', size = 24 } = props;
	const classes = useStyles({ size });
	const variantKey = `variant${variant}`;
	return <ReactSVG ref={ref} src={resolvePath(icon)} className={clsx(classes.root, classes[variantKey], className || '')} />;
});

const useStyles = makeStyles<Theme, { size: number | string }>((theme) => {
	const {
		palette: { grey },
	} = theme;
	return createStyles({
		root: {
			height: (props) => props.size,
			width: (props) => props.size,
			lineHeight: 1.2,
			borderRadius: '50%',
			color: grey['500'],
			display: 'inline-block',
			'& svg': {
				height: (props) => props.size,
				width: (props) => props.size,
			},
			transition: '200ms linear',
		},
		variant: {},
		variantdark: {
			color: theme.palette.common.black,
			//  backgroundColor: theme.palette.primary['contrastText'],
			'& svg': {
				color: theme.palette.common.black,
				fill: theme.palette.common.black,
				// backgroundColor: theme.palette.primary['contrastText'],
			},
		},
		variantlight: {
			// backgroundColor: theme.palette.primary['main'],
			color: theme.palette.primary.contrastText,
			'& svg': {
				// backgroundColor: theme.palette.primary['main'],
				color: theme.palette.primary.contrastText,
				fill: theme.palette.primary.contrastText,
			},
		},
		variantgrey: {
			// backgroundColor: theme.palette.primary['contrastText'],
			color: '#949494',
			'& svg': {
				fill: '#949494',
			},
		},
		variantprimary: {
			// backgroundColor: theme.palette.primary['contrastText'],
			color: theme.palette.primary.main,
			'& svg': {
				fill: theme.palette.primary.main,
			},
		},
	});
});

export default CustomIcon;

import React from 'react';
import { Theme, TypographyProps, Typography, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Font1Bold, Font1Regular, Font2Bold, Font2Medium, Font2Regular, Font3Bold, Font3Medium, Font3Regular } from 'Theme';

export interface TypoProps extends TypographyProps {
	weight?: 'regular' | 'medium' | 'bold';
	underline?: boolean;
	contrast?: boolean;
	whiteSpace?: React.CSSProperties['whiteSpace'];
	component?: string;
	limitLines?: number;
	font?: 'Font1' | 'Font2' | 'Font3';
	ignoreUrl?: boolean;
}

const Typo: React.FC<TypoProps> = (props) => {
	const { children, weight = 'regular', limitLines, whiteSpace, underline = false, contrast = false, font, ignoreUrl, ...typographyProps } = props;
	const theme = useTheme();
	const style: React.CSSProperties = {
		...typographyProps.style,
		textDecoration: underline ? 'underline' : undefined,
		...getFont(theme, weight, font),
		whiteSpace,
		color: contrast ? 'white' : undefined,
		...(limitLines !== undefined ? { lineClamp: limitLines, WebkitLineClamp: limitLines, display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden' } : {}),
	};

	if (typeof children === 'string' && isUrl(children) && !ignoreUrl) {
		return (
			<Link rel="noopener noreferrer" href={children} target="_blank" {...typographyProps} style={{ ...style, cursor: 'pointer' }}>
				{props.children}
			</Link>
		);
	}

	return (
		<Typography color="textPrimary" {...typographyProps} style={style}>
			{children}
		</Typography>
	);
};

const getFont = (theme: Theme, weight: TypoProps['weight'], font: TypoProps['font']) => {
	if (weight === 'bold') {
		if (font === 'Font3') return { fontFamily: Font3Bold };
		if (font === 'Font2') return { fontFamily: Font2Bold };
		return { fontFamily: Font1Bold };
	}
	if (weight === 'medium') {
		if (font === 'Font3') return { fontFamily: Font3Medium };
		if (font === 'Font2') return { fontFamily: Font2Medium };
		return { fontFamily: Font1Regular, fontWeight: theme.typography.fontWeightMedium };
	}
	if (weight === 'regular') {
		if (font === 'Font3') return { fontFamily: Font3Regular };
		if (font === 'Font2') return { fontFamily: Font2Regular };
		return { fontFamily: Font1Regular };
	}
	return { fontFamily: Font1Regular };
};

const isUrl = (text: string) => {
	return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi.test(text);
};

export default Typo;

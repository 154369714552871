import { Token } from 'Models/Auth/@types';
import { MicroCourse } from 'Models/MicroCourse/@types';
import { parseToMicroCourse } from 'Models/MicroCourse/MicroCourseParsers';
// import { MicroCourse } from 'Models/MicroCourse/@types';
import AxiosUtils, { request } from 'Resources/AxiosUtils';
import { JSONType, Params } from 'Typings/@types';
import _startCase from 'lodash/startCase';
import set from 'lodash/set';
import { Cursor, ExploreFeed, Follow, SuggestedCurator, SuggestedMicroCourse, User, UserContentResponse, UserFeedResponse } from './@types';
import { parseFeed, parseToCompletedMicroCourse, parseToSuggestedCurator, parseToSuggestedMicroCourse, parseToUser, parseToUserFeedResponse, parseUser } from './UserParsers';

class UserModel {
	static getFeaturedUsers = async (params: Params): Promise<User[]> => {
		const users = await request<JSONType>({
			url: 'users/featuredProfessionals',
			method: 'GET',
			params,
		});
		return users.map(parseToUser);
	};

	static getUserProfession = (user?: User): string => {
		if (!user) {
			return '';
		}
		return `${user.recentJobTitle ? user.recentJobTitle : ''} ${user.recentJobTitle && user.recentCompany ? 'at' : ''} ${user.recentCompany ?? ''}`;
	};

	static login = async (credentials: { email: string; password: string; isWeb: boolean }): Promise<Token> => {
		const data = await request<JSONType>({
			url: '/users/login',
			method: 'post',
			data: credentials,
		}).catch((err) => {
			AxiosUtils.throwError(err);
		});

		const token: Token = {
			id: data?.id,
			userId: data?.userId,
			ttl: data?.ttl,
			created: data?.created,
		};

		// eslint-disable-next-line consistent-return
		return token;
	};

	static signUp = async (credentials: Partial<User>, web: boolean): Promise<{ user: User; token: Token }> => {
		const user = await request<JSONType>({
			url: '/users/signup',
			method: 'POST',
			data: { ...credentials, web },
		}).catch(AxiosUtils.throwError);
		const token: Token = {
			id: user?.accessToken.id,
			userId: user?.accessToken.userId,
			ttl: user?.accessToken.ttl,
			created: user?.accessToken.created,
		};
		return { user: parseToUser(user ?? {}), token };
	};

	static logOut = async (): Promise<JSONType | undefined> => {
		// not the best typing. Will do for now.
		let resp;
		try {
			resp = await request({
				url: '/users/logout',
				method: 'post',
			});
		} catch (error) {
			// DO NOTHING
		}
		return resp;
	};

	static fetchMe = async (accessToken: string): Promise<User> => {
		const user = await request<User>({
			url: `/users/me`,
			headers: { Authorization: accessToken },
			params: {
				microCourseFilter: {
					include: ['tags'],
				},
				userFilter: {
					include: ['roles'],
				},
			},
		});
		return parseToUser(user);
	};

	static updateUser = async (data: Partial<User>): Promise<User> => {
		const user = await request<JSONType>({
			url: `/users/${data.id}`,
			method: 'PATCH',
			data: parseUser(data),
		});
		return parseToUser(user);
	};

	static getCompletedMicroCourses = async (userId: string, params: Params): Promise<MicroCourse[]> => {
		const completedMicroCourses = await request<JSONType[]>({
			url: `users/${userId}/markCompletes`,
			method: 'GET',
			params,
		});
		return completedMicroCourses.map(parseToCompletedMicroCourse);
	};

	static getIsUserRegistered = async (email: string, includeUserInfo?: boolean): Promise<boolean | { firstName: string; email: string }> => {
		const userRegistered = await request<boolean>({
			url: `users/is-registered`,
			method: 'GET',
			params: { email, includeUserInfo },
		});
		return userRegistered;
	};

	static updatePassword = async (oldPassword: string, newPassword: string): Promise<boolean> => {
		const { statusCode } = await request<JSONType>({
			url: 'users/update-password',
			method: 'PATCH',
			data: { oldPassword, newPassword },
		});
		if (statusCode === 200) return true;
		return false;
	};

	static getUser = async (id: string, params?: Params): Promise<User> => {
		const user = await request<JSONType>({
			url: `users/${id}`,
			method: 'GET',
			params,
		});
		return parseToUser(user);
	};

	static resetPassword = async (email: string): Promise<boolean> => {
		const res = await request<{ statusCode: number }>({
			url: 'users/forget-password',
			method: 'POST',
			data: { email },
		});
		return res.statusCode === 202;
	};

	static verifyToken = async ({ email, token }: { email: string; token: string }): Promise<User & { accessToken: string }> => {
		const res = await request({
			url: 'users/verify-token',
			method: 'POST',
			data: { email, token },
		});
		return parseToUser(res) as User & { accessToken: string };
	};

	static setPasswordAfterForget = async (data: { password: string }, accessToken: string): Promise<boolean> => {
		const res = await request<User>({
			url: 'users/update-password-token',
			method: 'POST',
			data,
			headers: { Authorization: accessToken },
		});
		return !!res.id;
	};

	static feed = async (filter?: JSONType, cursor?: Cursor, limitMultiplier?: number): Promise<UserFeedResponse> => {
		const res = await request<JSONType>({
			url: `users/feed_v2`,
			method: 'GET',
			params: { filter, cursor, limitMultiplier },
		});
		return parseToUserFeedResponse(res);
	};

	static recommendedMicroCourses = async (filter?: JSONType, count = 2): Promise<MicroCourse[]> => {
		const res = await request<MicroCourse[]>({
			url: `users/recommended-microcourses`,
			method: 'GET',
			params: {
				filter,
				count,
			},
		});
		return res.map(parseToMicroCourse);
	};

	static myContent = async (subjectTypes?: 'Pathway' | 'MicroCourse', filter?: JSONType): Promise<UserContentResponse> =>
		request<UserContentResponse>({
			url: `users/my-content`,
			params: {
				subjectTypes,
				filter,
			},
		});

	static getUserFullName = (user?: User): string => {
		if (!user) return '';
		if (user.firstName) return _startCase(`${user.firstName ?? ''} ${user.lastName ?? ''}`);
		return _startCase(user.name);
	};

	static followUser = (userIdToFollow: string): Promise<Follow> => {
		return request<Follow>({ url: `users/${userIdToFollow}/follow`, method: 'POST' });
	};

	static unFollowUser = (followId: string): Promise<{ count: number }> => {
		return request<{ count: number }>({ url: `follows/${followId}/unFollow`, method: 'DELETE' });
	};

	static getFollowedCurators = async (userId: string, params?: Params): Promise<User[]> => {
		const newParams = set(params ?? {}, 'filter.subject', 'user');
		const following = await request<JSONType[]>({
			url: `users/${userId}/following`,
			method: 'GET',
			params: newParams,
		});
		return following.map(parseToUser);
	};

	static getFollowedMicroCourses = async (userId: string, params?: Params): Promise<MicroCourse[]> => {
		const newParams = set(params ?? {}, 'filter.subject', 'MicroCourse');
		const following = await request<JSONType[]>({
			url: `users/${userId}/following`,
			method: 'GET',
			params: newParams,
		});
		return following.map(parseToMicroCourse);
	};

	static requestOfficeHours = async (userId: string, notes?: string): Promise<JSONType> => {
		const requestSuccess = await request<JSONType>({
			url: `users/${userId}/createOfficeHourRequest`,
			method: 'POST',
			data: { notes },
		});
		return requestSuccess;
	};

	static getSuggestedCurators = async (): Promise<SuggestedCurator[]> => {
		const suggestedCurators = await request<JSONType[]>({
			url: `users/suggested-curators`,
			method: 'GET',
		});
		return suggestedCurators.map(parseToSuggestedCurator);
	};

	static getSuggestedMicroCourses = async (): Promise<SuggestedMicroCourse[]> => {
		const suggestedCurators = await request<JSONType[]>({
			url: `AppModels/tag-related-collections`,
			method: 'GET',
		});
		return suggestedCurators.map(parseToSuggestedMicroCourse);
	};

	static getUserFeed = async (params?: Params): Promise<ExploreFeed> => {
		const exploreFeed = await request({ url: 'users/user-feed', method: 'GET', params });
		return parseFeed(exploreFeed);
	};

	static getGlobalFeed = async (params?: Params): Promise<ExploreFeed> => {
		const exploreFeed = await request({ url: 'users/global-feed', method: 'GET', params });
		return parseFeed(exploreFeed);
	};

	static getFollowers = async (id: string, params?: Params): Promise<User[]> => {
		const followers = await request<JSONType[]>({ url: `users/${id}/followers`, method: 'GET', params });
		return followers.map(parseToUser);
	};
}

export default UserModel;

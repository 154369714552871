import React, { FC } from 'react';
import PrivateRoute from 'Components/PrivateRoute';
import lazyImport from 'lazy-import';
import { CURATOR_STUDIO_PATH_PREFIX } from 'Routes/RouteConstants';

const EditMicroCourse = lazyImport('../../Screens/EditMicroCourse');
const CuratorStudioLesson = lazyImport('../../Screens/CuratorStudioLesson');
const CuratorStudioScreen = lazyImport('../../Screens/CuratorStudio');
const CuratorStudioFAQScreen = lazyImport('../../Screens/CuratorStudioFAQ');
const MicroCourseOverviewScreen = lazyImport('../../Screens/CuratorStudioMicroCourseOverview');

const CuratorStudioNavigation: FC = () => {
	return (
		<>
			<PrivateRoute exact path={MICRO_COURSE_EDIT_PATH} component={EditMicroCourse} />
			<PrivateRoute path={MICRO_COURSE_EDIT_LESSON_PATH} component={CuratorStudioLesson} />
			<PrivateRoute exact path={MICRO_COURSE_OVERVIEW_PATH} component={MicroCourseOverviewScreen} />
			<PrivateRoute exact path={CURATOR_STUDIO_FAQ_PATH} component={CuratorStudioFAQScreen} />
			<PrivateRoute path={CURATOR_STUDIO_PATH_PREFIX} exact component={CuratorStudioScreen} />
		</>
	);
};

export default CuratorStudioNavigation;
export const MICRO_COURSE_OVERVIEW_PATH = `${CURATOR_STUDIO_PATH_PREFIX}/microCourse/:slug`;
export const MICRO_COURSE_EDIT_PATH = `${MICRO_COURSE_OVERVIEW_PATH}/edit`;
export const MICRO_COURSE_ADD_LESSON_PATH = `${MICRO_COURSE_OVERVIEW_PATH}/lessons/new`;
export const MICRO_COURSE_EDIT_LESSON_PATH = `${MICRO_COURSE_OVERVIEW_PATH}/lessons/:lessonSlug`;
export const CURATOR_STUDIO_FAQ_PATH = `${CURATOR_STUDIO_PATH_PREFIX}/faq`;

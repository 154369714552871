import React, { FC, ReactElement } from 'react';
import { Box, makeStyles, Theme, BoxProps } from '@material-ui/core';
import clsx from 'clsx';
import Typo, { TypoProps } from './Typo';

interface IconTextProps {
	icon: ReactElement;
	text: string;
	space?: number;
	typoProps?: TypoProps;
	className?: string;
	iconOnRight?: boolean;
	boxProps?: Omit<BoxProps, 'display' | 'className' | 'alignItems' | 'flexDirection'>;
}

const IconText: FC<IconTextProps> = ({ icon, text, space = 4, typoProps, className, iconOnRight = false, boxProps }) => {
	const classes = styles({ space });
	return (
		<Box {...boxProps} display="flex" className={clsx(classes.root, className)} alignItems="center" flexDirection={iconOnRight ? 'row-reverse' : 'row'}>
			{icon}
			<Typo {...typoProps}>{text}</Typo>
		</Box>
	);
};

export default IconText;

const styles = makeStyles<Theme, { space: number }>(() => ({
	root: { gap: ({ space }) => space },
}));

import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useStoreState } from 'Stores';

interface PrivateRouteProps extends RouteProps {}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
	const { ...routeProps } = props;
	const { user } = useStoreState(({ Auth: { user } }) => ({ user }));

	const getComponent = () => {
		if (user) return <Route {...routeProps} />;
		return <Redirect to="/" />;
	};

	return getComponent();
};

export default PrivateRoute;

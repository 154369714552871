import React from 'react';
import ChapterListicleCard from 'Features/Explore/Cards/ChapterListicleCard';
import PostCard from 'Features/Explore/Cards/PostCard';
import MicroCourseCard from 'Features/MicroCourses/MicroCourseCard';
import PathwayCard from 'Features/Pathway/PathwayCard';
import { ListDocument } from 'Models/ExploreHome/@types';
import { MicroCourse } from 'Models/MicroCourse/@types';
import { Pathway } from 'Models/Pathway/@types';
import { Post } from 'Models/Post/@types';
import { Chapter } from 'Models/Chapter/@types';
import TagChip from 'Components/TagChip';
import { Tag } from 'Models/Tag/@types';
import { ExploreFeed, ExploreFeedHit, User } from 'Models/User/@types';
import { generatePath } from 'react-router';
import { TAG_PATH } from 'Routes/RouteConstants';
import UserListicleCard from 'Features/Explore/Cards/UserListicleCard';
import { Job } from 'Models/JobListing/@types';
import JobCard from 'Features/JobListings/JobCard';

// eslint-disable-next-line import/prefer-default-export
export const getCorrectCard = (listDocument: ListDocument, classes: Record<string, string>, shouldUseExperiment?: boolean, isMobile?: boolean): JSX.Element => {
	const { subject, subjectType } = listDocument;
	switch (subjectType) {
		case 'Pathway':
			return <PathwayCard pathway={subject as Pathway} className={classes.docCardRoot} shouldUseExperiment={shouldUseExperiment} />;
		case 'MicroCourse':
			return <MicroCourseCard microCourse={subject as MicroCourse} classes={{ root: classes.docCardRoot }} shouldUseExperiment={shouldUseExperiment} />;
		case 'Post':
			if ((listDocument.subject as Post).postType === 'post') return <PostCard post={subject as Post} classes={{ root: classes.docCardRoot }} shouldUseExperiment={shouldUseExperiment} />;
			return <ChapterListicleCard chapter={subject as Chapter} classes={{ root: classes.docCardRoot }} shouldUseExperiment={shouldUseExperiment} />;
		case 'user':
			return <UserListicleCard user={listDocument.subject as User} />;
		case 'Tag':
			return (
				<TagChip
					link={generatePath(TAG_PATH, { tagSlug: (listDocument.subject as Tag).value ?? ' ' })}
					tag={listDocument.subject as Tag}
					size={isMobile ? 'fullWidth' : undefined}
					variant="rounded"
					color="primary"
				/>
			);
		case 'Job':
			return <JobCard job={listDocument.subject as Job} />;
		default:
			// eslint-disable-next-line no-console
			console.error(` No card available for received subjectType:  ${subjectType}.`, { listDocument });
			return <></>;
	}
};
export const getRandomInRange = (range: { min: number; max: number }): number => {
	const delta = range.max - range.min;
	const rand = Math.ceil(range.min + Math.random() * delta);
	return rand;
};

export const addFakeLikeCounts = (res: ExploreFeed['results']): ExploreFeed['results'] => {
	return res.map(({ hit }) => {
		if (hit.doc_type === 'Post') {
			const post = hit as Post;
			return {
				hit: { ...post, likeCount: (post.likeCount ?? 0) + (post.externalLikes ?? 0), viewersInfo: { count: (post.viewersInfo?.count ?? 0) + (post.externalViews ?? 0) } } as ExploreFeedHit,
			};
		}
		return { hit };
	});
};

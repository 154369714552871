import React, { FC } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import ICONS from 'Constants/icons.json';
import useResponsive from 'Hooks/useResponsive';
import SvgIcon from './SvgIcon';

const ImagePickerLabel: FC = () => {
	const { isMobile } = useResponsive();
	const classes = styles();
	return (
		<Box position="relative" width={isMobile ? 84 : 140} height={isMobile ? 84 : 140} display="flex" alignItems="center" justifyContent="center">
			<Box position="absolute" right={isMobile ? 16 : 32} top={isMobile ? 19 : 38}>
				<Typography variant="subtitle2" className={classes.plus}>
					+
				</Typography>
			</Box>
			<SvgIcon variant="primary" icon={ICONS.addImage} size={isMobile ? 42 : 68} />
		</Box>
	);
};

export default ImagePickerLabel;
const styles = makeStyles((theme) => ({ plus: { color: theme.palette.grey['600'] } }));

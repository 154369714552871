import useAsyncTask from 'Hooks/useAsyncTask';
import { useEventListener } from 'Hooks/useEventListener';
import { useEffect, useState } from 'react';
import AxiosUtils from 'Resources/AxiosUtils';
import { useStoreActions } from 'Stores';
import Config from 'config';

const VALIDATE_CONFIG_PROPERTIES = ['BASE_URL', 'API_URL'];

const validateConfig = () => {
	VALIDATE_CONFIG_PROPERTIES.forEach((key) => {
		const val = Config.get(key);
		if (!val) throw new Error(`App config must define ${key}`);
	});
};

const useBoot = (): { isCompleted?: boolean } => {
	const [isCompleted, setIsCompleted] = useState(false);
	const {
		loadAuthInfo: loadAuthInfoAction,
		getAllTags,
		getAppConstants,
	} = useStoreActions(({ Auth: { loadAuthInfo }, App: { getAllTags, getAppConstants } }) => ({
		loadAuthInfo,
		getAllTags,
		getAppConstants,
	}));
	const loadAuthInfo = useAsyncTask(loadAuthInfoAction);
	const getAllTagsTask = useAsyncTask(getAllTags);
	const getAppConstantsTask = useAsyncTask(getAppConstants);
	useEventListener();

	useEffect(() => {
		AxiosUtils.setBaseAPI_URL(Config.get('API_URL') as string);

		validateConfig();
		/** Override console.log as per environment file */
		if (Config.get('CONSOLE_LOGGING') === false) {
			// eslint-disable-next-line no-console
			console.log = () => {};
		}

		Promise.all([loadAuthInfo.run({}), getAllTagsTask.run({}), getAppConstantsTask.run()])
			.then(() => {
				setIsCompleted(true);
			})
			.catch(() => {
				setIsCompleted(true);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { isCompleted };
};

export default useBoot;

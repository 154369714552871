import React, { FC } from 'react';
import { Avatar, AvatarProps, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import PictureModel from 'Models/Picture';

export interface ProfileImageWithLogoProps {
	profileSize: number;
	logoSize: number;
	profileImage?: string;
	companyLogo?: string;
	recentCompany?: string;
	styles?: React.CSSProperties;
	avatarClass?: string;
	name?: string;
	profileImageVariant?: AvatarProps['variant'];
}

const ProfileImageWithLogo: FC<ProfileImageWithLogoProps> = (props) => {
	const { profileImage, companyLogo, profileSize, recentCompany, styles, avatarClass, name, logoSize, profileImageVariant = 'rounded' } = props;
	const classes = useStyles({ profileSize, logoSize });
	return (
		<div className={clsx(classes.root)} style={styles}>
			<Avatar
				variant={profileImageVariant}
				src={PictureModel.getTransformedUrl(profileImage || '', profileSize * 2, profileSize * 2, 'face')}
				alt=""
				className={clsx(classes.profileImage, avatarClass)}
			>
				{name?.[0]}
			</Avatar>
			{companyLogo ? <Avatar alt={recentCompany || ''} src={PictureModel.getTransformedUrl(companyLogo, logoSize * 2, logoSize * 2, 'auto')} className={clsx(classes.companyLogo)} /> : null}
		</div>
	);
};

export default ProfileImageWithLogo;

const useStyles = makeStyles<Theme, { profileSize: number; logoSize: number }>((theme) =>
	createStyles({
		root: {
			position: 'relative',
			height: 'max-content',
			width: 'max-content',
		},
		profileImage: {
			width: ({ profileSize }) => profileSize,
			height: ({ profileSize }) => profileSize,
			background: theme.palette.primary.main,
			border: `2px solid ${theme.palette.common.white}`,
		},
		companyLogo: {
			position: 'absolute',
			right: -6,
			bottom: 0,
			height: ({ logoSize }) => logoSize,
			width: ({ logoSize }) => logoSize,
			boxShadow: '0px 0px 4px 0px #00000040',
		},
	})
);

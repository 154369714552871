export const THEME_PALETTE = {
	background: {
		default: '#F3F0E7',
		paper: '#ffffff',
	},
	common: {
		black: '#000000',
		white: '#FFFFFF',
	},
	action: {
		active: '#000',
	},

	others: {},

	success: {
		dark: '#2C8065',
		main: '#61C9A8',
		light: '#A2F9DD',
	},
	warning: {
		dark: '#F9C700',
		main: '#FBDA5A',
		light: '#FFEA95',
	},
	info: {
		dark: '#2328BA',
		main: '#5257FE',
		light: '#CACCFF',
	},
	primary: {
		dark: '#2328BA',
		main: '#5257FE',
		light: '#CACCFF',
		contrastText: '#FFFFFF',
	},
	secondary: {
		dark: '#2C8065',
		main: '#61C9A8',
		light: '#A2F9DD',
	},
	text: {
		primary: '#191C38',
		secondary: '#FFFFFF',
	},
	error: {
		dark: '#A84545',
		main: '#D66060',
		light: '#ED9292',
	},
	red: {
		'100': '#FF6FEE',
	},
	green: {
		'100': '#659883',
		'200': '#7DE4A4',
		'300': '#2AD8B3',
	},
	blue: {
		'100': '#646BB9',
		'200': '#484CD1',
		'300': '#8E93CB',
		'400': '#2C3062',
		'500': '#9EBBFF',
		'600': '#BAB8FF',
		'700': '#B8BCDF',
		'800': '#191C38',
		'900': '#FBFBFB',
		A100: '#695ef8',
		A200: '#E9EBFC',
		A300: '#CCC8FF',
		A400: '#DDDBF1',
		A500: '#EDECFF',
		A600: '#DAD8F6',
		A700: '#BAB1DF',
		A800: '#EAE4FF',
		B200: '#D7DAFE',
		B300: '#F1F3FF',
		B400: '#393DA1',
		B500: '#AAA4FF',
		B600: '#A0A4FF',
		B700: '#F7F7FF',
		B800: '#1D2153',
		B900: '#4045E2',
	},
	grey: {
		'100': '#FFFFFF',
		'200': '#F3F0E7',
		'300': '#E3E0D5',
		'400': '#D2CFC4',
		'500': '#95928D',
		'600': '#6E6B67',
		'700': '#3E3B37',
		'800': '#272318',
		'900': '#000000',
		A100: '#C2C2C2',
		A300: '#6A6494',
		A400: '#434343',
		A500: '#252525',
		A600: '#E6E6E6',
		A700: '#7E7E7E',
		A800: '#212429',
		A900: '#98989E',
		B100: '#C4C4C4',
		B200: '#E3E3E3',
		B300: '#838383',
		B400: '#ECECEC',
		B500: '#5C5C5C',
		B600: '#C6C6C6',
		B800: '#D6D6D6',
		B900: '#999999',
		C100: '#121212',
		C300: '#BFBCB6',
		C600: '#3E3B37',
	},
	yellow: {
		'100': '#FFD016',
		'200': '#FFA800',
	},
};

export const GRADIENTS = [];

export const DIVIDER_BORDER = `1px solid #E6E6E6`;
export const CARD_BORDER = `1px solid #C2C2C2`;

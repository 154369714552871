export const MICRO_COURSE_PATH = '/microCourse/:microCourseSlug';
export const MICRO_COURSE_REDIRECT_PATH = '/pathway/:pathwaySlug';
export const PATHWAY_PATH = '/pathway/:pathwaySlug';
export const PATHWAY_MICROCOURSE_PATH = '/pathway/:pathwaySlug/microCourse/:microCourseSlug';
export const CHAPTER_PATH = '/microCourse/:microCourseSlug/chapter/:chapterSlug';
export const COMMENTS_PATH = '/microCourse/:microCourseSlug/chapter/:chapterSlug/comments';
export const POST_PATH = '/post/:postSlug';
export const CURATOR_PROFILE_PATH = '/curator/:curatorSlug';
export const CURATOR_MICRO_COURSES_PATH = `${CURATOR_PROFILE_PATH}/collections/`;
export const CURATOR_JOURNEY_PATH = `${CURATOR_PROFILE_PATH}/journey/`;
export const CURATOR_FEED_PATH = `${CURATOR_PROFILE_PATH}/feed/`;
export const CURATOR_REQUEST_PATH = `${CURATOR_PROFILE_PATH}/office-hours/`;
export const CURATOR_FOLLOWERS_PATH = `${CURATOR_PROFILE_PATH}/followers/`;
export const CURATOR_ABOUT_PATH = `${CURATOR_PROFILE_PATH}/about/`; // Currently only required for mobile view.
export const EXPLORE_PATH = '/explore';
// DASHBOARD_PATH = '/dashboard',
export const REGISTER_PATH = '/register';
export const LOGIN_PATH = '/login';
export const RESET_PASSWORD_PATH = '/reset-password';
export const USER_HOME_PATH = '/home';
// USER_HOME_FEED_PATH = '/home/feed',
export const USER_HOME_CONTENT_PATH = '/home/content';
export const USER_HOME_PROFILE_PATH = '/home/profile';
export const USER_HOME_SUBSCRIPTIONS_PATH = '/home/subscriptions';
export const USER_HOME_FOLLOWING_PATH = '/home/following';
export const USER_HOME_DIGEST_PATH = '/home/digest';
export const FEED_PATH = '/feed';
export const USER_PROFILE_PATH = '/user/:userSlug';
export const SEARCH_PATH = '/search';
export const CURATOR_STUDIO_PATH_PREFIX = '/curator-studio';
export const CURATOR_STUDIO_PATH = CURATOR_STUDIO_PATH_PREFIX;
export const EXPLORE_HOME_PATH = `${EXPLORE_PATH}/home`;
export const EXPLORE_LIBRARY_PATH = `${EXPLORE_PATH}/library`;
export const EXPLORE_FEED_PATH = `${EXPLORE_PATH}/feed`;
export const JOB_LISTINGS_PATH = `${EXPLORE_PATH}/jobs`;
export const INDIVIDUAL_JOB_LISTING_PATH = `/jobs/:listingSlug`;
export const COMPANIES_PATH = `${EXPLORE_PATH}/companies`;
export const INDIVIDUAL_COMPANY_PATH = `/companies/:companySlug`;
export const COMPONENTS_TEST_PATH = '/components-test';
export const TAG_PATH = '/tag/:tagSlug';
export const LANDING_PATH = '/';
export const ABOUT_PATH = '/about';
// export const MISSION_PATH = '/mission';
export const CURATORS_INFO_PATH = '/curators';
export const CONTACT_US_PATH = '/contact-us';
export const APPLY_FOR_CURATOR_PATH = '/curators/apply';
export const ELITE_100_PATH = '/elite-100';
// For future sprint
export const UPCOMING_ITEMS_PATH = '/upcoming';

export const MAIN_CONTENT_WIDTH = 1152;

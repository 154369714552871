import React, { FC } from 'react';
import { Box, makeStyles, Paper, Link as MUILink } from '@material-ui/core';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Font1Bold } from 'Theme';
import { useStoreState, useStoreActions } from 'Stores';
import HeaderMenu from 'Components/HeaderMenu';
import Spacer from 'Components/Spacer';
import { Analytics } from 'analytics';
import { THEME_PALETTE } from 'Theme/themeConstants';
import { DeviceSpecificHeaderProps } from './@types';
import { LOGO, HEADER_DESKTOP_HEIGHT, loggedOutHeaderLinks } from './Constants';
// import SearchForm from './SearchForm';
import { getMenuItems } from './utils';
import SearchForm from './SearchForm';

interface HeaderLgProps extends DeviceSpecificHeaderProps {}
// const searchEnabledPages = [EXPLORE_PATH, SEARCH_PATH];
const HeaderLg: FC<HeaderLgProps> = ({ resolvedUserIcon, logoLink }) => {
	const history = useHistory();
	const { user } = useStoreState(({ Auth: { user } }) => ({ user }));
	const { logout } = useStoreActions(({ Auth: { logout } }) => ({ logout }));
	const handleLogout = async () => {
		await logout();
		history.push('/');
	};
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const menuItems = getMenuItems(user!, handleLogout);
	const classes = styles();
	return (
		<Paper className={classes.root} square>
			<Box className={classes.restrictedContainer}>
				<Box display="flex" alignItems="center">
					<Link
						to={logoLink}
						onClick={() => {
							Analytics.track('HEADER_LOGO_PRESSED', { userId: user?.id ?? null });
						}}
					>
						<img src={LOGO} alt="Chet logo" height={32} />
					</Link>
					<Spacer width={16} />
					{loggedOutHeaderLinks.map((headerLink) =>
						!headerLink.external ? (
							<NavLink
								key={headerLink.link}
								className={classes.headerLink}
								activeClassName={classes.activeLink}
								to={headerLink.link}
								onClick={() => Analytics.page(headerLink.analyticsPage, { source: 'navbar-link' })}
							>
								{headerLink.label}
							</NavLink>
						) : (
							<MUILink
								className={classes.headerLink}
								onClick={() => Analytics.page(headerLink.analyticsPage, { source: 'navbar-link' })}
								key={headerLink.link}
								target={headerLink.openInNewTab ? '_blank' : undefined}
								href={headerLink.link}
								underline="none"
								color="textPrimary"
								rel="noopener noreferrer"
							>
								{headerLink.label}
							</MUILink>
						)
					)}
					{/* <SearchForm /> */}
				</Box>
				<Box justifyContent="space-between" display="flex" className={classes.linksContainer}>
					<SearchForm />
					<Spacer width={20} />
					{Array.isArray(resolvedUserIcon) ? (
						resolvedUserIcon.map((userIconItem, index) => <React.Fragment key={index.toString()}>{userIconItem}</React.Fragment>)
					) : (
						<HeaderMenu hoverOver={resolvedUserIcon} menuItems={menuItems} />
					)}
				</Box>
			</Box>
		</Paper>
	);
};

export default HeaderLg;

const styles = makeStyles((theme) => ({
	root: {
		position: 'sticky',
		top: 0,
		zIndex: 5,
		boxSizing: 'border-box',
		boxShadow: `0px 2px 0px 0px ${theme.palette.grey['200']}`,
		minHeight: HEADER_DESKTOP_HEIGHT,
		paddingTop: theme.spacing(1.5),
		paddingBottom: theme.spacing(1.5),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.up('lg')]: {
			width: '100%',
			// margin: '0 auto',
		},
	},
	restrictedContainer: {
		maxWidth: 1440,
		margin: '0 auto',
		width: '100%',
		display: 'flex',
		paddingLeft: 172,
		paddingRight: 172,
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0, 2),
		},
	},
	headerLink: {
		textDecoration: 'none',
		color: theme.palette.text.primary,
		fontSize: '1.125rem',
		lineHeight: 1.2,
		fontWeight: 500,
		margin: theme.spacing(0, '0.75rem'),
		'&:visited': { color: theme.palette.text.primary },
		'&:hover': { color: THEME_PALETTE.grey.A300 },
	},
	linksContainer: {
		marginLeft: 'auto',
		display: 'flex',
		alignItems: 'center',
	},
	activeLink: {
		fontFamily: Font1Bold,
		color: theme.palette.primary.main,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'none',
		},
	},
}));

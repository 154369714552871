import { useState, useEffect, createRef } from 'react';

function useLayoutQuery(): { ref: React.RefObject<HTMLDivElement>; width: number; height: number } {
	const [width, setWidth] = useState(220);
	const [height, setHeight] = useState(220);
	const ref = createRef<HTMLDivElement>();

	useEffect(() => {
		if (!ref.current) return;
		setWidth(ref.current.clientWidth);
		setHeight(ref.current.clientHeight);
	}, [ref]);

	return {
		ref,
		width,
		height,
	};
}

export default useLayoutQuery;

import set from 'lodash/set';
import { request } from 'Resources/AxiosUtils';
import { JSONType, Params } from 'Typings/@types';
import { UserList, UserListItem } from './@types';
import { parseRecentlyViewedToUserList, parseToUserList, parseToUserListItem } from './UserListParsers';

class UserListModel {
	static getAllUserLists = async (userId: string, params?: Params): Promise<UserList[]> => {
		const res = await request<JSONType[]>({
			url: `/users/${userId}/userLists`,
			method: 'GET',
			params,
		});
		return res.map(parseToUserList);
	};

	static getUserList = async (userId: string, slugOrId: string, key: 'slug' | 'id' = 'slug', params?: Params): Promise<UserList> => {
		const res = await request<JSONType[]>({
			url: `/users/${userId}/userLists/`,
			method: 'GET',
			params: set(params ?? {}, `filter.where.${key}`, slugOrId),
		});
		return parseToUserList(res[0]);
	};

	static getUserListItems = async (userListId: string, params?: Params): Promise<UserListItem[]> => {
		const res = await request<JSONType[]>({ url: `/UserLists/${userListId}/userListItems`, method: 'GET', params });
		return res.map(parseToUserListItem);
	};

	static createUserList = async (userId: string, name: string): Promise<UserList> => {
		const res = await request<JSONType>({
			url: `users/${userId}/userLists/`,
			method: 'POST',
			data: { name },
		});
		return parseToUserList(res);
	};

	static addToUserList = async (userId: string, listId: string, data: { subjectType: UserListItem['subjectType']; subjectId: string }): Promise<UserListItem> => {
		const res = await request<JSONType>({
			url: `users/${userId}/${listId}/add-list-item`,
			method: 'POST',
			data: { listItemContent: data },
		});
		return parseToUserListItem(res);
	};

	static updateUserList = async (userId: string, listId: string, data: { listItemContent: Partial<UserList> }): Promise<UserList> => {
		const res = await request<JSONType>({
			url: `users/${userId}/upsert-list-item`,
			method: 'PATCH',
			data: { listItemContent: { ...data.listItemContent, id: listId } },
		});
		return parseToUserList(res);
	};

	static deleteList = async (id: string): Promise<JSONType> => {
		const res = await request<JSONType>({
			url: `userLists/move-to-trash`,
			method: 'DELETE',
			data: { id: [id] },
		});
		return res;
	};

	static getRecentlyViewed = async (userId: string, params?: Params): Promise<{ count: number; recentlyViewed: UserList }> => {
		const res = await request<{ total: number; results: JSONType[] }>({
			url: `users/${userId}/recently-viewed`,
			params,
		});
		return parseRecentlyViewedToUserList(res, userId);
	};
}

export default UserListModel;

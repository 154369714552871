import React, { FC } from 'react';
import { Avatar, Box, Card, Link as MUILink, makeStyles } from '@material-ui/core';
import Typo from 'Components/Typo';
import dayjs from 'dayjs';
import IconText from 'Components/IconText';
import { UilLayerGroup } from '@iconscout/react-unicons';
import { Job } from 'Models/JobListing/@types';
import { generatePath, Link } from 'react-router-dom';
import { INDIVIDUAL_JOB_LISTING_PATH } from 'Routes/RouteConstants';

export interface JobCardProps {
	job: Job;
}

const JobCard: FC<JobCardProps> = ({ job }) => {
	const classes = styles();
	const dateDiff = dayjs(Date.now()).diff(new Date(job.created), 'day');
	const diffString = dateDiff === 0 ? 'Today' : `${dateDiff} days ago`;
	return (
		<MUILink component={Link} to={generatePath(INDIVIDUAL_JOB_LISTING_PATH, { listingSlug: job.slug ?? job.id })} underline="none" className={classes.root}>
			<Card className={classes.cardRoot}>
				<Avatar alt="" src={job.company?.companyLogo?.url} className={classes.logo} />
				<Box width="100%">
					<Typo className={classes.title} variant="h5" font="Font2" weight="medium">
						{job.title}
					</Typo>
					{job.company?.name ? (
						<Typo variant="h5" weight="bold">
							{job.company?.name}
						</Typo>
					) : (
						<Box />
					)}
					<Box display="flex" justifyContent="space-between" mt={2} alignItems="center">
						<IconText icon={<UilLayerGroup size={18} />} text={`${job.microCourseIds?.length ?? 0} collections`} typoProps={{ variant: 'body2' }} />
						<Typo variant="caption">{diffString}</Typo>
					</Box>
				</Box>
			</Card>
		</MUILink>
	);
};

export default JobCard;

const styles = makeStyles((theme) => ({
	root: {
		display: 'block',
		width: '100%',
	},
	cardRoot: {
		display: 'flex',
		padding: theme.spacing(2),
		gap: theme.spacing(2),
		width: '100%',
	},
	logo: {
		border: `1px solid ${theme.palette.grey[400]}`,
		width: 56,
		height: 56,
	},
	title: {
		color: theme.palette.primary.dark,
		paddingBottom: theme.spacing(2),
	},
	companyName: {},
}));

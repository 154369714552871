import React, { FC, useState } from 'react';
import { SnackbarProps, SnackbarContent, Snackbar, makeStyles, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

interface ContextProps {
	snackbarState: ISnackbarContext;
	message: string;
	// variant: ToastProps['variant'];
	showSnackbar: (message: string, options?: Partial<ISnackbarContext>) => void;
	hideSnackbar: () => void;
}

export const SnackbarContext = React.createContext<ContextProps>({
	snackbarState: {},
	message: '',
	// variant: 'success',
	showSnackbar: () => {},
	hideSnackbar: () => {},
});

export interface ISnackbarContext extends SnackbarProps {
	message?: string;
	// variant?: ToastProps['variant'];
}

export const useSnackbar = (
	initialState: ISnackbarContext
): {
	showSnackbar: (message: string, options?: Partial<ISnackbarContext>) => void;
	hideSnackbar: () => void;
	message: string;
	snackbarState: ISnackbarContext /*  variant: ToastProps['variant'] */;
} => {
	const [snackbarState, setSnackbarState] = useState({ open: false, message: '', ...initialState });

	const showSnackbar = (message: string, options?: Partial<ISnackbarContext>) => {
		setSnackbarState({
			...snackbarState,
			message,
			open: true,
			...options,
		});
	};

	const hideSnackbar = () => {
		setSnackbarState({
			...snackbarState,
			open: false,
			message: '',
		});
	};
	return { showSnackbar, hideSnackbar, message: snackbarState.message || '', snackbarState /* variant: toastState.variant || 'success' */ };
};

const SnackbarProvider: FC = (props) => {
	const { children } = props;
	const { showSnackbar, hideSnackbar, message, snackbarState /* variant */ } = useSnackbar({ open: false });
	const classes = styles();
	return (
		<SnackbarContext.Provider value={{ hideSnackbar, message, snackbarState, showSnackbar }}>
			{children}
			<SnackbarContext.Consumer>
				{({ hideSnackbar, snackbarState, message }) => (
					<Snackbar
						open={snackbarState.open}
						onClose={hideSnackbar}
						classes={{ root: classes.rootSnackbar }}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
						autoHideDuration={2000}
					>
						<SnackbarContent
							classes={{ root: classes.snackbarContent }}
							message={message}
							/* variant={variant} */ action={
								<>
									{snackbarState.action}
									<IconButton onClick={hideSnackbar} size="small" className={classes.closeButton}>
										<Close />
									</IconButton>
								</>
							}
						/>
					</Snackbar>
				)}
			</SnackbarContext.Consumer>
		</SnackbarContext.Provider>
	);
};

export default SnackbarProvider;

const styles = makeStyles((theme) => ({
	rootSnackbar: {
		marginLeft: 170,
		[theme.breakpoints.down('md')]: {
			marginLeft: 80,
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
		},
	},
	snackbarContent: {
		backgroundColor: theme.palette.primary.main,
	},
	closeButton: {
		color: theme.palette.primary.light,
	},
}));

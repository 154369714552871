import { parseToCompany } from 'Models/Company/CompanyParsers';
import { parseToMicroCourse } from 'Models/MicroCourse/MicroCourseParsers';
import { JSONType } from 'Typings/@types';
import { Job } from './@types';

// eslint-disable-next-line import/prefer-default-export
export const parseToJobListing = (obj: JSONType): Job => {
	const microCourses = obj.microCourses?.map(parseToMicroCourse);
	const company = obj.company ? parseToCompany(obj.company) : undefined;
	return { ...obj, microCourses, company } as Job;
};

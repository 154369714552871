import { LinearProgress } from '@material-ui/core';
import useBoot from 'Boot';
import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'Stores';
// eslint-disable-next-line import/no-cycle
import AppNavigation from 'Routes/AppNavigation';
import { EXPLORE_HOME_PATH, LOGIN_PATH, REGISTER_PATH } from 'Routes/RouteConstants';
import queryString from 'query-string';
import { Analytics } from 'analytics';
import { attachField, setDefaultProps } from 'react-forms';
import ImagePicker from 'Components/ImagePicker';
import { completeProfile } from 'Features/Auth/onboarding';
import Config from 'config';
import MultiTabImagePicker from 'Components/MultiTabImagePicker';
import utils from 'Utils';
import { Token } from 'Models/Auth/@types';
import LinkInput from 'Components/LinkInput';
import ChipSelect from 'Components/ChipSelect';
import CategorisedChipSelect from 'Components/CategorisedChipSelect';
import RootContextProviders from '../Contexts';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'rf-autocomplete';

setDefaultProps('text', {
	variant: 'outlined',
	InputLabelProps: { shrink: true },
	fullWidth: true,
});
setDefaultProps('password', {
	variant: 'outlined',
	InputLabelProps: { shrink: true },
	fullWidth: true,
});

attachField('image-picker', <ImagePicker />);
attachField('mc-image-picker', <MultiTabImagePicker />);
attachField('link', <LinkInput />);
attachField('chip-select', <ChipSelect />);
attachField('category-chip-select', <CategorisedChipSelect />);

// const experimentKeys = [AB_EXPERIMENT_KEY_PREFIX + LANDING_EXPERIMENT, AB_EXPERIMENT_KEY_PREFIX + EXPLORE_EXPERIMENT, AB_EXPERIMENT_KEY_PREFIX + MICRO_COURSE_CHAPTER_EXPERIMENT];
const Layout: FC = () => {
	const { isCompleted } = useBoot();
	const { setAuthInfo } = useStoreActions(({ Auth: { setAuthInfo } }) => ({ setAuthInfo }));
	const { user } = useStoreState(({ Auth: { user } }) => ({ user }));

	const history = useHistory();
	const location = useLocation();
	const { redirectTo, jumpToStep } = queryString.parse(location.search) as { redirectTo?: string; jumpToStep?: number };

	useEffect(() => {
		const authInfo = utils.getCookieUser() as Token;
		setAuthInfo(authInfo).then((user) => {
			if (user) completeProfile(user);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const { getUserLists } = useStoreActions(({ Library: { getUserLists } }) => ({ getUserLists }));

	useEffect(() => {
		const urlPath = history.location.pathname;
		const { analytics } = window;
		if (!analytics) {
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.innerHTML = analyticsScript;
			script.async = true;
			head.appendChild(script);
		}
		if (isCompleted) {
			if (!user && urlPath.includes('/home')) {
				history.push(EXPLORE_HOME_PATH);
			}
			if (user) {
				getUserLists({ userId: user?.id ?? '', params: { filter: { include: { relation: 'userListItems', scope: { include: 'subject' } } } } });
			}
			Analytics.page('chet-opened', { userId: user?.id || null, integrations: { Amplitude: { session_id: Date.now() } } });
			if (user && user.id && (urlPath === LOGIN_PATH || (urlPath === REGISTER_PATH && jumpToStep === undefined))) {
				if (redirectTo && redirectTo !== Config.get('POST_LOGIN_REDIRECT')) {
					history.push(redirectTo as string);
					return;
				}
			}
			if (user && !user.onboardingComplete) {
				completeProfile(user);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, isCompleted]);

	return isCompleted ? <AppNavigation /> : <LinearProgress />;
};

const RootLayout: FC = () => {
	return (
		<RootContextProviders>
			<Layout />
		</RootContextProviders>
	);
};

export default RootLayout;
const analyticsScript = `(function () {
	// console.log('analytics is running');
	var analytics = (window.analytics = window.analytics || []);
	if (!analytics.initialize)
		if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
		else {
			analytics.invoked = !0;
			analytics.methods = ['track', 'page', 'identify'];
			analytics.factory = function (e) {
				return function () {
					var t = Array.prototype.slice.call(arguments);
					t.unshift(e);
					analytics.push(t);
					return analytics;
				};
			};
			for (var e = 0; e < analytics.methods.length; e++) {
				var key = analytics.methods[e];
				analytics[key] = analytics.factory(key);
			}
			analytics.load = function (key, e) {
				var t = document.createElement('script');
				t.type = 'text/javascript';
				t.async = !0;
				t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
				var n = document.getElementsByTagName('script')[0];
				n.parentNode.insertBefore(t, n);
				analytics._loadOptions = e;
			};
			${process.env.REACT_APP_NODE_ENV?.trim() === 'production' ? '' : `analytics._writeKey="${Config.get('SEGMENT_LOAD_ID')}"`}
			analytics.SNIPPET_VERSION = ${process.env.REACT_APP_NODE_ENV?.trim() === 'production' ? "'4.13.1'" : "'4.13.2'"};
			analytics.load('${Config.get('SEGMENT_LOAD_ID')}');
		}
})();`;

import queryString from 'query-string';
import { User } from 'Models/User/@types';
// eslint-disable-next-line import/no-cycle
import { history } from 'App';
import { LANDING_PATH, LOGIN_PATH, REGISTER_PATH } from 'Routes/RouteConstants';
import { TRegistrationStep } from 'Screens/Register/RegistrationContext';
// import Config from 'Config';

// eslint-disable-next-line import/prefer-default-export
export function completeProfile(user: User): void {
	const { redirectTo } = queryString.parse(history.location.search) as { redirectTo: string };
	const {
		location: { pathname },
	} = history;
	const nextStepNumber = getNextStepNumber(user);
	if (!nextStepNumber) return;
	history.push(`${REGISTER_PATH}?email=${user.email}&jumpToStep=${nextStepNumber}${redirectTo || !DONT_REDIRECT_TO_PATHS.includes(pathname) ? `&redirectTo=${redirectTo ?? pathname}` : ''}`);
}
const DONT_REDIRECT_TO_PATHS = [REGISTER_PATH, LOGIN_PATH, LANDING_PATH];

// Currently onboardingComplete flag is completely ignored.

const getNextStepNumber = (user: User): TRegistrationStep | undefined => {
	if (!user.onboardingSteps) return 2;
	if (user.onboardingSteps.step2) {
		if (user.onboardingSteps.step3) {
			if (user.onboardingSteps.step4) {
				if (user.onboardingSteps?.step5) {
					if (user.onboardingSteps?.step6) {
						return undefined;
					}
					return 6;
				}
				return 5;
			}
			return 4;
		}
		return 3;
	}
	return 2;
};

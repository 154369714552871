import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { Chapter } from 'Models/Chapter/@types';
import Cookies from 'universal-cookie';
// eslint-disable-next-line import/no-cycle
import { COOKIES_ACCESS_TOKEN, COOKIES_USERID } from 'Stores/Auth';
import { Token } from 'Models/Auth/@types';
import { ExploreHome } from 'Models/ExploreHome/@types';
import { ExploreList } from 'Models/ExploreList/@types';

const cookies = new Cookies();
export const request = async <T>(config: AxiosRequestConfig): Promise<T> => {
	if (!axios.defaults.baseURL) {
		throw new Error('Error: Base Url is not provided');
	}
	const resp = await axios.request<T>(config);

	return resp.data;
};

const utils = {
	setBaseAPI_URL: (url: string): void => {
		axios.defaults.baseURL = url;
	},

	setHeader: (type = 'Content-Type', value = 'application/json'): void => {
		axios.defaults.headers.post[type] = value;
	},

	setAuthHeader: (accessToken?: string): void => {
		axios.defaults.headers.common.Authorization = accessToken;
	},

	throwError: (error: AxiosError): void => {
		// eslint-disable-next-line no-console
		console.log('Error', error.response);
		throw error;
	},

	getAccessToken: (): string | null => {
		return localStorage.getItem(COOKIES_ACCESS_TOKEN);
		// return cookies.get(COOKIES_ACCESS_TOKEN);
	},
	setUserCookies: (token: Partial<Token>): void => {
		const expires = new Date(Date.now() + (token.ttl ?? 604800) * 1000); // use given ttl or one week in ms. Date() takes value in milliseconds.
		cookies.set(COOKIES_USERID, token.userId, { expires, path: '/' });
		cookies.set(COOKIES_ACCESS_TOKEN, token.id, { expires, path: '/' });
		cookies.remove(COOKIES_USERID, { path: '/microCourse' });
		cookies.remove(COOKIES_ACCESS_TOKEN, { path: '/microCourse' });
		cookies.remove(COOKIES_USERID, { path: '/home' });
		cookies.remove(COOKIES_ACCESS_TOKEN, { path: '/home' });
	},
	getCookieUser: (): { userId: string; id: string } => {
		const userId = cookies.get(COOKIES_USERID);
		const accessToken = cookies.get(COOKIES_ACCESS_TOKEN);
		return { userId, id: accessToken };
	},
	removeUserCookies: (): void => {
		utils.setAuthHeader(undefined);
		cookies.remove(COOKIES_USERID);
		cookies.remove(COOKIES_ACCESS_TOKEN);
	},
	containsEncodedComponents: (link: string): boolean => {
		return decodeURI(link) !== decodeURIComponent(link);
	},

	validateUrl: (uri: string): boolean => {
		let finalUri = uri;
		if (utils.containsEncodedComponents(uri)) {
			finalUri = decodeURIComponent(uri);
		}
		// eslint-disable-next-line
		let tester = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
		if (!finalUri) return false;
		return tester.test(finalUri);
	},
};

export default utils;

export const getButtonLabel = (type: Chapter['type']): string => {
	switch (type) {
		case 'video':
			return 'Play';
		case 'podcast':
			return 'Listen to podcast';
		case 'blog':
			return 'Read article';
		case 'event':
			return ' View Event';
		case 'book':
			return 'Read book';
		case 'course':
			return 'View Course';
		default:
			return 'View Link';
	}
};
export const parseExploreListToExploreHome = (exploreList: ExploreList): ExploreHome => ({
	id: Math.random().toString(),
	order: 0,
	subjectId: Math.random().toString(),
	subjectType: 'ExploreList',
	subject: exploreList,
});

import React, { useState } from 'react';
import { EnrollViaEmailBannerProps } from 'Screens/MicroCourse/EnrollViaEmailBanner';

export type TFooterVariant = 'apply-curator' | 'email-reminder';

export interface SecondaryFooterContextState {
	footerVariant: TFooterVariant;
	setFooterVariant: (next: TFooterVariant) => void;
	setAdditionalProps: (next?: EnrollViaEmailBannerProps) => void;
	additionalProps?: EnrollViaEmailBannerProps;
}
export const SecondaryFooterContext = React.createContext<SecondaryFooterContextState>({
	footerVariant: 'apply-curator',
	additionalProps: undefined,
	setFooterVariant: () => {},
	setAdditionalProps: () => {},
});
export const useSecondaryFooter = (): SecondaryFooterContextState => {
	const [footerVariant, setFooterVariant] = useState<TFooterVariant>('apply-curator');
	const [additionalProps, setAdditionalProps] = useState<EnrollViaEmailBannerProps | undefined>();

	return { footerVariant, setFooterVariant, additionalProps, setAdditionalProps };
};
export const SecondaryFooterCTXProvider: React.FC = ({ children }) => {
	const { footerVariant, setFooterVariant, additionalProps, setAdditionalProps } = useSecondaryFooter();
	return <SecondaryFooterContext.Provider value={{ footerVariant, setFooterVariant, additionalProps, setAdditionalProps }}>{children}</SecondaryFooterContext.Provider>;
};

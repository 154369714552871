// eslint-disable-next-line import/no-cycle
import { parseToMicroCourse } from 'Models/MicroCourse/MicroCourseParsers';
import { PathwayStep } from 'Models/PathwayStep/@types';
import { JSONType } from 'Typings/@types';
import { Pathway } from './@types';

export const parseToPathway = (obj: JSONType): Pathway => {
	const { _ogImage: ogImage, _coverImage: coverImage } = obj ?? {};
	return { ...obj, ogImage, coverImage, pathwaySteps: obj.pathwaySteps?.map(parseToPathwayStep) } as Pathway;
};

export const parseToPathwayStep = (obj: JSONType): PathwayStep => {
	return { ...obj, microCourses: obj.microCourses?.map(parseToMicroCourse) } as PathwayStep;
};

import { action, Action, thunk, Thunk } from 'easy-peasy';
import { ChapterModel } from 'Models/Chapter';
import { Chapter } from 'Models/Chapter/@types';
// eslint-disable-next-line import/no-cycle
import { RootStore } from 'Stores';
import { IncludeArray, Params } from 'Typings/@types';
import MicroCourseModel from 'Models/MicroCourse';
import { MicroCourse } from 'Models/MicroCourse/@types';
import { parseToMicroCourse } from 'Models/MicroCourse/MicroCourseParsers';
// import HelperUtils from 'Utils/helpers';
// eslint-disable-next-line import/no-cycle
import utils from 'Utils';
import { chapterCompleted } from 'Features/MicroCourse/ChapterCompletedEvent';
// eslint-disable-next-line import/no-cycle
import { MicroCoursePageState } from 'Screens/MicroCourse';

export interface MicroCourseState {
	microCourses: MicroCourse[];
	microCourse?: MicroCourse;
	chapters?: Chapter[];
	chapter?: Chapter;
	pageState?: MicroCoursePageState;
	relatedMicroCourses?: MicroCourse[];
	getChapter: Thunk<MicroCourseState, { microCourseSlug: string; chapterSlug: string; params: Params }, unknown, RootStore, Promise<Chapter>>;
	getAllMicroCourses: Thunk<MicroCourseState, Params | undefined, unknown, RootStore, Promise<MicroCourse[]>>;
	getMicroCourse: Thunk<MicroCourseState, { microCourseSlug: string; params?: Params }, unknown, RootStore, Promise<MicroCourse>>;
	getRelatedMicroCourses: Thunk<MicroCourseState, { id: string; params?: Params | undefined }, unknown, RootStore, Promise<MicroCourse[]>>;
	getAllChapters: Thunk<MicroCourseState, { id: string; params: Params }, unknown, RootStore, Promise<Chapter[]>>;
	setChapters: Action<MicroCourseState, Chapter[]>;
	markChapterCompleted: Thunk<MicroCourseState, string, unknown, RootStore, Promise<void>>;
	markCompletedBulk: Thunk<MicroCourseState, { chapterSlugs: string[]; userId: string }>;
	postMicroCourseViews: Thunk<MicroCourseState, MicroCourse, unknown, RootStore, Promise<void>>;
	setMicroCourse: Action<MicroCourseState, MicroCourse | undefined>;
	setMicroCourses: Action<MicroCourseState, MicroCourse[]>;
	setRelatedMicroCourses: Action<MicroCourseState, MicroCourse[] | undefined>;
	setAllMicroCourses: Action<MicroCourseState, MicroCourse[]>;
	setChapter: Action<MicroCourseState, Chapter | undefined>;
	updateCompletedCount: Action<MicroCourseState, string>;
	setPageState: Action<MicroCourseState, Partial<MicroCoursePageState> | undefined>;
}

const MicroCourseStore: MicroCourseState = {
	microCourses: [],
	microCourse: undefined,
	chapters: [],
	chapter: undefined,
	pageState: undefined,
	relatedMicroCourses: [],
	getAllMicroCourses: thunk(async (actions, params) => {
		// Keep include as object array
		const res = await MicroCourseModel.getAllMicroCourses({
			...params,
			filter: { ...params?.filter, include: [...((params?.filter?.include as IncludeArray) || []), { relation: 'curators' }], order: 'order ASC' },
		});
		const microCourses = res.map(parseToMicroCourse);
		actions.setMicroCourses(microCourses);
		return microCourses;
	}),
	getMicroCourse: thunk(async (actions, { microCourseSlug, params }) => {
		// Keep include as object array
		const microCourse = await MicroCourseModel.getMicroCourse(microCourseSlug, {
			...params,
			filter: {
				...params?.filter,
				include: [...((params?.filter?.include as IncludeArray) || []), { relation: 'curators' }],
			},
		});
		actions.setMicroCourse(microCourse);
		return microCourse;
	}),
	getRelatedMicroCourses: thunk(async (actions, { id, params }) => {
		const relatedMicroCourses = await MicroCourseModel.getRelatedMicroCourses(id, params);
		actions.setRelatedMicroCourses(relatedMicroCourses);
		return relatedMicroCourses;
	}),
	postMicroCourseViews: thunk(async (_actions, microCourse, { getStoreState, getStoreActions }) => {
		// We need to track microCourse views, If a logged in user has opened a microCourse(engaged)
		const { user, userOngoingMicroCourses } = getStoreState().Auth;
		const success = await MicroCourseModel.postMicroCourseView(microCourse.id, !user);
		if (success) {
			microCourse.rawViewCount = (microCourse.rawViewCount || 0) + 1;
			if (user) {
				// rearrange according to lastViewed
				const { setUserOngoingMicroCourses } = getStoreActions().Auth;
				const microCoursePosInUserOngoingMicroCourses = (userOngoingMicroCourses ?? []).findIndex((ongoingMicroCourse) => ongoingMicroCourse.id === microCourse.id);
				if (microCoursePosInUserOngoingMicroCourses > -1) {
					const newOngoingMicroCourses = userOngoingMicroCourses ?? [];
					newOngoingMicroCourses.splice(0, 0, ...newOngoingMicroCourses.splice(microCoursePosInUserOngoingMicroCourses, 1));
					setUserOngoingMicroCourses(newOngoingMicroCourses);
				}
			}
		}
	}),
	getAllChapters: thunk(async (actions, { id, params }) => {
		const chapters = await ChapterModel.getAllChapters(id, params);
		actions.setChapters(chapters);
		return chapters;
	}),
	setChapters: action((state, payload) => {
		state.chapters = payload;
	}),
	markChapterCompleted: thunk(async (actions, id, { getStoreState, getStoreActions, getState }) => {
		const { isCordova } = getStoreState().App;
		if (isCordova) {
			const accessToken = utils.getAccessToken();
			if (!accessToken) return;
			chapterCompleted(id, accessToken);
			return;
		}

		const res = await ChapterModel.markCompleted(id);
		const { microCourse } = getState();
		if (res && microCourse) {
			actions.updateCompletedCount(id);
			getStoreActions().Auth.addMicroCourseToOngoing(microCourse);
		}
	}),
	updateCompletedCount: action((state, chapterId) => {
		const { microCourse } = state;
		if (microCourse) {
			microCourse.chapters = microCourse.chapters?.map((chapter) =>
				chapter.id === chapterId ? { ...chapter, isMarkedComplete: true, markCompletedCount: (chapter.markCompletedCount || 0) + 1 } : chapter
			);
		}
	}),
	markCompletedBulk: thunk(async (_actions, data) => {
		await ChapterModel.markCompletedBulk(data);
	}),
	getChapter: thunk(async (actions, { microCourseSlug, chapterSlug, params }) => {
		const chapter = await ChapterModel.getChapter(microCourseSlug, chapterSlug, params);
		actions.setChapter(chapter);
		return chapter;
	}),
	setMicroCourse: action((state, payload) => {
		state.microCourse = payload;
	}),
	setMicroCourses: action((state, payload) => {
		state.microCourses = payload;
	}),
	setRelatedMicroCourses: action((state, payload) => {
		state.relatedMicroCourses = payload;
	}),
	setAllMicroCourses: action((state, payload) => {
		state.microCourses = payload;
	}),
	setChapter: action((state, payload) => {
		state.chapter = payload;
	}),
	setPageState: action((state, payload) => {
		if (payload === undefined) state.pageState = payload;
		state.pageState = { ...state.pageState, ...payload };
	}),
};

export default MicroCourseStore;

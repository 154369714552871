import { action, Action, thunk, Thunk } from 'easy-peasy';
import ExploreHomeModel from 'Models/ExploreHome';
import { ExploreHome } from 'Models/ExploreHome/@types';
import ExploreListModel from 'Models/ExploreList';
import { ExploreList } from 'Models/ExploreList/@types';
import PathwayModel from 'Models/Pathway';
import { Pathway } from 'Models/Pathway/@types';
// eslint-disable-next-line import/no-cycle
import { RootStore } from 'Stores';
import { Params } from 'Typings/@types';
import orderBy from 'lodash/orderBy';

export interface ExploreListState {
	featuredList?: ExploreList;
	allExploreLists?: ExploreList[];
	getFeaturedList: Thunk<ExploreListState, Params | undefined, null, RootStore, Promise<ExploreList | null>>;
	getAllExploreLists: Thunk<ExploreListState, Params | undefined, null, RootStore, Promise<ExploreList[]>>;
	setAllExploreLists: Action<ExploreListState, ExploreList[] | undefined>;
	setFeaturedList: Action<ExploreListState, ExploreList | undefined>;

	exploreItems?: ExploreHome[];
	// viewedAny: boolean;
	getAllExploreItems: Thunk<ExploreListState, { params?: Params }, null, RootStore, Promise<ExploreHome[]>>;
	setAllExploreItems: Action<ExploreListState, ExploreHome[] | undefined>;

	pathway?: Pathway;
	setPathway: Action<ExploreListState, Pathway | undefined>;
	fetchPathway: Thunk<ExploreListState, string, null, RootStore, Promise<void>>;
}

const ExploreListStore: ExploreListState = {
	featuredList: undefined,
	exploreItems: [],
	allExploreLists: [],
	setPathway: action((state, payload) => {
		state.pathway = payload;
	}),
	fetchPathway: thunk(async (actions, id) => {
		const res = await PathwayModel.getPathwayOverview(id);
		// const res = await PathwayModel.getPathway(id, { filter: { ...PATHWAY_DETAIL_INCLUDE_FILTER } })
		actions.setPathway(res);
	}),
	getAllExploreItems: thunk(async (_actions, { params }, { getState }) => {
		const res = await ExploreHomeModel.getAllExploreHome(params);
		getState().exploreItems = res;
		return res;
	}),
	setAllExploreItems: action((state, payload) => {
		state.exploreItems = payload;
	}),
	getFeaturedList: thunk(async (actions, params) => {
		const res = await ExploreListModel.getFeaturedLists(params); // Currently only 1 list is required to be featured.
		actions.setFeaturedList(res.length ? { ...res[0], listDocuments: orderBy(res[0].listDocuments || [], 'order') } : undefined);
		return res[0];
	}),
	getAllExploreLists: thunk(async (actions, params) => {
		const res = await ExploreListModel.getAllExploreList(params);
		actions.setAllExploreLists(res);
		return res;
	}),
	setFeaturedList: action((state, exploreList) => {
		state.featuredList = exploreList;
	}),
	setAllExploreLists: action((state, allExploreLists) => {
		state.allExploreLists = allExploreLists;
	}),
};

export default ExploreListStore;

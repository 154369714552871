import { MicroCourse } from 'Models/MicroCourse/@types';
import { Pathway } from 'Models/Pathway/@types';
import { Post } from 'Models/Post/@types';
import { JSONType } from 'Typings/@types';
import { parseToMicroCourse } from 'Models/MicroCourse/MicroCourseParsers';
import { parseToPathway } from 'Models/Pathway/PathwayParsers';
import { parseToPost } from 'Models/Post/PostParsers';
import { parseToTag } from 'Models/Tag/TagParsers';
import { parseToChapter } from 'Models/Chapter/ChapterParsers';
import { FeedEntity, SuggestedCurator, User, UserFeedResponse, UserFeedResult, ExploreFeed, ExploreFeedHit, SuggestedMicroCourse } from './@types';

export const parseToUser = (obj: JSONType): User => {
	const { userOngoingMicroCourses } = obj ?? {};
	if (userOngoingMicroCourses) obj.userOngoingMicroCourses = userOngoingMicroCourses.map(parseToMicroCourse);
	const { _educations: educations, _profileImage: profileImage, _experiences: experiences, _milestones: milestones, _companyLogo: companyLogo } = obj ?? {};

	return {
		...obj,
		educations,
		profileImage,
		experiences,
		milestones,
		companyLogo,
	} as User;
};

export const parseToCompletedMicroCourse = (obj: JSONType): MicroCourse => {
	const { subject } = obj;
	return parseToMicroCourse(subject) as MicroCourse;
};

export const parseUser = (user: Partial<User>): JSONType => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { email, id: _, password, profileImage, userOngoingMicroCourses: __, ...rest } = user;
	return {
		...rest,
		...(password ? { password } : {}),
		...(email ? { email } : {}),
		...(profileImage ? { _profileImage: profileImage } : {}),
	};
};

export const parseToUserFeedResponse = (obj: JSONType): UserFeedResponse => {
	const {
		counts: { total },
		cursor,
		buckets,
		resultCounts: { total: resultCountTotal },
	} = obj;
	const parserMapping = { post: parseToPost, pathway: parseToPathway, microCourse: parseToMicroCourse };
	const results: UserFeedResult[] = [];
	const pattern: FeedEntity[] = ['post', 'post', 'microCourse', 'post', 'post', 'pathway'];
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < resultCountTotal; i++) {
		const currentPatternPos = i % pattern.length;
		const currentBucket = `${pattern[currentPatternPos]}Bucket`;
		if (buckets[currentBucket].length === 0) {
			// eslint-disable-next-line no-continue
			continue;
		}
		const result = parserMapping[pattern[currentPatternPos]]((buckets[currentBucket] as Array<MicroCourse | Post | Pathway>).shift() as MicroCourse | Post | Pathway);
		// Parse the result as per requirement
		results.push({ hit: { ...result, doc_type: pattern[currentPatternPos] } });
	}
	return { total, cursor, results } as UserFeedResponse;
};

export const parseToSuggestedCurator = (obj: JSONType): SuggestedCurator => {
	// eslint-disable-next-line no-underscore-dangle
	return { ...obj, id: obj._id, tagInfo: parseToTag(obj.tagInfo), users: obj.users.map(parseToUser) } as SuggestedCurator;
};
export const parseToSuggestedMicroCourse = (obj: JSONType): SuggestedMicroCourse => {
	// eslint-disable-next-line no-underscore-dangle
	return { ...obj, id: obj._id, tagInfo: parseToTag(obj.tagInfo), microCourses: obj.microCourses.map(parseToMicroCourse) } as SuggestedMicroCourse;
};

export const parseFeed = (obj: JSONType): ExploreFeed => {
	return {
		...obj,
		results: obj.results.map((item: JSONType): { hit: ExploreFeedHit } => {
			switch (item.hit.doc_type) {
				case 'Post':
					if (item.postType === 'post') return { hit: parseToPost(item.hit) as ExploreFeedHit };
					return { hit: parseToChapter(item.hit) as ExploreFeedHit };
				case 'MicroCourse':
					return { hit: parseToMicroCourse(item.hit) as ExploreFeedHit };
				default:
					// eslint-disable-next-line no-console
					console.error(`No handler found for type: ${item.doc_type}`, { hit: item.hit });
					return { hit: item as ExploreFeedHit };
			}
		}),
	} as ExploreFeed;
};

import React, { FC } from 'react';
import { EXPLORE_HOME_PATH, LOGIN_PATH } from 'Routes/RouteConstants';
import { Variant, Experiment } from '@marvelapp/react-ab-test';
import { Analytics } from 'analytics';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { CONTROL_VARIANT, EXPLORE_EXPERIMENT, LOGIN_VARIANT } from './Constants';

interface ABExperimentProps {
	controlPath: string;
}

const ABExperiment: FC<ABExperimentProps> = ({ controlPath, children }) => {
	const experimentRef = React.createRef();
	const classes = styles();

	return (
		<Experiment ref={experimentRef} name={EXPLORE_EXPERIMENT}>
			<Variant name={CONTROL_VARIANT}>
				<Link
					className={classes.link}
					to={controlPath}
					onClick={() => {
						Analytics.track('EXPLORE_CONTROL_CLICKED', { time: Date.now().toString(), page: EXPLORE_HOME_PATH });
					}}
				>
					{children}
				</Link>
			</Variant>
			<Variant name={LOGIN_VARIANT}>
				<Link
					className={classes.link}
					to={`${LOGIN_PATH}?redirectTo=${controlPath}`}
					onClick={() => {
						Analytics.track('EXPLORE_LOGIN_EXPERIMENT_CLICKED', { time: Date.now().toString(), page: EXPLORE_HOME_PATH });
					}}
				>
					{children}
				</Link>
			</Variant>
		</Experiment>
	);
};

export default ABExperiment;

const styles = makeStyles(() => ({
	link: {
		textDecoration: 'none',
		display: 'flex',
		flex: 1,
	},
}));

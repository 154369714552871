import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ChevronDown, IconProps } from 'react-feather';

interface ArrowRotatableProps extends IconProps {
	shouldBeRotated?: boolean;
}

const ArrowRotatable: FC<ArrowRotatableProps> = (props) => {
	const { shouldBeRotated, ...rest } = props;
	const classes = getClasses();
	return <ChevronDown {...rest} className={clsx(rest.className, classes.root, { [classes.upsideDown]: shouldBeRotated })} />;
};

export default ArrowRotatable;

const getClasses = makeStyles(() => ({
	root: {
		transition: 'transform ease 300ms',
	},
	upsideDown: {
		transform: 'rotate(180deg)',
	},
}));

import React from 'react';
import { Chapter } from 'Models/Chapter/@types';
import { Calendar, Book as BookOutlinedIcon, BookOpen as BookOpenIcon } from 'react-feather';
import { UilVideo, UilHeadphones, UilFileAlt } from '@iconscout/react-unicons';
import { JSONType } from 'Typings/@types';

export const getIcon = (type: Chapter['type'], iconProps?: JSONType): JSX.Element => {
	switch (type) {
		case 'video':
			return <UilVideo size="1em" {...iconProps} />;
		case 'podcast':
			return <UilHeadphones size="1em" {...iconProps} />;
		case 'blog':
			return <UilFileAlt size="1em" {...iconProps} />;
		case 'event':
			return <Calendar height="1em" {...iconProps} />;
		case 'book':
			return <BookOutlinedIcon height="1em" {...iconProps} />;
		case 'course':
			return <BookOpenIcon height="1em" {...iconProps} />;
		default:
			return <UilFileAlt size="1em" {...iconProps} />;
	}
};

export const getActionTextForType = (type: Chapter['type'], pastTense?: boolean): string => {
	if (!pastTense)
		switch (type) {
			case 'event':
			case 'video':
				return 'watch';
			case 'podcast':
				return 'listen';
			default:
			case 'blog':
			case 'book':
			case 'other':
				return 'read';
		}
	switch (type) {
		case 'event':
			return 'attended';
		case 'video':
			return 'watched';
		case 'podcast':
			return 'listened to';
		default:
		case 'blog':
		case 'book':
		case 'other':
			return 'read';
	}
};

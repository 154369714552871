import React, { FC } from 'react';
import { Box, createStyles, Link, makeStyles } from '@material-ui/core';
import AppStoreDownload from 'Assets/img/download-appstore.png';
import PlayStoreDownload from 'Assets/img/download-googleplay.png';

const DownloadsSection: FC = () => {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<Link className={classes.link} href="https://apps.apple.com/app/chet-professional-development/id1373554072">
				<img src={AppStoreDownload} alt="Download from the AppStore" />
			</Link>
			<Link className={classes.link} href="https://play.google.com/store/apps/details?id=com.chetnetwork.mobile">
				<img src={PlayStoreDownload} alt="Download from the PlayStore" />
			</Link>
		</Box>
	);
};

export default DownloadsSection;

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: 'flex',
			width: '100%',
			justifyContent: 'center',
			boxSizing: 'border-box',
			flex: 1,
			'& > a:first-child': {
				paddingRight: 8,
			},
			'& > a:last-child': {
				paddingLeft: 8,
			},
		},
		link: {
			'&>img': { maxWidth: 135 },
			boxSizing: 'border-box',
			[theme.breakpoints.down('sm')]: {
				'&>img': { maxWidth: 147 },
				'&:not(:last-child)': {
					marginRight: 12,
				},
			},
		},
	})
);

import { request } from 'Resources/AxiosUtils';
import { JSONType, Params } from 'Typings/@types';
import { ExploreList } from './@types';
import { parseToExploreList } from './ExploreListParsers';

class ExploreListModel {
	static getAllExploreList = async (params?: JSONType): Promise<ExploreList[]> => {
		const res = await request<JSONType[]>({
			url: '/exploreLists',
			method: 'GET',
			params,
		});
		return res.map(parseToExploreList);
	};

	static getExploreList = async (id: string, params?: JSONType): Promise<ExploreList> => {
		const res = await request<JSONType>({
			url: `/exploreLists/${id}`,
			method: 'GET',
			params,
		});
		return parseToExploreList(res);
	};

	static getFeaturedLists = async (params?: Params): Promise<ExploreList[]> => {
		const res = await request<JSONType>({
			url: '/exploreLists/featured-list',
			method: 'GET',
			params,
		});
		return res.map(parseToExploreList);
	};
}

export default ExploreListModel;

import { TFile } from 'react-forms';
import { request } from 'Resources/AxiosUtils';
import { Picture } from './@types';

export type ImagekitCropTypes = 'auto' | 'face';
class PictureModel {
	static getDummyImage = (width = 100, height = 100): string => {
		return `https://picsum.photos/${width}/${height}`;
	};

	static getTransformedUrl = (imgUrl: string, width?: number, height?: number, cropType?: ImagekitCropTypes): string => {
		// Below is coming as undefined in some case
		if (!imgUrl) return '';
		if (!imgUrl.includes('imagekit')) {
			return imgUrl;
		}
		const transforms = ['f-auto'];
		let finalImgUrl = imgUrl;
		if (finalImgUrl?.indexOf('?tr=') > -1) {
			// in case uploaded image already has transforms applied
			finalImgUrl = imgUrl.substring(0, imgUrl?.indexOf('?tr='));
		}
		if (width) transforms.push(`w-${width}`);
		if (height) transforms.push(`h-${height}`);
		if (cropType) transforms.push(cropType === 'auto' ? 'fo-auto' : 'fo-face');
		if (!transforms.length) return finalImgUrl;
		return `${finalImgUrl}?tr=${transforms.join(',')}`;
	};

	static upload = async (pictureObject: TFile, fileName?: string, folderName?: string, setProgress?: (progress: number) => unknown): Promise<Picture> => {
		const { base64, name } = pictureObject;
		return request<Picture>({
			url: 'pictures/upload',
			data: { base64img: base64, filename: fileName || name, folder: folderName || 'default' },
			method: 'POST',
			onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
				const progress = (progressEvent.loaded / progressEvent.total) * 100;
				setProgress && setProgress(Math.floor(progress));
			},
		}).catch((error) => {
			throw error;
		});
	};
}

export default PictureModel;

import React, { FC } from 'react';
import { makeStyles, Box, Grow, MenuItem, MenuList, LinkProps as MUILinkProps, Link as MUILink, MenuItemProps, PopoverProps, MenuListProps } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import PopupStateController, { bindHover, bindPopover } from 'material-ui-popup-state';
import clsx from 'clsx';
import { THEME_PALETTE } from 'Theme/themeConstants';
import { Analytics } from 'analytics';
import { useStoreState } from 'Stores';

export interface HeaderMenuProps {
	hoverOver: JSX.Element;
	menuItems: HeaderMenuItem[];
	menuItemsProps?: Omit<MenuItemProps, 'button'>;
	popoverProps?: Omit<PopoverProps, 'open'>;
	menuListProps?: MenuListProps;
}

export interface HeaderMenuItem {
	label: string;
	analyticsEvent: Analytics.EVENT;
	icon?: JSX.Element;
	onClick?: (event: React.MouseEvent<HTMLLIElement>) => void;
	link?: string;
	linkProps?: Partial<LinkProps & MUILinkProps>;
	externalLink?: boolean;
}

const HeaderMenu: FC<HeaderMenuProps> = (props) => {
	const {
		hoverOver,
		menuItems,
		menuItemsProps,
		popoverProps = { anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, transformOrigin: { vertical: 'top', horizontal: 'right' } },
		menuListProps,
	} = props;
	const classes = styles();
	const { user } = useStoreState(({ Auth: { user } }) => ({ user }));
	return (
		<>
			<PopupStateController variant="popover" popupId="header-menu">
				{(popupState) => (
					<>
						<Box width="fit-content" {...bindHover(popupState)}>
							{hoverOver}
						</Box>
						{popupState.isOpen && (
							<HoverPopover TransitionComponent={Grow} disableRestoreFocus {...popoverProps} {...bindPopover(popupState)}>
								<MenuList id="menu-list-grow" {...menuListProps} className={classes.menu}>
									{menuItems.map((item) => (
										<Box key={item.label}>
											{item.externalLink ? (
												<MUILink href={item.link} underline="none" onClick={() => Analytics.track(item.analyticsEvent, { userId: user?.id ?? null, src: 'HEADER_MENU' })}>
													<MenuItem onClick={item.onClick} {...menuItemsProps} button className={classes.menuItem}>
														<Box className={classes.itemLabel}>{item.label}</Box>
														{item.icon && <Box className={classes.icon}>{item.icon}</Box>}
													</MenuItem>
												</MUILink>
											) : (
												<MUILink
													component={Link}
													to={item.link ? item.link : ''}
													{...item.linkProps}
													underline="none"
													className={clsx(item.linkProps?.className, classes.link)}
													onClick={() => Analytics.track(item.analyticsEvent, { userId: user?.id ?? null, src: 'HEADER_MENU' })}
												>
													<MenuItem onClick={item.onClick} {...menuItemsProps} button className={classes.menuItem}>
														<Box className={classes.itemLabel}>{item.label}</Box>
														{item.icon && <Box className={classes.icon}>{item.icon}</Box>}
													</MenuItem>
												</MUILink>
											)}
										</Box>
									))}
								</MenuList>
							</HoverPopover>
						)}
					</>
				)}
			</PopupStateController>
		</>
	);
};

export default HeaderMenu;

const styles = makeStyles((theme) => ({
	menu: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		border: `1px solid ${THEME_PALETTE.grey.B800}`,
	},
	link: {
		width: '100%',
		textAlign: 'right',
	},
	menuItem: {
		display: 'flex',
		padding: theme.spacing(1, 2),
		alignItems: 'center',
		justifyContent: 'right',
		color: 'black',
	},
	icon: {
		margin: '0 12px',
	},
	itemLabel: {
		fontSize: '16px',
	},
}));

import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { RootStore } from 'Stores';
import { recentlyViewedSlug, UserList } from 'Models/UserList/@types';
import UserListModel from 'Models/UserList';
import { Params } from 'Typings/@types';
import { NUM_LIST_ITEMS_TO_SHOW } from 'Screens/Explore/Library/Constants';

export interface LibraryState {
	loadingUserLists: boolean;
	loadingSelectedList: boolean;
	userLists?: UserList[];
	defaultLists?: UserList[];
	selectedList?: string;
	selectedListDetails?: UserList;
	selectedListListItemsCount?: number;

	getUserLists: Thunk<LibraryState, { userId: string; params?: Params }, unknown, RootStore, Promise<void>>;
	setUserLists: Action<LibraryState, UserList[] | undefined>;

	setDefaultLists: Action<LibraryState, UserList[] | undefined>;

	setSelectedList: Action<LibraryState, string | undefined>;
	fetchSelectedListDetails: Thunk<LibraryState, { page?: number }, unknown, RootStore, Promise<void>>;
	// fetchSelectedListListItemsCount: Thunk<LibraryState, void, unknown, RootStore, Promise<void>>;
	setSelectedListDetails: Action<LibraryState, UserList | undefined>;
	setSelectedListListItemsCount: Action<LibraryState, number | undefined>;

	onSelectedListChange: ThunkOn<LibraryState, Record<string, never>, RootStore>;

	setLoadingUserLists: Action<LibraryState, boolean>;

	setLoadingSelectedList: Action<LibraryState, boolean>;
}

const LibraryStore: LibraryState = {
	userLists: [],
	defaultLists: [],
	selectedList: undefined,
	selectedListDetails: undefined,
	loadingUserLists: false,
	loadingSelectedList: false,

	getUserLists: thunk(async (actions, { userId, params }) => {
		actions.setLoadingUserLists(true);
		try {
			const res = await UserListModel.getAllUserLists(userId, params);
			const userLists: UserList[] = [];
			const defaultLists: UserList[] = [
				{ id: 'recentlyViewed', slug: 'recentlyViewed', name: 'Recently Viewed', userId: '', userListItemIds: [], created: Date.now().toString(), updated: Date.now().toString() },
			];
			res.forEach((resItem) => {
				if (resItem.type === 'uncategorized') defaultLists.unshift(resItem);
				else userLists.push(resItem);
			});
			actions.setUserLists(userLists);
			actions.setDefaultLists(defaultLists);
		} catch (_err) {
			actions.setLoadingUserLists(false);
		}
		actions.setLoadingUserLists(false);
	}),

	setUserLists: action((state, payload) => {
		state.userLists = payload;
	}),
	setDefaultLists: action((state, payload) => {
		state.defaultLists = payload;
	}),
	setSelectedList: action((state, payload) => {
		state.selectedList = payload;
	}),

	onSelectedListChange: thunkOn(
		(actions) => actions.setSelectedList,
		(actions) => {
			actions.fetchSelectedListDetails({});
		}
	),
	fetchSelectedListDetails: thunk(async (actions, { page }, { getState, getStoreState }) => {
		actions.setLoadingSelectedList(true);
		actions.setSelectedListDetails(undefined);
		const { selectedList } = getState();
		const { user } = getStoreState().Auth;
		if (selectedList && user) {
			if (selectedList !== recentlyViewedSlug)
				try {
					const selectedListDetails = await UserListModel.getUserList(user.id, selectedList, 'slug');
					const userListItems = await UserListModel.getUserListItems(selectedListDetails.id, {
						filter: {
							include: {
								relation: 'subject',
								scope: { include: [{ relation: 'curators' }, { relation: 'microCourse', scope: { include: { relation: 'curators' } } }, { relation: 'user' }] },
							},
							limit: NUM_LIST_ITEMS_TO_SHOW,
							skip: (page ?? 0) * NUM_LIST_ITEMS_TO_SHOW,
							order: 'created DESC',
						},
					});
					selectedListDetails.userListItems = userListItems;
					actions.setSelectedListDetails(selectedListDetails);
					actions.setSelectedListListItemsCount(selectedListDetails.userListItemIds.length);
				} catch (_err) {
					actions.setLoadingSelectedList(false);
				}
			else {
				const { count, recentlyViewed } = await UserListModel.getRecentlyViewed(user.id, {
					filter: {
						include: {
							relation: 'subject',
							scope: { include: [{ relation: 'curators' }, { relation: 'MicroCourse', scope: { include: { relation: 'curators' } } }, { relation: 'User' }] },
						},
						order: 'created DESC',
						limit: NUM_LIST_ITEMS_TO_SHOW,
						skip: (page ?? 0) * NUM_LIST_ITEMS_TO_SHOW,
					},
				});
				recentlyViewed.type = 'recentlyViewed';
				actions.setSelectedListDetails(recentlyViewed);
				actions.setSelectedListListItemsCount(count);
			}
		}
		actions.setLoadingSelectedList(false);
	}),

	setSelectedListListItemsCount: action((state, payload) => {
		state.selectedListListItemsCount = payload;
	}),

	setSelectedListDetails: action((state, payload) => {
		state.selectedListDetails = payload;
	}),

	setLoadingUserLists: action((state, payload) => {
		state.loadingUserLists = payload;
	}),

	setLoadingSelectedList: action((state, payload) => {
		state.loadingSelectedList = payload;
	}),
};

export default LibraryStore;

import React, { FC } from 'react';
import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Typo from 'Components/Typo';
import { MicroCourse } from 'Models/MicroCourse/@types';
import MicroCourseModel from 'Models/MicroCourse';
import { LOGIN_PATH } from 'Routes/RouteConstants';
import { Font2Bold } from 'Theme';
import useAsyncTask from 'Hooks/useAsyncTask';
import { useStoreState, useStoreActions } from 'Stores';
import useEnrollViaEmail from 'Hooks/useEnrollViaEmail';
import { useHistory } from 'react-router';
import { THEME_PALETTE } from 'Theme/themeConstants';

export interface EnrollViaEmailBannerProps {
	microCourse: MicroCourse;
}

const EnrollViaEmailBanner: FC<EnrollViaEmailBannerProps> = ({ microCourse }) => {
	const classes = styles();
	const { enrollInMicroCourse } = useEnrollViaEmail();
	const { user } = useStoreState(({ Auth: { user } }) => ({ user }));
	const { setMicroCourse } = useStoreActions(({ MicroCourse: { setMicroCourse } }) => ({ setMicroCourse }));
	const history = useHistory();
	const handleEmailEnroll = async () => {
		if (microCourse && !microCourse.isEnrolled) {
			if (!user) {
				MicroCourseModel.setMicroCourseToEnrollIn(microCourse.id);
				history.push(`${LOGIN_PATH}`);
			}
			const success = await enrollInMicroCourse(microCourse.id);
			if (success) {
				setMicroCourse({ ...microCourse, isEnrolled: true });
			}
		}
	};
	const handleEmailEnrollTask = useAsyncTask(handleEmailEnroll);

	return (
		<Box onClick={handleEmailEnrollTask.run} role="button" className={clsx(classes.courseByEmailBanner, { [classes.cursorPointer]: !microCourse.isEnrolled })}>
			{handleEmailEnrollTask.status === 'PROCESSING' ? (
				<CircularProgress />
			) : (
				<>
					{microCourse.isEnrolled ? (
						<>
							<Typo weight="bold" variant="h4">
								Congrats! You’ve signed up for email reminders
							</Typo>
							<Typography variant="h5">Check your email for the first link!</Typography>
						</>
					) : (
						<>
							<Box>
								<Typo weight="bold" component="h5" variant="h5">
									Don’t have time today?
								</Typo>
							</Box>
							<Button variant="contained" className={classes.courseByEmailButton}>
								{!user ? 'Sign Up and get email reminders' : 'Get email reminders'}
							</Button>
						</>
					)}
				</>
			)}
		</Box>
	);
};

export default EnrollViaEmailBanner;

const styles = makeStyles((theme) => ({
	courseByEmailBanner: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8),
		width: '100%',
		boxSizing: 'border-box',
		userSelect: 'none',
		backgroundColor: THEME_PALETTE.blue['200'],
		display: 'flex',
		gap: 24,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		'& h4': {
			fontFamily: `${Font2Bold} !important`,
			fontSize: 24,
			color: theme.palette.common.white,
		},
		'& h5': {
			color: theme.palette.common.white,
		},
	},
	cursorPointer: {
		cursor: 'pointer',
	},
	courseByEmailButton: {
		height: 46,
		boxSizing: 'border-box',
		borderWidth: 2,
		borderRadius: 24,
		backgroundColor: theme.palette.common.white,
		color: theme.palette.primary.main,

		'&:hover': {
			borderWidth: 2,
			color: theme.palette.primary.main,
			backgroundColor: theme.palette.common.white,
		},
		[theme.breakpoints.down('sm')]: {
			height: 44,
			alignSelf: 'center',
			marginTop: 16,
		},
	},
}));

import React, { FC, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, AvatarProps, Box, Theme } from '@material-ui/core';
import { Picture } from 'Models/Picture/@types';
import PictureModel, { ImagekitCropTypes } from 'Models/Picture';

export interface StackedAvatarsProps {
	pictures: (Picture | undefined)[];
	overlap?: number;
	imgHW?: number;
	border?: string;
	transformType?: ImagekitCropTypes;
	avatarType?: AvatarProps['variant'];
}

const StackedAvatars: FC<StackedAvatarsProps> = ({ pictures, overlap = 12, imgHW = 80, border, transformType = 'auto', avatarType = 'rounded' }) => {
	const classes = useStyles({ hw: imgHW, border, overlap });

	const data = useMemo(
		() => ({
			imgUrls: (pictures?.filter((u) => !!u) as Picture[]).map((u) => PictureModel.getTransformedUrl(u.url, imgHW * 2, imgHW * 2, transformType)),
		}),
		[pictures]
	);

	return (
		<Box pl={`${overlap}px`}>
			<div className={classes.avatarContainer}>
				{data.imgUrls.map((imgUrl) => (
					<Avatar src={imgUrl} key={imgUrl || Math.random().toString()} className={classes.avatar} variant={avatarType} alt="" />
				))}
			</div>
		</Box>
	);
};

const useStyles = makeStyles<Theme, { hw: number; border?: string; overlap: number }>((theme) => ({
	avatar: {
		width: ({ hw }) => hw,
		height: ({ hw }) => hw,
		background: theme.palette.common.white,
		border: ({ border }) => border ?? `3px solid ${theme.palette.grey['200']}`,
		marginLeft: ({ overlap }) => `-${overlap}px`,
	},
	avatarContainer: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
	},
}));

export default StackedAvatars;

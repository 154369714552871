import React, { FC, useEffect, useMemo } from 'react';
import { Box, Link as MUILink, makeStyles, Typography } from '@material-ui/core';
import SvgIcon from 'Components/SvgIcon';
import useResponsive from 'Hooks/useResponsive';
import { useStoreActions, useStoreState } from 'Stores';
import ICONS from 'Constants/icons.json';
import { THEME_PALETTE } from 'Theme/themeConstants';
import Typo from 'Components/Typo';
import useAsyncTask from 'Hooks/useAsyncTask';
import { Link } from 'react-router-dom';
import { ABOUT_PATH, CONTACT_US_PATH, CURATORS_INFO_PATH, EXPLORE_HOME_PATH } from 'Routes/RouteConstants';
import _startCase from 'lodash/startCase';
import { Analytics } from 'analytics';
import DownloadsSection from 'Screens/Landing/DownloadsSection';

const Footer: FC = () => {
	const classes = styles();
	// const { isCordova } = useStoreState(({ App: { isCordova } }) => ({ isCordova }));
	const { isMobile } = useResponsive();
	const { getAllTags } = useStoreActions(({ App: { getAllTags } }) => ({ getAllTags }));
	const getAllTagsTask = useAsyncTask(getAllTags);
	const { tags } = useStoreState(({ App: { webOnlyTags: tags } }) => ({ tags }));
	useEffect(() => {
		if (!tags?.length) {
			getAllTagsTask.run({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const contactChet = useMemo(
		() => (
			<Box className={classes.contactChet}>
				<Typography>© 2021 Chet Network, Inc.</Typography>
				<MUILink underline="none" color="inherit" href="mailto:contact@chetnetwork.com">
					contact@chetnetwork.com
				</MUILink>
			</Box>
		),
		[]
	);
	const orgLinks = useMemo(
		() => (
			<Box mr={!isMobile ? 9 : undefined}>
				<Typo weight="bold" color="textSecondary">
					Company
				</Typo>
				<Box mt={1} display="flex" flexDirection="column">
					{additionalLinks.map((link) =>
						link.isExternal ? (
							<MUILink
								onClick={() => {
									Analytics.track('COMPANY_LINK_CLICKED', { page: link.analyticsPage });
								}}
								color="inherit"
								key={link.label}
								href={link.link}
								className={classes.link}
								rel="noopener noreferrer"
							>
								{link.label}
							</MUILink>
						) : (
							<MUILink
								component={Link}
								onClick={() => {
									Analytics.track('COMPANY_LINK_CLICKED', { page: link.analyticsPage });
								}}
								color="inherit"
								key={link.label}
								to={link.link}
								className={classes.link}
							>
								{link.label}
							</MUILink>
						)
					)}
				</Box>
			</Box>
		),
		[]
	);
	const tagLinks = useMemo(
		() => (
			<Box>
				<Typo weight="bold" color="textSecondary">
					Collections
				</Typo>
				<Box mt={1} display="flex" flexDirection="column">
					{tags?.map((tag) => (
						<Link color="inherit" key={tag.value} to={`${EXPLORE_HOME_PATH}?filterTags=${tag.value}`} className={classes.link}>
							{_startCase(`${tag.value === 'all' ? 'All Collections' : tag.name}`)}
						</Link>
					))}
				</Box>
			</Box>
		),
		[tags]
	);
	const socialLinks = useMemo(
		() => (
			<Box className={classes.socialLinks} display="flex">
				{iconsConfig.map((iconConfig) => (
					<MUILink
						onClick={() => {
							Analytics.track('SOCIAL_LINK_CLICKED', { siteName: iconConfig.siteName });
						}}
						aria-label={`link to ${iconConfig.link}`}
						color="inherit"
						key={iconConfig.link}
						target="_blank"
						href={iconConfig.link}
						className={classes.iconContainer}
						rel="noopener noreferrer"
					>
						{iconConfig.icon}
					</MUILink>
				))}
			</Box>
		),
		[]
	);

	return (
		<Box className={classes.root}>
			<Box className={classes.innerContainer}>
				{isMobile ? (
					<>
						{socialLinks}
						<DownloadsSection />
						<Box display="flex" justifyContent="space-around">
							{orgLinks}
							{tagLinks}
						</Box>
						{contactChet}
					</>
				) : (
					<>
						{contactChet}
						{orgLinks}
						{tagLinks}
						<Box display="flex" flexDirection="column" alignItems="flex-end">
							{socialLinks}
							<DownloadsSection />
						</Box>
					</>
				)}
			</Box>
		</Box>
	);
};

export default Footer;

const styles = makeStyles((theme) => ({
	root: {
		zIndex: 1,
		width: '100%',
		backgroundColor: THEME_PALETTE.blue.B400,
		display: 'flex',
		justifyContent: 'center',
		'& a': {
			lineHeight: 1.6,
		},
		[theme.breakpoints.only('md')]: {
			'& *': {
				transform: 'scale(0.95)',
			},
		},
	},
	iconContainer: {
		borderRadius: '50%',
		display: 'flex',
		textDecoration: 'none',
		width: 32,
		height: 32,
		justifyContent: 'center',
		alignItems: 'flex-start',
	},
	socialLinks: {
		gap: theme.spacing(1.5),
		opacity: 0.72,
		marginBottom: theme.spacing(2.5),
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'flex-start',
			marginTop: theme.spacing(6),
		},
	},
	link: {
		color: theme.palette.primary.contrastText,
		textDecoration: 'none',
		'&:visited': {
			color: theme.palette.primary.contrastText,
		},
	},
	contactChet: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			marginTop: theme.spacing(5),
			marginBottom: theme.spacing(5),
		},
	},
	innerContainer: {
		width: '100%',
		justifyContent: 'space-between',
		display: 'flex',
		boxSizing: 'border-box',
		paddingTop: 30,
		paddingBottom: 28,
		paddingLeft: 18,
		paddingRight: 18,
		flexGrow: 1,
		flexDirection: 'column',
		overflowX: 'hidden',
		color: theme.palette.primary.contrastText,
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
		},
		[theme.breakpoints.up('md')]: {
			paddingRight: 152,
			paddingLeft: 152,
		},
		[theme.breakpoints.up('xl')]: {
			maxWidth: 1440,
		},
	},
}));

const additionalLinks = [
	{
		label: 'About',
		analyticsPage: 'About',
		link: ABOUT_PATH,
	},
	// {
	// 	label: 'Mission',
	// 	analyticsPage: 'Mission',
	// 	link: 'https://chetnetwork.com/mission.html',
	// 	isExternal: true,
	// 	openInNewTab: false,
	// },
	{
		label: 'Curators',
		analyticsPage: 'Curators',
		link: CURATORS_INFO_PATH,
		isExternal: false,
		openInNewTab: false,
	},
	{
		label: 'Contact',
		analyticsPage: 'Contact',
		link: CONTACT_US_PATH,
		isExternal: false,
		openInNewTab: false,
	},
];

const iconsConfig: { icon: JSX.Element; link: string; siteName: string }[] = [
	{
		icon: <SvgIcon size={32} icon={ICONS.fb} variant="none" />,
		link: 'https://www.facebook.com/chetapp/',
		siteName: 'FACEBOOK',
	},
	{
		icon: <SvgIcon size={32} icon={ICONS.insta} variant="none" />,
		link: 'https://www.instagram.com/chetapp/',
		siteName: 'INSTAGRAM',
	},
	{
		icon: <SvgIcon size={32} icon={ICONS.twitter} variant="none" />,
		link: 'https://twitter.com/chetapp/',
		siteName: 'TWITTER',
	},
	{
		icon: <SvgIcon size={32} icon={ICONS.linkedIn} variant="none" />,
		link: 'https://www.linkedin.com/company/chet/',
		siteName: 'LINKEDIN',
	},
	{
		icon: <SvgIcon size={32} icon={ICONS.tiktok} variant="none" />,
		link: 'https://tiktok.com/@chetapp',
		siteName: 'TIKTOK',
	},
];

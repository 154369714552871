import MicroCourseModel from 'Models/MicroCourse';
import { request } from 'Resources/AxiosUtils';
import { JSONType } from 'Typings/@types';
import { PathwayStep } from './@types';
import { parseToPathwayStep } from './PathwayStepParsers';

class PathwayStepModel {
	static getAllPathwayStep = async (params?: JSONType): Promise<PathwayStep[]> => {
		const res = await request<JSONType[]>({
			url: '/pathwaySteps',
			method: 'GET',
			params,
		});
		return res.map(parseToPathwayStep);
	};

	static getPathwayStep = async (id: string, params?: JSONType): Promise<PathwayStep> => {
		const res = request<JSONType>({
			url: `/pathwaySteps/${id}`,
			method: 'GET',
			params,
		});
		return parseToPathwayStep(res);
	};

	static getCompletedMicroCoursesLS = (pathwayStep: PathwayStep): number => {
		let completed = 0;
		const lSMicroCourses = MicroCourseModel.getLSMicroCourses();
		pathwayStep.microCourses?.forEach((mc) => {
			const completedChapterSlugs = lSMicroCourses[mc.slug];
			// eslint-disable-next-line no-plusplus
			if (mc.chapterCount === (completedChapterSlugs?.length || 0)) completed++;
		});
		return completed;
	};
}

export default PathwayStepModel;

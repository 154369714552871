import { action, Action, thunk, Thunk } from 'easy-peasy';
import { Company, CompanyListicle } from 'Models/Company/@types';
import { RootStore } from 'Stores';
import { Params } from 'Typings/@types';
import CompanyModel from 'Models/Company';

export interface CompanyState {
	company?: Company;
	companyListicles: CompanyListicle[];
	getCompany: Thunk<CompanyState, { id: string; params?: Params }, unknown, RootStore, Promise<Company>>;
	getCompanyListicles: Thunk<CompanyState, { params?: Params }, unknown, RootStore, Promise<CompanyListicle[]>>;
	setCompanyListicles: Action<CompanyState, CompanyListicle[]>;
	setCompany: Action<CompanyState, Company>;
}

const CompanyStore: CompanyState = {
	company: undefined,
	companyListicles: [],
	getCompany: thunk(async (actions, { id, params }) => {
		const company = await CompanyModel.getCompany(id, params);
		actions.setCompany(company);
		return company;
	}),
	getCompanyListicles: thunk(async (actions, { params }) => {
		const companyListicles = await CompanyModel.getCompanyListicles(params);
		actions.setCompanyListicles(companyListicles);
		return companyListicles;
	}),
	setCompany: action((state, payload) => {
		state.company = payload;
	}),
	setCompanyListicles: action((state, payload) => {
		state.companyListicles = payload;
	}),
};

export default CompanyStore;

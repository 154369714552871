import { Chapter } from 'Models/Chapter/@types';
import { Job } from 'Models/JobListing/@types';
import { MicroCourse } from 'Models/MicroCourse/@types';
import { Pathway } from 'Models/Pathway/@types';
import { Post } from 'Models/Post/@types';
import { TimeStamp } from 'Typings/@types';

export interface UserList extends TimeStamp {
	name: string;
	userId: string;
	id: string;
	slug: string;
	type?: DefaultListType | RecentlyViewed;
	userListItemIds: string[];
	userListItems?: UserListItem[];
}

export const defaultListType = 'uncategorized';
export type DefaultListType = 'uncategorized';
export type RecentlyViewed = 'recentlyViewed';
export const recentlyViewedSlug = 'recentlyViewed';

export interface UserListItem extends TimeStamp {
	id: string;
	subjectId: string;
	subjectType: 'MicroCourse' | 'Chapter' | 'Post' | 'Pathway' | 'Job';
	subject?: MicroCourse | Chapter | Post | Pathway | Job;
}

import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { useStoreState } from 'Stores';
import useResponsive from 'Hooks/useResponsive';
import Config from 'config';
import { THEME_PALETTE } from 'Theme/themeConstants';
import HeaderSm from './sm';
import HeaderLg from './lg';
import { resolveUserIcon } from './utils';
import { HeaderProps } from './@types';

const Header: FC<HeaderProps> = () => {
	const classes = useStyles();
	const { user } = useStoreState(({ Auth: { user } }) => ({ user }));
	const { isMobile } = useResponsive();
	const history = useHistory();
	const resolvedUserIcon = resolveUserIcon(classes, history.location.pathname, isMobile, user);

	return (
		<>
			{isMobile ? (
				<HeaderSm logoLink={user ? (Config.get('POST_LOGIN_REDIRECT') as string) : '/'} resolvedUserIcon={resolvedUserIcon} />
			) : (
				<HeaderLg logoLink={user ? (Config.get('POST_LOGIN_REDIRECT') as string) : '/'} resolvedUserIcon={resolvedUserIcon} />
			)}
		</>
	);
};

export default Header;

const useStyles = makeStyles((theme) => ({
	avatar: {
		borderRadius: '50%',
		boxSizing: 'border-box',
		height: 35,
		width: 35,
		cursor: 'pointer',
		[theme.breakpoints.up('sm')]: {
			height: 40,
			width: 40,
		},
	},
	avatarWithBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
	},
	content: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flex: 1,
		[theme.breakpoints.up('sm')]: {
			boxSizing: 'border-box',
		},
	},
	authLinkButton: {
		height: 48,
		padding: theme.spacing('0.5rem', '38px'),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 1),
			fontSize: 14,
			width: 'fit-content',
		},
		margin: theme.spacing(0, 0.5),
		fontSize: 16,
		lineHeight: 1.2,
		boxSizing: 'border-box',
		'&.MuiButton-contained': {
			backgroundColor: THEME_PALETTE.blue['200'],
		},
	},
}));

import { Picture } from 'Models/Picture/@types';
import { TimeStamp } from 'Typings/@types';

export interface Tag extends TimeStamp {
	name: string;
	value: string;
	notes?: string;
	force?: boolean;
	userPreference?: boolean;
	applyOnFilters?: boolean;
	type: string;
	featured?: boolean;
	popular?: boolean;
	postCount?: number;
	userCount?: number;
	trendScore?: number;
	trending?: boolean;
	id: string;
	picture?: Picture;
	emoji?: string;
	metaTags?: string;
}

export interface TagCategory extends TimeStamp {
	id: string;
	tagIds: string[];
	tags: Tag[];
	order: number;
	name: string;
	webOnly: boolean;
	type?: typeof MainTagCategoryType | typeof FeedTagCategoryType | null;
	_picture: Picture;
}

export const MainTagCategoryType = 'exploreListCategory';
export const FeedTagCategoryType = 'feedCategory'; // Not currently used in back-end atm.

import { Box, createStyles, makeStyles, Paper } from '@material-ui/core';
import clsx from 'clsx';
import { Chapter } from 'Models/Chapter/@types';
import React, { FC } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { CHAPTER_PATH } from 'Routes/RouteConstants';
import ABExperiment from 'Features/ABExperiment/Explore';
import { MicroCourse } from 'Models/MicroCourse/@types';
import ProfileImageWithLogo from 'Components/ProfileImageWithLogo';
import IconText from 'Components/IconText';
import { getIcon } from 'Features/Chapters/ChapterUtils';
import { UilUser /* , UilBookmark */ } from '@iconscout/react-unicons';
import { getTimeLargestOnly } from 'Utils/TimeUtils';
import Spacer from 'Components/Spacer';
import { Font3Regular } from 'Theme';
import Typo from 'Components/Typo';

interface ChapterListicleCardProps {
	chapter: Chapter;
	classes?: ChapterListicleCardClasses;
	shouldUseExperiment?: boolean;
}

interface ChapterListicleCardClasses {
	root?: string;
	curatorsContainer?: string;
	tagContainer?: string;
}

const ChapterListicleCard: FC<ChapterListicleCardProps> = ({ chapter, classes: propClasses, shouldUseExperiment }) => {
	const { microCourse = {} as MicroCourse, type, contentDuration, totalViewCount } = chapter;
	const { curators } = microCourse;
	const classes = useStyles();

	const ContentInsideLink = React.useMemo(
		() => (
			<Paper className={classes.root}>
				<Box display="flex" style={{ gap: 12 }}>
					<ProfileImageWithLogo profileSize={72} logoSize={32} profileImage={curators?.[0].profileImage?.url} companyLogo={curators?.[0].companyLogo?.url} />
					<Box ml={2}>
						<Typo font="Font2" className={classes.title} variant="body2" weight="bold">
							{chapter.title}
						</Typo>
						<Box display="flex" justifyContent="space-between">
							{contentDuration ? (
								<IconText
									typoProps={{ weight: 'bold', variant: 'caption', color: 'inherit' }}
									icon={getIcon(type)}
									text={getTimeLargestOnly(contentDuration, { h: 'hour', hs: 'hours', m: 'minute', ms: 'minutes', s: 'second', ss: 'seconds' })}
								/>
							) : (
								<Spacer />
							)}
							{totalViewCount ? (
								<IconText typoProps={{ weight: 'bold', variant: 'caption', color: 'inherit' }} icon={<UilUser size={18} />} text={totalViewCount.toLocaleString()} />
							) : null}
						</Box>
					</Box>
				</Box>
			</Paper>
		),
		[classes.root, classes.title]
	);
	if (shouldUseExperiment) {
		return <ABExperiment controlPath={generatePath(CHAPTER_PATH, { microCourseSlug: microCourse?.slug ?? '', chapterSlug: chapter.slug ?? ' ' })}>{ContentInsideLink}</ABExperiment>;
	}

	return (
		<Link className={clsx(classes.link, propClasses?.root)} to={generatePath(CHAPTER_PATH, { microCourseSlug: microCourse?.slug || ' ', chapterSlug: chapter.slug ?? ' ' })}>
			{ContentInsideLink}
		</Link>
	);
};

export default ChapterListicleCard;
const useStyles = makeStyles((theme) =>
	createStyles({
		link: {
			textDecoration: 'none',
			display: 'flex',
			flex: 1,
			color: 'inherit',
		},
		root: {
			height: 'fit-content',
			width: '100%',
			padding: theme.spacing(2),
			marginRight: 16,
			boxShadow: `0px 2px 20px 0px ${theme.palette.text.secondary}26`,
			[theme.breakpoints.up('md')]: {
				marginRight: 'unset',
			},
		},
		title: {
			fontFamily: Font3Regular,
		},
		// tagContainer: {
		// 	backgroundColor: THEME_PALETTE.yellow['200'],
		// 	borderRadius: 8,
		// 	padding: '8px 27px',
		// 	position: 'absolute',
		// 	boxShadow: '0px 0px 1px rgba(117, 131, 142, 0.04), 0px 2px 4px rgba(52, 60, 68, 0.16)',
		// 	height: 26,
		// 	display: 'flex',
		// 	alignItems: 'center',
		// 	boxSizing: 'border-box',
		// 	top: -170,
		// 	left: -8,
		// 	opacity: 0.7,
		// 	'& > span': {
		// 		lineHeight: 1.2,
		// 	},
		// 	[theme.breakpoints.up('sm')]: {
		// 		top: -210,
		// 		left: -8,
		// 	},
		// },
		// coverImage: {
		// 	position: 'relative',
		// 	width: '100%',
		// 	backgroundColor: theme.palette.background.paper,
		// },
		// microCoursesCuratorNameText: {
		// 	fontWeight: fontWeightMedium,
		// 	paddingTop: '0.5rem',
		// },
		// microCoursesCuratorPositionText: {
		// 	color: theme.palette.grey['600'],
		// },
		// timeText: {
		// 	color: 'white',
		// 	display: 'flex',
		// 	alignItems: 'center',
		// 	fontSize: 12,
		// 	width: '100%',
		// 	'&>img': { height: '1em' },
		// 	[theme.breakpoints.up('sm')]: {},
		// },
		numViews: {
			fontSize: 12,
			lineHeight: 1.2,
			color: theme.palette.background.paper,
			height: 12,
			display: 'flex',
			alignItems: 'center',
			alignSelf: 'flex-end',
			'&>svg': {
				height: '1em',
				width: '1em',
				marginRight: 4,
			},
		},
		viewLessonContainer: {
			position: 'absolute',
			top: -55,
			display: 'flex',
			justifyContent: 'space-between',
			width: '105%',
			marginLeft: -8,
			alignItems: 'center',
		},
		curatorsContainer: {
			position: 'absolute',
			top: -38,
			zIndex: 1, // create new stacking context
		},
	})
);

import { request } from 'Resources/AxiosUtils';
import { Filter, JSONType, Params } from 'Typings/@types';
import _isEmpty from 'lodash/isEmpty';
import { parseToChapter } from 'Models/Chapter/ChapterParsers';
import { Chapter } from 'Models/Chapter/@types';
import { parseToMicroCourse } from './MicroCourseParsers';
import { MicroCourse } from './@types';

class MicroCourseModel {
	static getAllMicroCourses = async (params?: Params): Promise<MicroCourse[]> => {
		// Can only return published microCourses.
		const res = await request<JSONType[]>({
			url: '/microCourses',
			method: 'GET',
			params,
		});
		return res.map(parseToMicroCourse);
	};

	static getRelatedMicroCourses = async (id: string, params?: Params): Promise<MicroCourse[]> => {
		const res = await request<JSONType[]>({
			url: `/microCourses/${id}/relatedMicroCourses`,
			method: 'GET',
			params,
		});
		return res.map(parseToMicroCourse);
	};

	static postMicroCourseView = async (microCourseId: string, raw?: boolean): Promise<JSONType | JSONType[]> => {
		const { viewedMicroCourses } = MicroCourseModel.getLSViewedMicroCourses();
		if ((!_isEmpty(viewedMicroCourses) && !viewedMicroCourses.includes(microCourseId)) || _isEmpty(viewedMicroCourses)) {
			MicroCourseModel.addViewedMicroCourseToLS(microCourseId);
		}
		if (!raw) {
			return request<JSONType>({
				url: `/microCourses/${microCourseId}/view`,
				method: 'POST',
			});
		}

		return request<JSONType[]>({
			url: `Views/rvp`,
			method: 'POST',
			params: { entityId: microCourseId, entityType: 'MicroCourse' },
		});
	};

	static getMicroCourse = async (slug: string, params?: Params): Promise<MicroCourse> => {
		const res = await request<JSONType>({
			url: `/microCourses/${slug}`,
			method: 'GET',
			params,
		});
		return parseToMicroCourse(res);
	};

	static getLSMicroCourses = (): JSONType => {
		let lSMicroCourses: null | string | JSONType = localStorage.getItem('microCourses');
		lSMicroCourses = lSMicroCourses ? JSON.parse(lSMicroCourses) : {};
		return lSMicroCourses as JSONType;
	};

	static clearLSMicroCourses = (): void => {
		localStorage.removeItem('microCourses');
	};

	static addChapterToLSMicroCourse = (chapterSlug: string, microCourseSlug: string): void => {
		const lSMicroCourses = MicroCourseModel.getLSMicroCourses();
		if (!lSMicroCourses[microCourseSlug] || !lSMicroCourses[microCourseSlug]?.includes(chapterSlug)) {
			if (Array.isArray(lSMicroCourses[microCourseSlug])) {
				lSMicroCourses[microCourseSlug].push(chapterSlug);
			} else {
				lSMicroCourses[microCourseSlug] = [chapterSlug];
			}
		}
		localStorage.setItem('microCourses', JSON.stringify(lSMicroCourses));
	};

	static getLSViewedMicroCourses = (): JSONType => {
		let LSViewedMicroCourses: null | string | JSONType = localStorage.getItem('viewedMicroCourses');
		LSViewedMicroCourses = LSViewedMicroCourses ? JSON.parse(LSViewedMicroCourses) : {};
		return LSViewedMicroCourses as JSONType;
	};

	static clearLSViewedMicroCourses = (): void => localStorage.removeItem('viewedMicroCourses');

	static addViewedMicroCourseToLS = (microCourseId: string): void => {
		const viewedMicroCoursesObj = MicroCourseModel.getLSViewedMicroCourses();
		viewedMicroCoursesObj.viewedMicroCourses ? viewedMicroCoursesObj.viewedMicroCourses.push(microCourseId) : (viewedMicroCoursesObj.viewedMicroCourses = [microCourseId]);
		localStorage.setItem('viewedMicroCourses', JSON.stringify(viewedMicroCoursesObj));
	};

	static enrollUserViaEmail = async (microCourseId: string): Promise<JSONType> =>
		request<JSONType>({
			url: `microCourses/${microCourseId}/enroll`,
			method: 'POST',
		});

	static setMicroCourseToEnrollIn = (id: string): void => {
		localStorage.setItem('enrollViaEmail', id);
	};

	static getMicroCourseToEnrollIn = (): string | undefined => {
		const enrollViaEmail: string | null = localStorage.getItem('enrollViaEmail') as string;
		return enrollViaEmail;
	};

	static clearMicroCourseToEnrollIn = (): void => {
		localStorage.removeItem('enrollViaEmail');
	};

	static createMicroCourse = async (data: Partial<MicroCourse>): Promise<MicroCourse> => {
		const courseData = await request<JSONType>({
			url: `/microCourses/createNew`,
			method: 'POST',
			data,
		});
		return parseToMicroCourse(courseData);
	};

	static createChapter = async (data: Partial<Chapter>): Promise<Chapter> => {
		const chapterData = await request<JSONType>({
			url: `/posts/create-chapter`,
			method: 'POST',
			data,
		});
		return parseToChapter(chapterData);
	};

	static updateMicroCourse = async (data: Partial<MicroCourse>, id: string): Promise<MicroCourse> => {
		const updatedCourseData = await request<JSONType>({
			url: `/microCourses/${id}/update-data`,
			method: 'PATCH',
			data,
		});
		return parseToMicroCourse(updatedCourseData);
	};

	static updateChapter = async (data: Partial<Chapter>, id: string): Promise<Chapter> => {
		const updatedChapterData = await request<JSONType>({
			url: `/posts/${id}/update-data`,
			method: 'PATCH',
			data,
		});
		return parseToChapter(updatedChapterData);
	};

	static getDraftMicroCourses = async (userId: string, params?: Params): Promise<{ count: number; results: MicroCourse[] }> => {
		const { count, results } = await request<{ count: number; results: JSONType[] }>({
			url: `users/${userId}/my-courses`,
			method: 'GET',
			params: { ...params, filter: { ...params?.filter, where: { isSubmittedForReview: false, isPublished: false } } },
		});
		return { count, results: results.map(parseToMicroCourse) };
	};

	static getInReviewMicroCourses = async (userId: string, params?: Params): Promise<{ count: number; results: MicroCourse[] }> => {
		const { count, results } = await request<{ count: number; results: JSONType[] }>({
			url: `users/${userId}/my-courses`,
			method: 'GET',
			params: { ...params, filter: { ...params?.filter, where: { isSubmittedForReview: true, isPublished: false } } },
		});
		return { count, results: results.map(parseToMicroCourse) };
	};

	static deleteMicroCourse = async (id: string): Promise<boolean> => {
		const deleted = (
			await request<{ deletedIds: string[] }>({
				url: `/microCourses/move-to-trash`,
				method: 'DELETE',
				params: {
					id,
				},
			})
		).deletedIds.includes(id);
		return deleted;
	};

	static getAllChapters = async (slug: string, filter?: Filter): Promise<Chapter[]> => {
		const chapters = await request<JSONType>({
			url: `microCourses/${slug}/chapters`,
			method: 'GET',
			params: { filter },
		});
		return chapters.map(parseToChapter);
	};

	static deleteChapter = async (id: string): Promise<boolean> => {
		const deleted = (
			await request<{ deletedIds: string[] }>({
				url: `/posts/move-to-trash`,
				method: 'DELETE',
				params: {
					id,
				},
			})
		).deletedIds.includes(id);
		return deleted;
	};

	static sendReviewRequest = async (id: string): Promise<MicroCourse> => {
		const reviewData = await request<JSONType>({
			url: `microCourses/${id}/submit-for-review`,
			method: 'POST',
		});
		return parseToMicroCourse(reviewData);
	};

	static getMyCourses = async (userId: string, params?: Params): Promise<{ count: number; results: MicroCourse[] }> => {
		const { count, results } = await request<{ count: number; results: JSONType[] }>({
			url: `users/${userId}/my-courses`,
			method: 'GET',
			params,
		});
		return { count, results: results.map(parseToMicroCourse) };
	};

	static followMicroCourse = async (id: string): Promise<JSONType> => {
		const subscription = await request<JSONType>({
			url: `microCourses/${id}/follow`,
			method: 'POST',
		});
		return subscription;
	};

	static unFollowMicroCourse = async (followId: string): Promise<{ count: number }> => {
		const subscription = await request<{ count: number }>({
			url: `follows/${followId}/unfollow`,
			method: 'DELETE',
		});
		return subscription;
	};
}

export default MicroCourseModel;

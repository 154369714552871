import { makeStyles, createStyles, Box, alpha /* Button */ } from '@material-ui/core';
import { User } from 'Models/User/@types';
import React, { FC, useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import ABExperiment from 'Features/ABExperiment/Explore';
import { CURATOR_PROFILE_PATH, USER_PROFILE_PATH } from 'Routes/RouteConstants';
import UserModel from 'Models/User';
import Typo from 'Components/Typo';
import { THEME_PALETTE } from 'Theme/themeConstants';
import clsx from 'clsx';
import ProfileImageWithLogo from 'Components/ProfileImageWithLogo';

export interface UserListicleCardProps {
	user: User;
	classes?: { root?: string; image?: string; name?: string };
	shouldUseExperiment?: boolean;
}

const UserListicleCard: FC<UserListicleCardProps> = (props) => {
	const { user, classes: propClasses, shouldUseExperiment } = props;
	const classes = useStyles();
	const { userNameText, userDescriptionText } = useMemo(() => {
		const userNameText = UserModel.getUserFullName(user);
		const userDescriptionText = `${user.recentCompany?.trim() || ''} ${user.recentJobTitle ? ` · ${user.recentJobTitle.trim()}` : ''}`;
		return { userNameText, userDescriptionText };
	}, []);
	const ContentInsideLink = useMemo(
		() => (
			<Box position="relative" zIndex={1} height="100%" display="flex" flexDirection="column" alignItems="center">
				<ProfileImageWithLogo profileSize={72} logoSize={32} profileImage={user.profileImage?.url} name={userNameText} recentCompany={user.recentCompany} companyLogo={user.companyLogo?.url} />
				<Box display="flex" flexDirection="column" mt={1.5} height="100%" width="100%">
					<Typo align="center" weight="bold" variant="body1">
						{userNameText}
					</Typo>
					<Typo align="center" limitLines={2} className={classes.descriptionText} variant="body2" weight="bold">
						{userDescriptionText}
					</Typo>
					{/* <Button variant={user.isFollowed ? 'text' : 'contained'} color="primary">
						{!user.isFollowed ? 'Follow' : 'Unfollow'}
					</Button> */}
					<Typo align="center" className={classes.viewProfileText} limitLines={1} variant="body2" weight="bold" color="primary">
						View Profile
					</Typo>
				</Box>
			</Box>
		),
		[classes.img, propClasses?.image, propClasses?.name, user.firstName, user.lastName, user.name, user.profileImage?.url]
	);

	if (shouldUseExperiment) {
		return <ABExperiment controlPath={generatePath(USER_PROFILE_PATH, { userSlug: user.slug })}>{ContentInsideLink}</ABExperiment>;
	}
	return (
		<Link className={clsx(classes.root, propClasses?.root)} to={generatePath(CURATOR_PROFILE_PATH, { curatorSlug: user.slug ?? user.id })}>
			{ContentInsideLink}
		</Link>
	);
};

export default UserListicleCard;

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			padding: theme.spacing(2),
			boxShadow: `0px 1px 4px 0px ${alpha(THEME_PALETTE.grey['800'], 0.113)}`,
			width: '100%',
			height: '100%',
			border: `1.5px solid ${theme.palette.primary.main}`,
			color: 'inherit',
			textDecoration: 'none',
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
			backgroundColor: theme.palette.background.paper,
			borderRadius: theme.spacing(2),
			marginRight: theme.spacing(2), // ListDocCard adds 12px to the right. Adding 24px to the right margin to get spacing of 36px total.
		},
		viewProfileText: {
			marginTop: 'auto',
			marginBottom: 0,
		},
		img: {
			width: 72,
			height: 72,
			borderRadius: '100%',
			backgroundColor: theme.palette.primary.main,
			[theme.breakpoints.up('sm')]: {
				width: 122,
				height: 122,
			},
		},
		descriptionText: {
			color: theme.palette.grey['600'],
		},
	})
);

// eslint-disable-next-line import/prefer-default-export
export function chapterCompleted(chapterId: string, accessToken: string): void {
	document.dispatchEvent(
		new CustomEvent('markCompleted', {
			detail: {
				chapterId,
				accessToken,
			},
		})
	);
}

import { request } from 'Resources/AxiosUtils';
import { Params } from 'Typings/@types';
import { Elite } from './@types';
import { parseToEliteItem } from './ElitesParsers';

class EliteModel {
	static getElites = async (params?: Params): Promise<Elite[]> => {
		const elites = await request<Elite[]>({
			url: 'Elites/',
			method: 'GET',
			params,
		});
		return elites.map(parseToEliteItem);
	};
}

export default EliteModel;

import React, { FC, ReactElement, useState } from 'react';
import { HeaderProps } from 'Features/Header/@types';

interface ContextProps {
	headerProps: HeaderProps;
	setAdditionalContent: (additionalContent: JSX.Element | null) => void;
	additionalContent: ReactElement | null;
}

export const HeaderContext = React.createContext<ContextProps>({
	headerProps: {} as HeaderProps,
	setAdditionalContent: () => {},
	additionalContent: null,
});

export const useHeader = (
	initialState: HeaderProps
): {
	headerProps: HeaderProps;
	setAdditionalContent: (additionalContent: JSX.Element | null) => void;
	setHeader: (updatedHeaderProps: Partial<HeaderProps>) => void;
	additionalContent: ReactElement | null;
} => {
	const [headerProps, setHeaderProps] = useState(initialState);
	const [additionalContent, setAdditionalContent] = useState<ReactElement | null>(null);
	const setHeader = (updatedHeaderProps: Partial<HeaderProps>) => {
		setHeaderProps({ ...headerProps, ...updatedHeaderProps });
	};

	return { headerProps, setAdditionalContent, setHeader, additionalContent };
};
export const HeaderProvider: FC = ({ children }) => {
	const { headerProps, setAdditionalContent, additionalContent } = useHeader({});
	return (
		<HeaderContext.Provider
			value={{
				headerProps,
				setAdditionalContent,
				additionalContent,
			}}
		>
			{children}
		</HeaderContext.Provider>
	);
};

export default HeaderProvider;

import React, { FC, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Pathway } from 'Models/Pathway/@types';
import useLayoutQuery from 'Hooks/useLayoutQuery';
import { generatePath, Link } from 'react-router-dom';
import { PATHWAY_PATH } from 'Routes/RouteConstants';
import ABExperiment from 'Features/ABExperiment/Explore';
import { User } from 'Models/User/@types';
import { alpha, Box, Paper, Theme } from '@material-ui/core';
import { Picture } from 'Models/Picture/@types';
import PictureModel from 'Models/Picture';
import Typo from 'Components/Typo';
import IconText from 'Components/IconText';
import { /* useStoreActions, */ useStoreState } from 'Stores';
// import { bindToggle, bindMenu } from 'material-ui-popup-state';
import { recentlyViewedSlug /* , UserList */ } from 'Models/UserList/@types';
// import { usePopupState } from 'material-ui-popup-state/hooks';
import { UilLayerGroup, UilUser /* , UilBookmark */ } from '@iconscout/react-unicons';
// import { UisBookmark } from '@iconscout/react-unicons-solid';
import { Font2Bold } from 'Theme';
import StackedAvatars from 'Components/StackedAvatars';
import { useTheme } from '@material-ui/styles';

export interface PathwayCardProps {
	pathway: Pathway;
	className?: string;
	shouldUseExperiment?: boolean;
}

const PathwayCard: FC<PathwayCardProps> = (props) => {
	const { pathway, className, shouldUseExperiment } = props;
	const { ref } = useLayoutQuery();
	const classes = useStyles({ coverImage: pathway.coverImage?.url });
	// const popupState = usePopupState({ variant: 'popper', popupId: 'addToListPopper' });
	const {
		userLists,
		defaultLists,
		// loadingUserLists,
		// user: appUser,
	} = useStoreState(({ Auth: { user }, Library: { userLists, defaultLists, loadingUserLists } }) => ({
		user,
		userLists,
		defaultLists,
		loadingUserLists,
	}));
	const lists = (defaultLists ?? []).filter((value) => value.slug !== recentlyViewedSlug).concat(userLists ?? []);
	// const { setUserLists, setDefaultLists } = useStoreActions(({ Library: { setUserLists, setDefaultLists } }) => ({ setUserLists, setDefaultLists }));
	// const { onClick, ...toggleProps } = bindToggle(popupState);
	// const { onClose, ...menuProps } = bindMenu(popupState);

	// const updateLists = (lists: UserList[]) => {
	// 	const userLists: UserList[] = [];
	// 	const defaultLists: UserList[] = [];
	// 	lists.forEach((list) => {
	// 		if (list.type === 'uncategorized') {
	// 			defaultLists.push(list);
	// 			return;
	// 		}
	// 		userLists.push(list);
	// 	});
	// 	setUserLists(userLists);
	// 	setDefaultLists(defaultLists);
	// };

	const { companyLogos, coverImage, /* curatorCompaniesList, */ pathwayName, leftIcon, leftIconText, rightIconText, rightIcon } = useMemo(() => {
		const theme = useTheme<Theme>();
		const curators: User[] = (pathway.pathwaySteps
			?.flatMap((pathwayStep) => (pathwayStep.microCourses ?? [])?.flatMap((microCourse) => microCourse.curators) ?? [])
			.filter((curator) => !!curator) ?? []) as User[];
		const companyLogos = curators
			.map((curator) => curator.companyLogo)
			.filter((logo) => !!logo)
			.slice(0, 6) as Picture[];
		const curatorCompaniesList = curators
			.map((curator) => curator.recentCompany)
			.filter((companyName) => !!companyName)
			.slice(0, 6)
			.concat(curators.length > 6 ? [`${curators.length - 6} more`] : [])
			.join(' • ');
		const coverImage = PictureModel.getTransformedUrl(pathway.coverImage.url, 576, 416, 'auto');
		const { name: pathwayName, totalViewCount } = pathway;
		const numLessons = (pathway.pathwaySteps ?? [])
			?.flatMap((pathwayStep) => (pathwayStep.microCourses ?? [])?.flatMap((microCourse) => microCourse.chapterIds.length))
			?.reduce((currentTotal, currentItem) => currentTotal + currentItem, 0);
		const leftIconText = `${numLessons} links`;
		const rightIconText = totalViewCount ? `${totalViewCount.toLocaleString()}` : '';
		const leftIcon = <UilLayerGroup color={theme.palette.common.white} size={18} />;
		const rightIcon = totalViewCount ? <UilUser color={theme.palette.common.white} size={18} /> : <></>;

		let isItemSavedToList = false;
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < lists.length; ++i) {
			if ((lists[i].userListItems?.findIndex((item) => item.subjectId === pathway.id) ?? 0) > -1) {
				isItemSavedToList = true;
				break;
			}
		}

		return { companyLogos, curatorCompaniesList, coverImage, pathwayName, rightIconText, leftIconText, leftIcon, isItemSavedToList, rightIcon };
	}, []);

	// const SaveToListElement = useMemo(() => {
	// 	if (loadingUserLists) return <CircularProgress />;
	// 	return isItemSavedToList ? <UisBookmark size={18} /> : <UilBookmark size={18} />;
	// }, [loadingUserLists, isItemSavedToList]);

	const ContentInsideLink = useMemo(
		() => (
			<Paper className={classes.root}>
				{/* <Box className={classes.topContent}> */}
				{/* <Box className={classes.pathwayTitleContainer}> */}
				<Typo className={classes.pathwayPreText} weight="regular">
					Chet&apos;s Guide to
				</Typo>
				<Typo className={classes.pathwayTitle} variant="h4">
					{pathwayName}
				</Typo>
				{/* </Box> */}
				{companyLogos.length > 0 ? (
					<Box className={classes.companiesContainer}>
						<StackedAvatars pictures={companyLogos} avatarType="circle" imgHW={32} overlap={8} />
					</Box>
				) : null}
				<Box display="flex" justifyContent="space-between" mt="auto" mb="0">
					{leftIcon && leftIconText ? (
						<Box className={classes.contentInfo}>
							<IconText typoProps={{ weight: 'bold', variant: 'caption', color: 'inherit' }} icon={leftIcon} text={leftIconText} />
						</Box>
					) : null}
					{rightIcon && rightIconText ? (
						<Box className={classes.contentInfo}>
							<IconText typoProps={{ weight: 'bold', variant: 'caption', color: 'inherit' }} icon={rightIcon} text={rightIconText} iconOnRight />
						</Box>
					) : null}
				</Box>
				{/* </Box> */}
				{/* <Box className={classes.bottomContent}> */}
				<Box>
					{/* <Box>
						<StackedAvatars pictures={companyLogos} />
					</Box> */}
					{/* <Typo variant="subtitle1" weight="bold">
							Featuring curators from{' '}
						</Typo>
						<Typo limitLines={3} variant="subtitle2" className={classes.curatorCompaniesList}>
							{curatorCompaniesList}
						</Typo> */}
				</Box>
				{/* <Box mt={2} display="flex" justifyContent="space-between" alignItems="center"> */}
				{/* <Box display="flex" justifyContent="space-between">
						{leftIcon && leftIconText ? (
							<Box className={classes.contentInfo}>
								<IconText typoProps={{ weight: 'bold', variant: 'subtitle1', color: 'inherit' }} icon={leftIcon} text={leftIconText} />
							</Box>
						) : null}
						{rightIcon && rightIconText ? (
							<Box className={classes.contentInfo}>
								<IconText typoProps={{ weight: 'bold', variant: 'subtitle1', color: 'inherit' }} icon={rightIcon} text={rightIconText} />
							</Box>
						) : null}
					</Box> */}
				{/* {!isEmpty(appUser) ? (
							<>
								<Button
									{...toggleProps}
									className={classes.bookmarkBtn}
									onClick={(event) => {
										event.preventDefault();
										onClick(event);
									}}
									disabled={loadingUserLists}
								>
									{SaveToListElement}
								</Button>
								{
									<Menu
										anchorOrigin={
											{
												// vertical: 'bottom',
												horizontal: 'right',
											} as PopoverOrigin
										}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										onClose={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
											event.preventDefault();
											event.stopPropagation();
											onClose();
										}}
										{...menuProps}
										className={classes.popper}
									>
										<UserListMenu lists={lists} userId={appUser?.id ?? ''} subjectType="Pathway" subjectId={pathway.id} setUpdatedLists={updateLists} />
									</Menu>
								}
							</>
						) : null} */}
				{/* </Box> */}
				{/* </Box> */}
			</Paper>
		),
		[className, coverImage, pathway.name, ref]
	);

	if (shouldUseExperiment) {
		return <ABExperiment controlPath={generatePath(PATHWAY_PATH, { pathwaySlug: pathway.slug })}>{ContentInsideLink}</ABExperiment>;
	}

	return (
		<Link className={classes.link} to={generatePath(PATHWAY_PATH, { pathwaySlug: pathway.slug })}>
			{ContentInsideLink}
		</Link>
	);
};

// const cardAspectRatio = 292 / 273;
const useStyles = makeStyles<Theme, { coverImage: string }>((theme) => ({
	root: {
		borderRadius: 32,
		overflow: 'hidden',
		width: '100%',
		boxShadow: `0px 4px 12px 0px ${alpha(theme.palette.common.black, 0.14)}`,
		position: 'relative',
		zIndex: 1,
		minHeight: 192,
		padding: theme.spacing(2),
		paddingTop: 20,
		backgroundImage: ({ coverImage }) => `url(${coverImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		backgroundOrigin: 'center',
		display: 'flex',
		flexDirection: 'column',
		'&:before': {
			backgroundColor: `${theme.palette.primary.main}CC`,
			content: "' '",
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			zIndex: -1,
		},
	},
	coverImage: {
		width: '100%',
		position: 'absolute',
	},
	companiesContainer: {
		// position: 'absolute',
		// top: -20,
		marginTop: 'auto',
		marginBottom: theme.spacing(2),
	},
	// topContent: {
	// 	position: 'relative',
	// 	'&:before': {
	// 		backgroundColor: `${theme.palette.primary.main}CC`,
	// 		content: "' '",
	// 		position: 'absolute',
	// 		top: 0,
	// 		bottom: 0,
	// 		left: 0,
	// 		right: 0,
	// 	},
	// },
	pathwayTitle: {
		fontFamily: `${Font2Bold} !important`,
		color: theme.palette.common.white,
	},
	pathwayPreText: {
		fontSize: 14,
		color: alpha(theme.palette.common.white, 0.72),
	},
	pathwayTitleContainer: {
		// position: 'absolute',
		// top: '50%',
		// transform: 'translateY(-50%)',
		// left: 24,
	},
	bottomContent: {
		padding: 20,
		position: 'relative',
		paddingTop: 36,
	},
	popper: {
		zIndex: 2,
	},
	link: {
		textDecoration: 'none',
		width: '100%',
		display: 'block',
	},
	curatorCompaniesList: {
		color: alpha(theme.palette.common.black, 0.5),
	},
	contentInfo: {
		borderRadius: 8,
		// backgroundColor: theme.palette.grey['200'],
		// padding: theme.spacing(0.5, 1.5),
		color: theme.palette.common.white,
	},
	bookmarkBtn: {
		backgroundColor: theme.palette.grey['200'],
		height: 28,
		borderRadius: 8,
	},
}));

export default PathwayCard;

import { createTheme, darken } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { THEME_PALETTE } from './themeConstants';
// TODO: update material ui to v5 and remove v4

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { others: _otherColors, ...palette } = THEME_PALETTE;

export const Font1ExtraBold = 'ProximaNova-Black';
export const Font1Bold = 'ProximaNova-Bold';
export const Font1Regular = 'ProximaNova-Regular';
export const Font1Semibold = 'ProximaNova-Semibold';

export const fontWeightRegular = 400;
export const fontWeightMedium = 600;
export const fontWeightBold = 700;

export const Font2Regular = 'DomaineText-Regular';
export const Font2Bold = 'DomaineText-Bold';
export const Font2Medium = 'DomaineText-Medium';

export const Font3Regular = 'FoundersGrotesk-Regular';
export const Font3Bold = 'FoundersGrotesk-Bold';
export const Font3Medium = 'FoundersGrotesk-Semibold';

export const SIZE_XS = 0;
export const SIZE_SM = 601;
export const SIZE_MD = 1025;
export const SIZE_LG = 1281;
export const SIZE_XL = 1440;

const breakpoints = createBreakpoints({
	values: {
		xs: SIZE_XS,
		sm: SIZE_SM,
		md: SIZE_MD,
		lg: SIZE_LG,
		xl: SIZE_XL,
	},
});

export default createTheme({
	breakpoints,
	palette,
	typography: {
		fontFamily: [Font1Regular, 'sans-serif'].join(', '),
		fontWeightRegular,
		fontWeightMedium,
		fontWeightBold,
		h1: {
			// fontSize: 48,
			fontSize: 72,
			lineHeight: 1.2,
		},
		h2: {
			// fontSize: 42,
			fontSize: 48,
			lineHeight: 1.2,
		},
		h3: {
			// fontSize: 36,
			fontSize: 32,
			lineHeight: 1.2,
		},
		h4: {
			// fontSize: 28,
			fontSize: 24,
			fontFamily: Font1Regular,
		},
		h5: {
			// fontSize: 24,
			fontSize: 18,
			lineHeight: 1.2,
			fontFamily: Font1Regular,
			fontWeight: fontWeightRegular,
		},
		h6: {
			// fontSize: 20,
			fontSize: 16,
			lineHeight: 1.2,
			fontFamily: Font1Regular,
			fontWeight: fontWeightRegular,
		},
		body1: {
			// fontSize: 18,
			fontSize: 16,
			lineHeight: 1.3,
			fontFamily: Font1Regular,
		},
		body2: {
			// fontSize: 16,
			fontSize: 14,
			lineHeight: 1.5,
			fontFamily: Font1Bold,
		},
		subtitle1: {
			// fontSize: 14,
			fontSize: 24,
			lineHeight: 1.5,
			fontFamily: Font1Regular,
		},
		subtitle2: {
			// fontSize: 14,
			fontSize: 18,
			lineHeight: 1.5,
			fontFamily: Font1Bold,
			fontWeight: fontWeightRegular,
		},
		button: {
			fontSize: 16,
			lineHeight: 1.2,
			fontFamily: Font1Bold,
			textTransform: 'none',
			fontWeight: fontWeightRegular,
		},
		caption: {
			fontSize: 12,
			fontFamily: Font1Regular,
			lineHeight: 1.5,
			textTransform: 'none',
		},
		overline: {
			fontSize: 12,
			lineHeight: 1.5,
			fontFamily: Font1Bold,
			// textTransform: 'uppercase',
		},
	},
	overrides: {
		MuiSvgIcon: {
			fontSizeSmall: {
				fontSize: '1.1rem',
			},
			fontSizeLarge: {
				fontSize: 32,
			},
		},
		MuiAvatar: {
			rounded: {
				borderRadius: 8,
			},
		},
		MuiButton: {
			containedSecondary: {
				color: THEME_PALETTE.primary.main,
				'&:visited': {
					color: THEME_PALETTE.primary.main,
				},
			},
			root: {
				borderRadius: 12,
				letterSpacing: '.05px',
				lineHeight: 1,
				height: 40,
				padding: '14px 38px',
				fontSize: 16,
				[breakpoints.down('sm')]: {
					fontSize: 14,
					padding: '12px 14px',
				},
			},
			outlined: {
				borderRadius: 12,
				letterSpacing: '.05px',
				lineHeight: 1,
				height: 40,
				padding: '14px 38px',
				fontSize: 16,
				[breakpoints.down('sm')]: {
					fontSize: 14,
					padding: '12px 14px',
				},
			},
			textSizeLarge: {
				height: 60,
				paddingLeft: 64,
				paddingRight: 64,
				fontSize: 18,
				borderRadius: 12,
			},
			containedSizeLarge: {
				height: 60,
				paddingLeft: 64,
				paddingRight: 64,
				fontSize: 18,
				borderRadius: 12,
			},
			outlinedSizeLarge: {
				height: 60,
				paddingLeft: 64,
				paddingRight: 64,
				fontSize: 18,
				borderRadius: 12,
			},
			containedSizeSmall: {
				height: 35,
				width: 'fit-content',
			},
			outlinedSizeSmall: {
				height: 35,
				width: 'fit-content',
			},
			contained: {
				backgroundColor: THEME_PALETTE.common.white,
				color: THEME_PALETTE.primary.main,
				'&:hover': {
					backgroundColor: darken(THEME_PALETTE.common.white, 0.1),
				},
				'&:visited': {
					color: THEME_PALETTE.primary.main,
				},
				'&.Mui-disabled': {
					opacity: 0.5,
					backgroundColor: THEME_PALETTE.primary.main,
					color: THEME_PALETTE.primary.contrastText,
				},
			},
			containedPrimary: {
				color: THEME_PALETTE.common.white,
				'&:hover': {
					backgroundColor: THEME_PALETTE.primary.main,
				},
				'&:visited': { color: THEME_PALETTE.common.white },
			},
			outlinedPrimary: {
				border: `2px solid ${palette.primary.main}`,
				'&:hover': {
					border: `2px solid ${palette.primary.main}`,
				},
			},
			label: {
				whiteSpace: 'nowrap',
				overflow: 'hidden',
			},
		},
		MuiPaper: {
			rounded: {
				borderRadius: 16,
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: 8,
				minHeight: 48,
				paddingLeft: 20,
				paddingRight: 20,
				border: `1px solid ${palette.grey.C300}`,
				backgroundColor: THEME_PALETTE.grey[300],
				'&.Mui-error': {
					border: `1px solid ${palette.error.main}`,
				},
			},
			notchedOutline: {
				display: 'none',
			},

			input: {
				// height: 48,
				boxSizing: 'border-box',
				paddingLeft: 0,
				paddingRight: 0,
			},
		},
		MuiBottomNavigationAction: {
			root: {
				'&.Mui-selected': {
					paddingTop: 16,
					'& .MuiBottomNavigationAction-wrapper': {
						color: THEME_PALETTE.common.white,
					},
				},
			},
			iconOnly: {
				paddingTop: 16,
			},
			label: { '&.MuiBottomNavigationAction-iconOnly': { display: 'none' }, '&.Mui-selected': { display: 'none' } },
			wrapper: {
				color: THEME_PALETTE.blue.B600,
			},
		},
		MuiInputLabel: {
			outlined: {
				fontSize: 14,
				color: THEME_PALETTE.common.black,
				'&.MuiInputLabel-shrink': {
					transform: `translate(4px, -21px) scale(1)`,
					fontFamily: Font1Bold,
					letterSpacing: 0.25,
				},
			},
		},
	},
});

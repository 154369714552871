import { request } from 'Resources/AxiosUtils';
import { JSONType, Params } from 'Typings/@types';
import { parseToChapter, parseToComment } from './ChapterParsers';
import type { Chapter, Comment } from './@types';

// eslint-disable-next-line import/prefer-default-export
export class ChapterModel {
	static getChapter = async (microCourseSlug: string, chapterSlug: string, params: Params): Promise<Chapter> => {
		const res = await request<JSONType[]>({
			url: `/microCourses/${microCourseSlug}/chapters/`,
			method: 'GET',
			params: { ...params, filter: { ...params.filter, where: { slug: chapterSlug } } },
		});
		return parseToChapter(res[0] || {});
	};

	static getAllChapters = async (id: string, params?: Params): Promise<Chapter[]> => {
		const res = await request<JSONType[]>({
			url: `microCourses/${id}/chapters`,
			method: 'GET',
			params,
		});
		return res.map(parseToChapter);
	};

	static markCompleted = async (id: string, pathwayId?: string): Promise<JSONType> => {
		const res = await request<JSONType>({
			url: `posts/${id}/mark-complete`,
			method: 'POST',
			data: {
				pathwayId,
			},
		});
		return res;
	};

	static markCompletedBulk = async ({ chapterSlugs, userId }: { chapterSlugs: string[]; userId: string }): Promise<void> => {
		const res = await request<void>({
			url: `posts/mark-complete-bulk`,
			method: 'POST',
			params: { ids: chapterSlugs, userId },
			// eslint-disable-next-line no-console
		}).catch((err) => console.log(err));
		return res;
	};

	static addComment = async (id: string, data: { text: string }): Promise<Comment> => {
		if (!id) {
			throw new Error('No id');
		}
		const res = await request<JSONType>({
			url: `/posts/${id}/comment`,
			method: 'POST',
			data,
		});
		return parseToComment(res);
	};

	static getComments = async (id: string, params: Params): Promise<Comment[]> => {
		const res = await request<JSONType[]>({
			url: `/posts/${id}/comments`,
			method: 'GET',
			params,
		});
		return res.map(parseToComment);
	};

	static likeComment = async ({ commentId, userId }: { commentId: string; userId: string }): Promise<JSONType> => {
		const res = await request<JSONType>({
			url: `/comments/${commentId}/like`,
			method: 'POST',
			data: { userId },
		});
		return res;
	};

	static removeLike = async (likeId: string): Promise<{ count: number }> => {
		const res = await request<{ count: number }>({
			url: `/likes/${likeId}/unlike`,
			method: 'DELETE',
		});
		return res;
	};
}

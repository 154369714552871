import { RootStore } from 'Stores';
import { action, Action, Thunk, thunk } from 'easy-peasy';
import { Elite } from 'Models/Elites/@types';
import EliteModel from 'Models/Elites';

export interface EliteState {
	tagElitePairings: { tagId: string; eliteList: Elite[] }[];
	getEliteForTag: Thunk<EliteState, { tagId: string }, null, RootStore, Promise<Elite[]>>;
	setEliteForTag: Action<EliteState, { tagId: string; eliteList: Elite[] }>;
}

export const EliteStore: EliteState = {
	tagElitePairings: [],
	getEliteForTag: thunk(async (actions, { tagId }) => {
		const eliteList = await EliteModel.getElites({ filter: { where: { tagId }, include: ['user'], limit: 100, order: 'order ASC' } });
		actions.setEliteForTag({ tagId, eliteList });
		return eliteList;
	}),
	setEliteForTag: action((state, { tagId: tagToUpdate, eliteList }) => {
		const tagIndex = state.tagElitePairings.findIndex(({ tagId }) => tagId === tagToUpdate);
		if (tagIndex !== -1) {
			state.tagElitePairings = [...state.tagElitePairings].splice(tagIndex, 1, { tagId: tagToUpdate, eliteList });
			return;
		}
		state.tagElitePairings = [
			...state.tagElitePairings,
			{
				tagId: tagToUpdate,
				eliteList,
			},
		];
	}),
};

export default EliteStore;

/* eslint-disable @typescript-eslint/no-explicit-any */
import DEFAULT_CONFIG from './config.json';

let envConfig: Record<string, any> = {};
const defaultConfig: Record<string, any> = DEFAULT_CONFIG;

if (process.env.REACT_APP_NODE_ENV) {
	const env = process.env.REACT_APP_NODE_ENV.trim();
	// eslint-disable-next-line import/no-dynamic-require, global-require
	envConfig = require(`./config.${env}.json`);
}

const config = {
	get: (key: string): string | boolean | undefined => {
		if (key in envConfig) {
			return envConfig[key];
		}
		return defaultConfig[key];
	},
};

export default config;

/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, Button, makeStyles, CircularProgress, alpha } from '@material-ui/core';
import Typo, { TypoProps } from 'Components/Typo';
import { MLFormContent, RowSchema } from 'react-forms';
import { Formik, Form } from 'formik';
import { JSONType } from 'Typings/@types';

interface ActionFormProps<T = JSONType> {
	initialValues: Partial<T>;
	schema: RowSchema[];
	hideDialog: () => void;
	validation: unknown;
	onSubmit: (values: Partial<T>) => Promise<unknown>;
	postSubmitAction?: (res?: unknown) => void;
	heading: string;
	headingProps?: TypoProps;
	submitButtonText?: string;
}

function ActionForm<T>({ initialValues, hideDialog, schema, validation, onSubmit, postSubmitAction, heading, headingProps = {}, submitButtonText = 'Submit' }: ActionFormProps<T>): JSX.Element {
	const classes = styles();

	const handleSubmit = async (values: Partial<T>) => {
		const res = await onSubmit(values);
		if (res) {
			hideDialog();
			postSubmitAction?.(res);
		}
	};

	return (
		<Box className={classes.container}>
			<Typo weight="bold" {...headingProps}>
				{heading}
			</Typo>
			<Formik<Partial<T>> initialValues={initialValues} onSubmit={(values) => handleSubmit(values)} validationSchema={validation} enableReinitialize>
				{(formikProps) => (
					<Form className={classes.form}>
						<MLFormContent schema={schema} formId="name" formikProps={formikProps} />
						<Box className={classes.btnContainer}>
							<Button
								type="submit"
								size="medium"
								color="primary"
								variant="contained"
								disabled={!formikProps.isValid || formikProps.isSubmitting}
								classes={{ disabled: classes.disabledBtn }}
							>
								{formikProps.isSubmitting ? <CircularProgress /> : submitButtonText}
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
}

export default ActionForm;

const styles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: 23,
	},
	form: {
		width: '100%',
	},
	btnContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: theme.spacing(3, 0, 2, 0),
	},
	disabledBtn: {
		backgroundColor: `${alpha(theme.palette.primary.main, 0.5)} !important`,
		color: `${theme.palette.common.white} !important`,
	},
}));

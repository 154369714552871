import { thunk, Thunk } from 'easy-peasy';
import { MicroCourse } from 'Models/MicroCourse/@types';
import { parseToMicroCourse } from 'Models/MicroCourse/MicroCourseParsers';
import { Pathway } from 'Models/Pathway/@types';
import { parseToPathway } from 'Models/Pathway/PathwayParsers';
import UserModel from 'Models/User';
// eslint-disable-next-line import/no-cycle
import { RootStore } from 'Stores';
// eslint-disable-next-line import/no-cycle
import { PATHWAY_DETAIL_INCLUDE_FILTER } from 'Stores/Pathway';

export interface UserContentState {
	inProgressPathways: Pathway[];
	inProgressMicroCourses: MicroCourse[];
	completedPathways: Pathway[];
	completedMicroCourses: MicroCourse[];
	moreOnChet: MicroCourse[];
	isContentEmpty: boolean;
	loading: boolean;
	fetchContent: Thunk<UserContentState, void, null, RootStore, Promise<void>>;
	fetchRecommendedMicrocourses: Thunk<UserContentState, void, null, RootStore, Promise<void>>;
}

const UserContentStore: UserContentState = {
	isContentEmpty: false,
	moreOnChet: [],
	inProgressMicroCourses: [],
	inProgressPathways: [],
	completedMicroCourses: [],
	completedPathways: [],
	loading: false,
	fetchRecommendedMicrocourses: thunk(async (_, __, { getState }) => {
		const state = getState();
		const res = await UserModel.recommendedMicroCourses();
		state.moreOnChet = res;
	}),
	fetchContent: thunk(async (_, __, { getState }) => {
		const state = getState();
		state.loading = true;
		const res = await UserModel.myContent(undefined, {
			MicroCourse: {
				include: ['tags', 'curators', 'chapters'],
			},
			Pathway: {
				...PATHWAY_DETAIL_INCLUDE_FILTER,
			},
		});
		const microcourses = (res.MicroCourse || []).map((m) => parseToMicroCourse(m));
		const pathways = (res.Pathway || []).map((p) => parseToPathway(p));
		state.completedMicroCourses = microcourses.filter((m) => !!m.isMarkedComplete);
		state.inProgressMicroCourses = microcourses.filter((m) => !!m.isInProgress);
		state.completedPathways = pathways.filter((p) => !!p.isMarkedComplete);
		state.inProgressPathways = pathways.filter((p) => !!p.isInProgress);

		if (!microcourses.length && !pathways.length) state.isContentEmpty = true;
		else state.isContentEmpty = false;
		state.loading = false;
	}),
};

export default UserContentStore;

// eslint-disable-next-line import/no-cycle
import { parseToMicroCourse } from 'Models/MicroCourse/MicroCourseParsers';
// eslint-disable-next-line import/no-cycle
import { parseToPost } from 'Models/Post/PostParsers';
// eslint-disable-next-line import/no-cycle
import { parseToUser } from 'Models/User/UserParsers';
import { JSONType } from 'Typings/@types';
import type { Comment, Chapter } from './@types';

export const parseToChapter = (obj: JSONType): Chapter => {
	const { _isCommented: isCommented } = obj;
	return {
		...parseToPost(obj),
		...(obj?.microCourse && { microCourse: parseToMicroCourse(obj?.microCourse), isMarkedComplete: !!obj?.isMarkedComplete }),
		isCommented,
	} as Chapter;
};

export const parseToComment = (obj: JSONType): Comment => {
	return { ...obj, isLiked: !!obj?.isLiked, ...(obj?.user ? { user: parseToUser(obj?.user) } : {}) } as unknown as Comment;
};

import { Box, CircularProgress, TextField, TextFieldProps } from '@material-ui/core';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { Post } from 'Models/Post/@types';
import PostModel from 'Models/Post';
import React, { ChangeEvent, useCallback } from 'react';
import { IFieldProps } from 'react-forms';
import useAsyncTask from 'Hooks/useAsyncTask';
import utils from 'Utils';
import { FormikProps } from 'formik';

interface LinkInputFieldProps {
	name: string;
	label: string;
	fields?: string[]; // name of the fields to be set
}
interface LinkInputProps extends IFieldProps {
	fieldProps?: TextFieldProps & LinkInputFieldProps;
}
const LinkInput: React.FC<LinkInputProps> = (props) => {
	const { fieldProps = {} as LinkInputFieldProps, formikProps = {} as FormikProps<unknown> } = props;
	const { name, fields, ...textFieldProps } = fieldProps;
	const value = get(formikProps?.values, name);
	const fetchUrlData = useAsyncTask(async (url: string) => {
		const res = await PostModel.getPostLinkPreview({ url });
		return res;
	});

	const fetch = useCallback(
		debounce(async (url: string) => {
			if (utils.validateUrl(url)) {
				try {
					const post = await fetchUrlData.run(url);
					if (post) {
						fields?.forEach((field) => {
							if (post[field as keyof Post]) {
								formikProps?.setFieldValue(field, post[field as keyof Post]);
							}
						});
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				}
			}
		}, 500),
		[]
	);

	const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		formikProps.setFieldValue(name, event.currentTarget.value);
		fetch(event.currentTarget.value);
	};

	return (
		<Box>
			<TextField {...textFieldProps} value={value} onChange={handleChange} />
			<Box mt={2} display="flex" justifyContent="center">
				{fetchUrlData.status === 'PROCESSING' && <CircularProgress />}
			</Box>
		</Box>
	);
};

export default LinkInput;

// eslint-disable-next-line import/no-cycle
import { parseToUser } from 'Models/User/UserParsers';
import { JSONType } from 'Typings/@types';
import { ContentDuration, Post } from './@types';

export const parseToPost = (obj: JSONType): Post => {
	if (!obj) return {} as Post;
	const { _assets: assets } = obj ?? {};
	return {
		...obj,
		...(obj?.contentDuration ? { contentDuration: parseToContentDuration(obj?.contentDuration) } : {}),
		...(obj?.user ? { user: parseToUser(obj?.user) } : {}),
		assets,
		...(obj.viewersInfo ? { viewersInfo: { ...obj.viewersInfo, userInstances: obj.viewersInfo.userInstances?.map(parseToUser) ?? [] } } : {}),
	} as Post;
};
export const parseToContentDuration = (min: number): ContentDuration => {
	const hours = Math.floor(min / 60);
	// eslint-disable-next-line no-bitwise
	const minutes = ~~(min % 60); // ~~ is the shorter sometimes faster method of Math.floor()
	const sec = Math.round((min % 1) * 60);
	return { hours, minutes, sec };
};

import React, { FC } from 'react';
import { Divider, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

interface CustomDividerProps {
	height?: number | string;
	color?: string;
	className?: string;
}

const CustomDivider: FC<CustomDividerProps> = ({ height, color, className }) => {
	const classes = getClasses({ height, color });
	return <Divider className={clsx(className, classes.root)} />;
};

export default CustomDivider;

const getClasses = makeStyles<Theme, CustomDividerProps>(() => ({
	root: {
		height: ({ height }) => height || 1,
		backgroundColor: ({ color }) => color || 'rgba(0, 0, 0, 0.12)',
	},
}));

import { useState } from 'react';

type TStatus = 'IDEAL' | 'PROCESSING' | 'ERROR' | 'SUCCESS';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useAsyncTask<T extends any[], R = any>(task: (...args: T) => Promise<R>): { run: (...arg: T) => Promise<R>; status: TStatus; message: string; reset: () => void } {
	const [status, setStatus] = useState<TStatus>('IDEAL');
	const [message, setMessage] = useState('');
	const run = async (...arg: T) => {
		setStatus('PROCESSING');
		try {
			const resp: R = await task(...arg);
			setStatus('SUCCESS');
			return resp;
		} catch (error) {
			const message = error?.response?.data?.error?.message || error.message;
			setMessage(message);
			setStatus('ERROR');
			throw error;
		}
	};
	const reset = () => {
		setMessage('');
		setStatus('IDEAL');
	};

	return {
		run,
		status,
		message,
		reset,
	};
}

export default useAsyncTask;

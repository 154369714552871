import { Analytics } from 'analytics';
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'Stores';

// eslint-disable-next-line import/prefer-default-export
export function useEventListener(): void {
	const { setIsCordova, setDeviceType, updateCompletedCount, addMicroCourseToOngoing } = useStoreActions(
		({ App: { setIsCordova, setDeviceType }, MicroCourse: { updateCompletedCount }, Auth: { addMicroCourseToOngoing } }) => ({
			setIsCordova,
			setDeviceType,
			updateCompletedCount,
			addMicroCourseToOngoing,
		})
	);
	const { microCourse } = useStoreState(({ MicroCourse: { microCourse } }) => ({ microCourse }));
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		document.addEventListener('nativeAppLoaded', (_e: unknown) => {
			setIsCordova(true);
			Analytics.setIsCordova(true);
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		document.addEventListener('deviceType', (e: any) => {
			setDeviceType(e?.detail?.deviceType);
			Analytics.setDeviceType(e?.detail?.deviceType);
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		document.addEventListener('markCompletedSuccess', (event: any) => {
			updateCompletedCount(event?.detail?.chapterId || '');
			if (microCourse) addMicroCourseToOngoing(microCourse);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

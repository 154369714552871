import React, { FC } from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { Font1Bold } from 'Theme';
import { THEME_PALETTE } from 'Theme/themeConstants';
import { Link } from 'react-router-dom';
import { REGISTER_PATH } from 'Routes/RouteConstants';

const FinalCTA: FC = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.innerContainer}>
				<Typography align="center" className={classes.title}>
					Learn from experts you can trust
				</Typography>
				<Typography align="center" className={classes.subtitle} variant="h2">
					Today’s best educational content handpicked by top professionals &amp; available for free — forever.
				</Typography>
				<Box className={classes.ctaButtonContainer}>
					<Button component={Link} to={REGISTER_PATH} size="large" className={classes.joinButton} color="primary" variant="contained">
						Join for free
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default FinalCTA;
const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: THEME_PALETTE.grey[300],
		padding: theme.spacing(9, 3, 9, 3),
		paddingBottom: 40,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		boxSizing: 'border-box',
		overflowX: 'hidden',
		[theme.breakpoints.up('md')]: {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	innerContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.up('xl')]: {
			flexGrow: 1,
			maxWidth: 1440,
		},
	},
	title: {
		fontFamily: Font1Bold,
		lineHeight: 1.2,
		paddingBottom: 16,
		fontSize: 24,
		width: '100%',
		flex: 1,
		textAlign: 'center',
		[theme.breakpoints.up('sm')]: {
			fontSize: 32,
		},
	},
	subtitle: {
		paddingBottom: 22,
		fontSize: 16,
		[theme.breakpoints.up('sm')]: {
			fontSize: 20,
		},
	},
	ctaButtonContainer: {
		display: 'flex',
		marginTop: 22,
		justifyContent: 'flex-start',
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'center',
		},
	},
	joinButton: {
		backgroundColor: THEME_PALETTE.blue[200],
		'&:visited': {
			color: theme.palette.common.white,
		},
	},
}));

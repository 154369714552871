import { Avatar, AvatarProps } from '@material-ui/core';
import clsx from 'clsx';
import { HeaderMenuItem } from 'Components/HeaderMenu';
import LinkButton from 'Components/LinkButton';
import SvgIcon from 'Components/SvgIcon';
import Config from 'config';
import PictureModel from 'Models/Picture';
import { User } from 'Models/User/@types';
import React from 'react';
import { LOGIN_PATH, REGISTER_PATH, CURATOR_STUDIO_PATH, EXPLORE_HOME_PATH, USER_HOME_PROFILE_PATH, USER_HOME_PATH, RESET_PASSWORD_PATH } from 'Routes/RouteConstants';
import ICONS from 'Constants/icons.json';
import { UilLayerGroup, UilApps, UilUserCircle } from '@iconscout/react-unicons';
import { generatePath, Link } from 'react-router-dom';

export const resolveUserIcon = (classes: Record<string, string>, pathname: string, isMobile?: boolean, user?: User | null): React.ReactElement | Array<React.ReactElement> => {
	const redirectTo = !NO_REDIRECT_PATHS.includes(pathname) ? pathname : Config.get('POST_LOGIN_REDIRECT');
	const UserAvatar = user?.profileImage?.url ? (
		React.createElement(Avatar, {
			className: clsx(classes.avatar, { [classes.avatarWithBorder]: !!user?.profileImage?.url }),
			alt: 'user profile',
			src: PictureModel.getTransformedUrl(user.profileImage.url, 64, 64, 'face'),
			imgProps: { height: 32, width: 32 },
		} as AvatarProps)
	) : (
		<UilUserCircle size={28} />
	);
	if (user && user?.id) {
		if (!isMobile) return UserAvatar;
		return <Link to={generatePath(USER_HOME_PATH)}>{UserAvatar}</Link>;
	}

	return [
		<LinkButton path={`${LOGIN_PATH}${redirectTo ? `?redirectTo=${redirectTo}` : ''}`} size="medium" className={clsx(classes.authLinkButton)} variant="text">
			Sign in
		</LinkButton>,
		<LinkButton path={`${REGISTER_PATH}${redirectTo ? `?redirectTo=${redirectTo}` : ''}`} size="medium" className={clsx(classes.authLinkButton)}>
			Sign up
		</LinkButton>,
	];
};
export const NO_REDIRECT_PATHS = [LOGIN_PATH, REGISTER_PATH, RESET_PASSWORD_PATH, '/'];
export const NO_HEADER_PATHS: string[] = [];

export const getMenuItems = (user: User, handleLogout: () => void): HeaderMenuItem[] => [
	{ label: 'My Chet', icon: <UilApps size={20} />, link: EXPLORE_HOME_PATH, analyticsEvent: 'MY_CHET_CLICKED' },
	{ label: 'Profile', icon: <SvgIcon icon={ICONS.user} variant="light" size={20} />, link: USER_HOME_PROFILE_PATH, analyticsEvent: 'PROFILE_CLICKED' },
	...(user?.roles?.find((role) => role.name === 'CURATOR')
		? [{ label: 'Curator Studio', icon: <SvgIcon icon={ICONS.curation} variant="light" size={20} />, link: CURATOR_STUDIO_PATH, analyticsEvent: 'CURATOR_STUDIO_PRESSED' } as HeaderMenuItem]
		: [{ label: 'Become a Curator', icon: <UilLayerGroup size={20} />, link: 'https://chetnetwork.com/curators', externalLink: true, analyticsEvent: 'BECOME_CURATOR_PRESSED' } as HeaderMenuItem]),
	{ label: 'Log out', icon: <SvgIcon icon={ICONS.logout} variant="light" size={20} />, onClick: () => handleLogout(), analyticsEvent: 'USER_LOGOUT_PRESSED' },
];

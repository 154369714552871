/* eslint-disable camelcase */
import { parseToMicroCourse } from 'Models/MicroCourse/MicroCourseParsers';
import { parseToPathway } from 'Models/Pathway/PathwayParsers';
import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
// eslint-disable-next-line import/no-cycle
import AppModel from 'Models/App';
import { Bucket, SearchRes } from 'Models/App/@types';
// eslint-disable-next-line import/no-cycle
import { RootStore } from 'Stores';
import { parseToChapter } from 'Models/Chapter/ChapterParsers';
import { parseToPost } from 'Models/Post/PostParsers';
import { Pathway } from 'Models/Pathway/@types';
import { MicroCourse } from 'Models/MicroCourse/@types';
import { Post } from 'Models/Post/@types';
import { Chapter } from 'Models/Chapter/@types';

export const RESULTS_PER_PAGE = 21;
export interface SearchState {
	isLoadingSearch: boolean;
	setLoadingSearch: Action<SearchState, boolean>;

	searchRes?: SearchRes;
	setSearchRes: Action<SearchState, SearchRes | undefined>;
	fetchSearchRes: Thunk<SearchState, TQuery, null, RootStore>;

	filters?: TSearchFilter;
	setFilters: Action<SearchState, TSearchFilter | undefined>;
	getFilters: Thunk<SearchState, undefined, null, RootStore>;

	searchQuery?: TQuery;
	onSearchChange: ThunkOn<SearchState, Record<string, never>, RootStore>;
	setSearchQuery: Action<SearchState, TQuery | undefined>;
}

const SearchStore: SearchState = {
	isLoadingSearch: false,
	filters: undefined,

	setLoadingSearch: action((state, payload) => {
		state.isLoadingSearch = payload;
	}),

	searchRes: undefined,
	setSearchRes: action((state, payload) => {
		state.searchRes = payload;
	}),
	fetchSearchRes: thunk(async (actions, { term, page, industry, contentType }) => {
		if (term) {
			actions.setLoadingSearch(true);
			const res = await AppModel.search({
				term,
				query: {
					skip: ((page ?? 1) - 1) * RESULTS_PER_PAGE,
					limit: RESULTS_PER_PAGE,
					where: {
						...(industry ? { 'tags.name': typeof industry === 'string' ? industry : industry } : {}),
						...(contentType ? { type: typeof contentType === 'string' ? contentType : contentType } : {}),
					},
				},
			});
			if (res) {
				res.results = res.results.map((resItem) => {
					switch (resItem.hit.doc_type) {
						case 'Pathway':
							return { hit: parseToPathway(resItem.hit) as Pathway & { doc_type: 'Pathway' } };
						case 'MicroCourse':
							return { hit: parseToMicroCourse(resItem.hit) as MicroCourse & { doc_type: 'MicroCourse' } };
						default:
						case 'Post':
							if ((resItem.hit as Post).postType === 'chapter') return { hit: parseToChapter(resItem.hit) as Chapter & { doc_type: 'Chapter' } };
							return { hit: parseToPost(resItem.hit) as Post & { doc_type: 'Post' } };
					}
				});
				actions.setSearchRes(res);
			}
			actions.setLoadingSearch(false);
		}
	}),

	getFilters: thunk(async (actions) => {
		const res = await AppModel.search({ term: '' });
		actions.setFilters({
			industry: { doc_count: res.aggregations.webTags.doc_count, buckets: res.aggregations.webTags?.name.buckets },
			contentType: { doc_count: res.aggregations.post.doc_count, buckets: res.aggregations.post.type.buckets },
		});
	}),

	setSearchQuery: action((state, payload) => {
		state.searchQuery = payload;
	}),

	onSearchChange: thunkOn(
		(actions) => actions.setSearchQuery,
		async (actions, { payload }) => {
			if (payload) actions.fetchSearchRes(payload);
			else actions.setSearchRes(undefined);
		}
	),
	setFilters: action((state, payload) => {
		state.filters = payload;
	}),
};

export default SearchStore;

export interface TSearchFilter {
	industry: { doc_count: number; buckets: Bucket[] };
	contentType: { doc_count: number; buckets: Bucket[] };
}

export interface TQuery {
	term?: string;
	page?: number;
	industry?: string | string[];
	contentType?: string | string[];
}

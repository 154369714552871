/* eslint-disable camelcase */
import { action, Action, thunk, Thunk } from 'easy-peasy';
import { Tag } from 'Models/Tag/@types';
// eslint-disable-next-line import/no-cycle
import { RootStore } from 'Stores';
// import { Params } from 'Typings/@types';
import { ListDocument } from 'Models/ExploreHome/@types';
// import ExploreHomeModel from 'Models/ExploreHome';
import AppModel from 'Models/App';
import { parseToChapter } from 'Models/Chapter/ChapterParsers';
import { MicroCourse } from 'Models/MicroCourse/@types';
import { parseToMicroCourse } from 'Models/MicroCourse/MicroCourseParsers';
import { Pathway } from 'Models/Pathway/@types';
import { parseToPathway } from 'Models/Pathway/PathwayParsers';
import { Post } from 'Models/Post/@types';
import { parseToPost } from 'Models/Post/PostParsers';
import { Chapter } from 'Models/Chapter/@types';
import { Bucket } from 'Models/App/@types';
// import Chapter from 'Stores/Chapter';

interface TagFilters {
	contentType: { doc_count: number; buckets: Bucket[] };
}

export interface TagState {
	tag?: Tag;
	totalTagEntities: number;

	filters?: TagFilters;
	setFilters: Action<TagState, TagFilters>;
	getFilters: Thunk<TagState, undefined, null, RootStore>;

	entities: ListDocument[];
	setTag: Action<TagState, Tag | undefined>;
	getTagEntities: Thunk<TagState, { limit: number; skip: number; tagSlug: string; contentType?: string | string[] }, null, RootStore, Promise<ListDocument[]>>;
	setTagEntities: Action<TagState, ListDocument[]>;
	setTotalTagEntities: Action<TagState, number>;
}

const TagStore: TagState = {
	tag: undefined,
	entities: [],
	totalTagEntities: 0,
	filters: undefined,
	getTagEntities: thunk(async (actions, { skip, tagSlug, limit, contentType }) => {
		const res = await AppModel.search({
			term: '',
			query: {
				skip,
				limit,
				where: {
					...(tagSlug ? { 'tags.name': tagSlug } : {}),
					...(contentType ? { type: contentType } : {}),
				},
			},
		});
		if (res) {
			const listDocuments: ListDocument[] = res.results.map((resItem) => {
				switch (resItem.hit.doc_type) {
					case 'Pathway':
						return {
							subject: parseToPathway(resItem.hit) as Pathway & { doc_type: 'Pathway' },
							id: Date.now().toString() + Math.random().toString(),
							subjectId: resItem.hit.id,
							subjectType: 'Pathway',
							order: 0,
						};
					case 'MicroCourse':
						return {
							subject: parseToMicroCourse(resItem.hit) as MicroCourse & { doc_type: 'MicroCourse' },
							id: Date.now().toString() + Math.random().toString(),
							subjectId: resItem.hit.id,
							subjectType: 'MicroCourse',
							order: 0,
						};
					default:
					case 'Post':
						if ((resItem.hit as Post).postType === 'chapter')
							return {
								subject: parseToChapter(resItem.hit) as Chapter & { doc_type: 'Chapter' },
								id: Date.now().toString() + Math.random().toString(),
								subjectId: resItem.hit.id,
								subjectType: 'Post',
								order: 0,
							};
						return {
							subject: parseToPost(resItem.hit) as Post & { doc_type: 'Post' },
							id: Date.now().toString() + Math.random().toString(),
							subjectId: resItem.hit.id,
							subjectType: 'Post',
							order: 0,
						};
				}
			});
			actions.setTagEntities(listDocuments);
			actions.setTotalTagEntities(res.total);
			return listDocuments;
		}
		return [];
	}),
	getFilters: thunk(async (actions) => {
		const res = await AppModel.search({ term: '' });
		actions.setFilters({
			contentType: { doc_count: res.aggregations.post.doc_count, buckets: res.aggregations.post.type.buckets },
		});
	}),

	setTagEntities: action((state, payload) => {
		state.entities = payload;
	}),
	setTag: action((state, payload) => {
		state.tag = payload;
	}),
	setTotalTagEntities: action((state, payload) => {
		state.totalTagEntities = payload;
	}),
	setFilters: action((state, payload) => {
		state.filters = payload;
	}),
};

export default TagStore;

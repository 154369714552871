import React, { FC } from 'react';
import { alpha, Avatar, AvatarProps, Box, makeStyles } from '@material-ui/core';
import { HEADER_MOBILE_HEIGHT } from 'Features/Header/Constants';
import { NavLink } from 'react-router-dom';
import { EXPLORE_HOME_PATH, SEARCH_PATH, EXPLORE_LIBRARY_PATH, LOGIN_PATH, USER_HOME_PATH, EXPLORE_FEED_PATH } from 'Routes/RouteConstants';
import { UilGlobe, UilEstate, UilBookmark, UilSearch, UilUserCircle } from '@iconscout/react-unicons';
import { useStoreState } from 'Stores';
import PictureModel from 'Models/Picture';
import clsx from 'clsx';
import { THEME_PALETTE } from 'Theme/themeConstants';

interface BottomBarProps {}

const BottomBar: FC<BottomBarProps> = () => {
	const classes = styles();
	const { user } = useStoreState(({ Auth: { user } }) => ({ user }));
	const UserAvatar = user?.profileImage?.url ? (
		React.createElement(Avatar, {
			className: clsx(classes.avatar, { [classes.avatarWithBorder]: !!user?.profileImage?.url }),
			alt: 'user profile',
			src: PictureModel.getTransformedUrl(user.profileImage.url, 64, 64, 'face'),
			imgProps: { height: 32, width: 32 },
		} as AvatarProps)
	) : (
		<UilUserCircle size={28} />
	);
	return (
		<Box className={classes.root}>
			<NavLink className={classes.link} activeClassName={classes.activeLink} to={EXPLORE_FEED_PATH}>
				{/* <UilEstate size={28} /> */}
				{React.createElement(UilEstate, { size: 28 })}
			</NavLink>
			<NavLink className={classes.link} activeClassName={classes.activeLink} to={EXPLORE_HOME_PATH}>
				<UilGlobe size={28} />
			</NavLink>
			<NavLink className={classes.link} activeClassName={classes.activeLink} to={SEARCH_PATH}>
				<UilSearch size={28} />
			</NavLink>
			<NavLink className={classes.link} activeClassName={classes.activeLink} to={user && user.id ? EXPLORE_LIBRARY_PATH : LOGIN_PATH}>
				<UilBookmark size={28} />
			</NavLink>
			<NavLink className={classes.link} activeClassName={classes.activeLink} to={user && user.id ? USER_HOME_PATH : LOGIN_PATH}>
				{UserAvatar}
			</NavLink>
		</Box>
	);
};

export default BottomBar;

const styles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		zIndex: 50,
		bottom: 0,
		width: '100%',
		borderTopLeftRadius: theme.spacing(2),
		borderTopRightRadius: theme.spacing(2),
		minHeight: HEADER_MOBILE_HEIGHT,
		backgroundColor: theme.palette.primary.main,
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
		boxShadow: `${alpha(theme.palette.common.black, 0.113)} 20px 0 50px, ${alpha(theme.palette.common.black, 0.113)} -20px 0 50px`,
		display: 'flex',
		alignItems: 'center',
		paddingTop: 8,
		justifyContent: 'space-around',
		color: theme.palette.common.white,
		'& > *': {
			// flex: 1,
			display: 'inline-flex',
			justifyContent: 'center',
		},
	},
	paper: {
		width: '100%',
	},
	link: {
		// display: 'inline-flex',
		// justifyContent: 'center',
		// width: '100%',
		textDecoration: 'none',
		// color: theme.palette.text.primary,
	},
	activeLink: {
		color: theme.palette.secondary.main,
		'&:visited': {
			color: theme.palette.secondary.main,
		},
		'& > div': {
			borderColor: theme.palette.secondary.main,
		},
	},
	avatar: {
		borderRadius: '50%',
		boxSizing: 'border-box',
		height: 32,
		width: 32,
		cursor: 'pointer',
	},
	avatarWithBorder: {
		border: `2px solid ${THEME_PALETTE.blue.B600}`,
	},
}));

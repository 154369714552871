import React, { FC, useMemo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { User } from 'Models/User/@types';
import UserImageWithLogoSquare from 'Components/UserImageWithLogoSquare';

interface UsersProps {
	users: User[];
}

const Users: FC<UsersProps> = ({ users }) => {
	const classes = styles();
	const { width } = useMemo(() => {
		// eslint-disable-next-line no-nested-ternary
		const cols = users.length > 1 && users.length < 5 ? 2 : users.length < 9 && users.length > 6 ? 4 : 3;
		const width = users.length > 1 ? cols * 34 : '100%';
		return { width };
	}, [users]);
	return (
		<Box display="flex" width={width} className={classes.root} justifyContent="flex-start">
			{users.map((user) => {
				return <UserImageWithLogoSquare key={user.id} companyLogo={user.companyLogo?.url} profileImage={user.profileImage?.url} variant={users.length > 1 ? 'small' : 'large'} />;
			})}
		</Box>
	);
};

export default Users;

const styles = makeStyles(() => ({
	root: { flexWrap: 'wrap' },
}));

/* 	eslint-disable @typescript-eslint/no-explicit-any */
import { isString, map, filter, indexOf } from 'lodash';
import { Dayjs } from 'dayjs';
import { JSONType } from 'Typings/@types';

const helpers = {
	toggleItemFromList: <T = any>(list: T[] = [], item: T, key = 'id', comparisonFunction?: (currentItem: T, item: T) => boolean): T[] => {
		const updatedList: T[] = [...list];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any,
		const index = list.findIndex((i) => (comparisonFunction ? comparisonFunction(i, item) : ((i as any)[key] as any) === ((item as any)[key] as any)));
		index === -1 ? updatedList.push(item) : updatedList.splice(index, 1);
		return updatedList;
	},
	getDurationInString(duration: number): string {
		const h = Math.floor(duration / 60);
		const m = Math.floor(duration % 60);

		if (!h && !m) return '0M';
		if (!h) return `${m}M`;
		if (!m) return `${h}H`;
		return `${h}H ${m}M`;
	},
	getDomainNameFromUrl: (url: string): string => {
		if (!url) return '';
		const domain = new URL(url);
		return domain.hostname.replace('www.', '').split('.').slice(0, -1).join('.'); // remove www. & split using ".", then remove last element (optimistically domain extension .io, .com, .co, etc. Will have issues for .co.in, .gov.in and such similar extensions)
	},
	getDummyImageUrl: (w = 800, h = 800): string => `https://source.unsplash.com/random/${w}x${h}`,
	bytesToSize: (bytes: number): string => {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes === 0) return '0 Byte';
		const i = Math.floor(Math.log(bytes) / Math.log(1024));
		return `${(bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`;
	},
	copyToClipboard: (value: string): void => {
		if (window.navigator && window.navigator.clipboard && window.navigator.clipboard.writeText) window.navigator?.clipboard?.writeText(value);
		else {
			const el = document.createElement('textarea');
			el.value = value;
			el.setAttribute('readonly', '');
			el.style.position = 'absolute';
			el.style.left = '-9999px';
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
		}
	},
	isListSimilar: (list1: Array<any>, list2: Array<any>): boolean => {
		if (list1.length !== list2.length) return false;
		// eslint-disable-next-line consistent-return
		list1.forEach((elem): boolean | undefined => {
			const index = list2.findIndex((i) => i.id === elem.id);
			if (index === -1) return false;
		});
		return true;
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	updateItemList: <T = any>(list: Array<T>, item: T, action: 'ADD' | 'DELETE' | 'UPDATE' | 'PUT', key = 'id', comparisonFunction?: (a: T) => boolean): T[] => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const compare = comparisonFunction || ((listItem) => ((item as any)[key] as any) === ((listItem as any)[key] as any));
		const newList = list.slice();
		let itemIndex;
		if (action === 'UPDATE') {
			itemIndex = newList.findIndex(compare);
			if (itemIndex !== -1) newList.splice(itemIndex, 1, item);
			return newList;
		}
		if (action === 'ADD') {
			newList.unshift(item);
			return newList;
		}
		if (action === 'DELETE') {
			return newList.filter(compare);
		}
		if (action === 'PUT') {
			itemIndex = newList.findIndex(compare);
			if (itemIndex !== -1) newList.splice(itemIndex, 1, item);
			else {
				newList.push(item);
			}
			return newList;
		}
		return newList;
	},
	findValues: (superSet: Array<Record<string, unknown>>, subSet: string | Array<string>, findKey = 'value', mapKey = 'name', isReturnItem = false): unknown[] => {
		// findKey = findKey || 'value';
		// mapKey = mapKey || 'name';
		let set = subSet;
		if (isString(subSet)) set = [subSet];
		const filteredValues = filter(superSet, (item: any) => {
			return indexOf(set, item[findKey]) !== -1;
		});
		if (isReturnItem) return filteredValues;
		return map(filteredValues, mapKey);
	},
	hexToRgb: (hex: string, opacity = '0.1'): string => {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		if (result) return `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)} ,${parseInt(result[3], 16)},${opacity})`;
		// r: parseInt(result[1], 16),
		// g: parseInt(result[2], 16),
		// b: parseInt(result[3], 16)
		return '';
	},
	getDateDifference: (date1: Dayjs, date2: Dayjs): { years: number; months: number; weeks: number; days: number; hours: number; mins: number } => {
		const years = date1.diff(date2, 'y');
		const months = date1.diff(date2, 'M');
		const weeks = date1.diff(date2, 'w');
		const days = date1.diff(date2, 'd');
		const hours = date1.diff(date2, 'h');
		const mins = date1.diff(date2, 'm');
		return { years, months, weeks, days, hours, mins };
	},
	hasObjectWithKey: (key: string, objectArray: JSONType[], keyName = 'id'): boolean => {
		const index = objectArray.findIndex((obj) => obj[keyName] === key);
		return index !== -1;
	},

	isElementInViewport: (elem: HTMLElement | null): boolean => {
		if (!elem) return false;
		const bounding = elem.getBoundingClientRect();
		return (
			// bounding.top >= 0 &&
			bounding.left >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	},
};
export default helpers;

import { MuiThemeProvider } from '@material-ui/core/styles';
import ScrollToTop from 'Components/ScrollToTop';
import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// eslint-disable-next-line import/no-cycle
import RootLayout from './Screens/RootLayout';
import Theme from './Theme';

export const history = createBrowserHistory();

const App: React.FC = () => {
	return (
		<Router history={history}>
			<ScrollToTop />
			<MuiThemeProvider theme={Theme}>
				<RootLayout />
			</MuiThemeProvider>
		</Router>
	);
};

export default App;

import React, { FC } from 'react';
import { alpha, InputAdornment, makeStyles } from '@material-ui/core';
import { MLFormContent } from 'react-forms';
import { Form, Formik } from 'formik';
import SearchIcon from '@material-ui/icons/Search';
import { generatePath, useHistory } from 'react-router';
import { SEARCH_PATH } from 'Routes/RouteConstants';
import { THEME_PALETTE } from 'Theme/themeConstants';
import queryString from 'query-string';
import useResponsive from 'Hooks/useResponsive';
import { useStoreState } from 'Stores';

interface SearchFormProps {}

const SearchForm: FC<SearchFormProps> = () => {
	const classes = styles();
	const history = useHistory();
	const { term } = queryString.parse(history.location.search) as { term?: string };
	const { isMobile } = useResponsive();
	const { searchQuery = {} } = useStoreState(({ Search: { searchQuery } }) => ({ searchQuery }));
	return (
		<Formik
			initialValues={{ searchTerm: term ?? '' }}
			enableReinitialize
			onSubmit={({ searchTerm }) => {
				localStorage.removeItem('visitedFromSearch');
				history.push(
					`${generatePath(SEARCH_PATH, {})}?${queryString.stringify({
						term: searchTerm,
						contentType: searchQuery.contentType,
						industry: searchQuery.industry,
					})}`
				);
			}}
		>
			{(formikProps) => (
				<Form>
					<MLFormContent
						formId="search"
						settings={{ verticalSpacing: 0.01 }} // 0 fails the truthy test. Default 10 gets applied.
						schema={[
							{
								type: 'text',
								valueKey: 'searchTerm',
								fieldProps: {
									variant: 'filled',
									placeholder: 'Search Chet',
									InputProps: {
										className: classes.searchField,
										startAdornment: !isMobile ? (
											<InputAdornment position="start">
												<SearchIcon htmlColor={THEME_PALETTE.grey[600]} />
											</InputAdornment>
										) : null,
									},
								},
							},
						]}
						formikProps={formikProps}
					/>
				</Form>
			)}
		</Formik>
	);
};

export default SearchForm;

const styles = makeStyles((theme) => ({
	searchField: {
		// borderRadius: 96,
		borderRadius: 8,
		border: `1px solid ${theme.palette.grey[500]}`,
		height: 40,
		padding: 0,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(2),
		},
		backgroundColor: theme.palette.grey[300],
		'& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
			marginTop: 0,
			paddingLeft: theme.spacing(2),
		},
		'& input': {
			padding: 0,
			'&::placeholder': {
				opacity: 1,
				color: theme.palette.grey[600],
			},
		},
		'&.MuiFilledInput-underline': {
			'&::before': { display: 'none', '&:hover': { display: 'none' } },
			'&::after': { display: 'none', '&:hover': { display: 'none' } },
		},
	},
	searchButton: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		borderTopRightRadius: 8,
		borderBottomRightRadius: 8,
		height: 40,
		width: 52,
		minWidth: 'unset',
		backgroundColor: THEME_PALETTE.blue.A100,
	},
	disabledSearchButton: {
		backgroundColor: alpha(THEME_PALETTE.blue.A100, 0.5),
	},
}));

import React, { FC, Suspense, useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Box, createStyles, LinearProgress, makeStyles } from '@material-ui/core';
import Footer from 'Features/Footer';
import FinalCTA from 'Screens/Landing/FinalCTA';
import PrivateRoute from 'Components/PrivateRoute';
import { emitter, segmentHelper } from '@marvelapp/react-ab-test';
import { EXPLORE_VARIANT, LANDING_EXPERIMENT, LOGIN_VARIANT } from 'Features/ABExperiment/Landing/Constants'; // Choose the right CONTROL_VARIANT & LOGIN_VARIANT to import
import SecondaryFooter from 'Features/SecondaryFooter';
import useResponsive from 'Hooks/useResponsive';
import BottomBar from 'Features/BottomBar';
import lazyImport from '../lazy-import';
import Page404 from '../Screens/Page404';
import {
	LOGIN_PATH,
	PATHWAY_PATH,
	REGISTER_PATH,
	EXPLORE_PATH,
	CURATOR_PROFILE_PATH,
	POST_PATH,
	// COMMENTS_PATH,
	CHAPTER_PATH,
	MICRO_COURSE_PATH,
	RESET_PASSWORD_PATH,
	USER_HOME_PATH,
	FEED_PATH,
	CURATOR_STUDIO_PATH,
	// FAQ_PATH,
	SEARCH_PATH,
	COMPONENTS_TEST_PATH,
	TAG_PATH,
	LANDING_PATH,
	ABOUT_PATH,
	CURATORS_INFO_PATH,
	APPLY_FOR_CURATOR_PATH,
	CONTACT_US_PATH,
	ELITE_100_PATH,
	MAIN_CONTENT_WIDTH,
	INDIVIDUAL_JOB_LISTING_PATH,
	INDIVIDUAL_COMPANY_PATH,
} from './RouteConstants';
// eslint-disable-next-line import/no-cycle
import { getHeader } from './utils';
import CuratorStudioNavigation from './CuratorStudioNavigation';

emitter.defineVariants(LANDING_EXPERIMENT, [EXPLORE_VARIANT, LOGIN_VARIANT], [0, 100]);

const Elite100 = lazyImport('../Screens/Elite100');
const ContactUsScreen = lazyImport('../Screens/ContactUs');
const ApplyCuratorScreen = lazyImport('../Screens/Curators/ApplyCurator');
const AboutScreen = lazyImport('../Screens/About');
const CuratorsInfoScreen = lazyImport('../Screens/Curators');
const Search = lazyImport('../Screens/Search');
// const Feed = lazyImport('../Screens/Feed');
const Home = lazyImport('../Screens/Home');
const Landing = lazyImport('../Screens/Landing');
const Login = lazyImport('../Screens/Login/index.tsx');
const Register = lazyImport('../Screens/Register');
// const Dashboard = lazyImport('../Screens/Dashboard');
const Explore = lazyImport('../Screens/Explore');
const MicroCourse = lazyImport('../Screens/MicroCourse');
const Chapter = lazyImport('../Screens/Chapter');
// const Comments = lazyImport('../Screens/Comments');
const CuratorProfile = lazyImport('../Screens/CuratorProfile');
const Post = lazyImport('../Screens/Post');
const ResetPassword = lazyImport('../Screens/ResetPassword');
const Job = lazyImport('../Screens/Job');
const Company = lazyImport('../Screens/Company');
// const Profile = lazyImport('../Screens/Profile');
const PathwayScreen = lazyImport('../Screens/Pathway');
const ComponentsTestingPage = lazyImport('../Screens/ComponentsTestingPage');
const TagPage = lazyImport('../Screens/Tag');

const footerDisabledPages: string[] = []; // ['/register', '/login'];
const secondaryFooterDisabledPages: string[] = [ABOUT_PATH, CURATORS_INFO_PATH, APPLY_FOR_CURATOR_PATH, CONTACT_US_PATH];
const bottomBarDisabledPages: string[] = [REGISTER_PATH, LOGIN_PATH];

const AppNavigation: FC = () => {
	const classes = useStyles();
	const location = useLocation();

	useEffect(() => {
		if (window.analytics) segmentHelper.enable(); // Adding these here because this way we can be sure window.analytics has been loaded
	}, []);
	let env = process.env.REACT_APP_NODE_ENV;
	if (env) env = env.trim();
	const { isMobile } = useResponsive();
	const header = useMemo(() => getHeader(undefined, isMobile), [isMobile, location.pathname]);
	return (
		<Box className={classes.root}>
			{header}
			<Box className={classes.innerContainer}>
				<Suspense
					fallback={
						<Box mt={10}>
							<LinearProgress />
						</Box>
					}
				>
					<Switch>
						<Route path={LANDING_PATH} exact component={Landing} />
						<Route path={ABOUT_PATH} exact component={AboutScreen} />
						<Route path={CURATORS_INFO_PATH} exact component={CuratorsInfoScreen} />
						<Route path={CONTACT_US_PATH} exact component={ContactUsScreen} />
						<Route path={APPLY_FOR_CURATOR_PATH} component={ApplyCuratorScreen} />
						<Route path={LOGIN_PATH} exact component={Login} />
						{env !== 'production' ? <Route path={COMPONENTS_TEST_PATH} component={ComponentsTestingPage} /> : null}
						<Route path={REGISTER_PATH} exact component={Register} />
						{/* <Route path={DASHBOARD_PATH} component={Dashboard} /> */}
						<Route path={EXPLORE_PATH} component={Explore} />
						<Route path={CURATOR_PROFILE_PATH} component={CuratorProfile} />
						<Route path={POST_PATH} component={Post} />
						{/* <Route path={COMMENTS_PATH} component={Comments} /> */}
						<Route path={CHAPTER_PATH} exact component={Chapter} />
						<Route path={FEED_PATH} exact component={() => <Redirect to={EXPLORE_PATH} />} />
						{/* <Route path={FEED_PATH} exact component={Feed} /> */}
						{/* <Route path={MICRO_COURSE_REDIRECT_PATH} exact component={MicroCourseRedirect} /> Singular "pathway" will refer to micro course, while "pathways" will refer to actual pathways */}
						<Route path={MICRO_COURSE_PATH} exact component={MicroCourse} />
						<Route path={SEARCH_PATH} exact component={Search} />
						<Route path={RESET_PASSWORD_PATH} exact component={ResetPassword} />
						<Route path={INDIVIDUAL_JOB_LISTING_PATH} exact component={Job} />
						<Route path={INDIVIDUAL_COMPANY_PATH} exact component={Company} />
						{/* <Route path={PROFILE_PATH} exact component={Profile} /> */}
						<Route path={PATHWAY_PATH} component={PathwayScreen} /> {/* Singular "pathway" will refer to micro course, while "pathways" will refer to actual pathways */}
						<Route path={TAG_PATH} component={TagPage} />
						<Route path={ELITE_100_PATH} component={() => <></>} />
						{/* Elite100 page is added outside the main route set due to its sizing requirements. This dummy is to prevent 404 notification. */}
						{/* Private Routes followed by 404 page */}
						<PrivateRoute path={USER_HOME_PATH} component={Home} />
						<PrivateRoute path={CURATOR_STUDIO_PATH} component={CuratorStudioNavigation} />
						<Route path="/*" exact component={Page404} />
					</Switch>
				</Suspense>
			</Box>
			<Suspense
				fallback={
					<Box mt={10}>
						<LinearProgress />
					</Box>
				}
			>
				<Switch>
					<Route path={ELITE_100_PATH} component={Elite100} />
				</Switch>
			</Suspense>
			{location.pathname === '/' ? <FinalCTA /> : null}
			{!footerDisabledPages.includes(location.pathname) ? (
				<>
					{!secondaryFooterDisabledPages.includes(location.pathname) && <SecondaryFooter />}
					<Footer />
				</>
			) : null}
			{isMobile && !bottomBarDisabledPages.includes(location.pathname) ? <BottomBar /> : null}
		</Box>
	);
};

export default AppNavigation;

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			minHeight: '100vh',
			display: 'flex',
			flexDirection: 'column',
			alignContent: 'center',
			backgroundColor: theme.palette.background.default,
			[theme.breakpoints.up('lg')]: {
				alignItems: 'center',
			},
		},
		innerContainer: {
			// minHeight: 'calc(100vh - 72px)',
			// overflow: 'hidden',
			[theme.breakpoints.up('lg')]: {
				width: MAIN_CONTENT_WIDTH,
				// boxShadow: '#00000029 20px -14px 50px, #00000029 -20px 0 50px',
			},
		},
	})
);

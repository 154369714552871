import { RootStore } from 'Stores';
import { Action, action, Thunk, thunk } from 'easy-peasy';
import UserModel from 'Models/User';
import { ExploreFeed, User, SpecialUser } from 'Models/User/@types';
import { Params, IncludeArray } from 'Typings/@types';
import MicroCourseModel from 'Models/MicroCourse';
import { MicroCourse } from 'Models/MicroCourse/@types';
import _set from 'lodash/set';
import { addFakeLikeCounts } from 'Resources/EntityUtils';

export interface CuratorState {
	userFeed?: ExploreFeed;
	followers: User[];
	curator?: User | SpecialUser;
	curatorMicroCourses?: MicroCourse[];
	getCurator: Thunk<CuratorState, { id: string; params?: Params }, null, RootStore, Promise<User>>;
	getCuratorMicroCourses: Thunk<CuratorState, { id: string; params?: Params }, null, RootStore, Promise<MicroCourse[]>>;
	getFollowers: Thunk<CuratorState, { id: string; params?: Params }, null, RootStore, Promise<User[]>>;
	getUserFeed: Thunk<CuratorState, { params?: Params; shouldAppend?: boolean }, null, RootStore, Promise<CuratorState['userFeed'] | void>>;
	setFollowers: Action<CuratorState, User[]>;
	setCurator: Action<CuratorState, User | undefined>;
	setUserFeed: Action<CuratorState, ExploreFeed>;
}

const CuratorStore: CuratorState = {
	userFeed: undefined,
	followers: [],
	curator: undefined,
	curatorMicroCourses: [],
	getCurator: thunk(async (actions, { id, params }) => {
		const curator = await UserModel.getUser(id, params);
		actions.setCurator(curator);
		return curator;
	}),
	getCuratorMicroCourses: thunk(async (_actions, { id, params }, { getState }) => {
		let finalParams = params;
		finalParams = _set(params ?? {}, 'filter.where.curatorIds.inq', [id]);
		finalParams = _set(finalParams ?? {}, 'filter.include', [...((params?.filter?.include as IncludeArray) || []), { relation: 'curators' }]);
		const curatorMicroCourses = await MicroCourseModel.getAllMicroCourses(finalParams);
		getState().curatorMicroCourses = curatorMicroCourses;
		return curatorMicroCourses;
	}),

	getFollowers: thunk(async (actions, { id, params }) => {
		const followers = await UserModel.getFollowers(id, params);
		actions.setFollowers(followers);
		return followers;
	}),

	// eslint-disable-next-line consistent-return
	getUserFeed: thunk(async (actions, { params, shouldAppend }, { getState }) => {
		const { curator, userFeed } = getState();
		if (curator) {
			let finalParams = _set(params ?? {}, 'doc_type', 'Post');
			finalParams = _set(params ?? {}, 'filter.where.curatorIds', [curator.id]);
			const partialFeed = await UserModel.getGlobalFeed(finalParams);
			if (!shouldAppend) actions.setUserFeed(partialFeed);
			else {
				actions.setUserFeed({ total: partialFeed.total, results: (userFeed?.results ?? []).concat([...addFakeLikeCounts(partialFeed?.results ?? [])]) });
			}
			return partialFeed;
		}
	}),

	setFollowers: action((state, payload) => {
		state.followers = payload;
	}),

	setCurator: action((state, payload) => {
		state.curator = payload;
	}),

	setUserFeed: action((state, payload) => {
		state.userFeed = payload;
	}),
};

export default CuratorStore;

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import clsx from 'clsx';
import { Font2Medium } from 'Theme';

interface CreatedByChetStampProps {
	reverse?: boolean;
	textSize?: number;
}

const CreatedByChetStamp: FC<CreatedByChetStampProps> = ({ reverse, textSize }) => {
	const classes = useStyles({ reverse, textSize });

	return (
		<div className={classes.root}>
			{reverse ? (
				<Typography component="span" className={classes.text}>
					Created by Chet
				</Typography>
			) : null}
			<div className={clsx(classes.container, { [classes.leftMargin]: reverse, [classes.rightMargin]: !reverse })}>
				<div className={classes.boxRounded}>
					<div className={clsx(classes.boxRounded, classes.boxRotate)} />
				</div>
				<span className={classes.C}>C</span>
			</div>
			{!reverse ? (
				<Typography component="span" className={classes.text}>
					Created by Chet
				</Typography>
			) : null}
		</div>
	);
};

export default CreatedByChetStamp;

const useStyles = makeStyles<Theme, { reverse?: boolean; textSize?: number }>((theme) =>
	createStyles({
		root: {
			display: 'flex',
			// justifyContent: 'center',
			alignItems: 'center',
			paddingLeft: ({ reverse }) => (!reverse ? 5 : undefined),
			paddingRight: ({ reverse }) => (reverse ? 5 : undefined),
		},
		container: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'relative',
		},
		boxRounded: {
			borderRadius: 2,
			backgroundColor: theme.palette.primary.main,
			width: 14,
			height: 14,
			position: 'absolute',
			zIndex: 1,
		},
		boxRotate: {
			transform: 'rotate(45deg)',
		},
		C: {
			zIndex: 2,
			color: 'white',
			lineHeight: '16px',
			fontSize: 11,
			display: 'block',
			fontFamily: Font2Medium,
		},
		text: {
			color: theme.palette.grey['500'],
			fontWeight: 500,
			fontSize: ({ textSize }) => textSize || 10,
		},
		leftMargin: {
			marginLeft: 11,
		},
		rightMargin: {
			marginRight: 11,
		},
	})
);

import { parseToChapter } from 'Models/Chapter/ChapterParsers';
import { parseToMicroCourse } from 'Models/MicroCourse/MicroCourseParsers';
import { parseToPost } from 'Models/Post/PostParsers';
import { JSONType } from 'Typings/@types';
import { parseToPathway } from 'Models/Pathway/PathwayParsers';
import { recentlyViewedSlug, UserList, UserListItem } from './@types';

export const parseToUserList = (obj: JSONType): UserList => {
	return {
		...obj,
		...(obj.userListItems
			? {
					userListItems: obj.userListItems.map(parseToUserListItem),
			  }
			: {}),
	} as UserList;
};

export const parseToUserListItem = (obj: JSONType): UserListItem => {
	return {
		...obj,
		subject: getCorrectParser(obj.subjectType)(obj.subject),
	} as UserListItem;
};

const getCorrectParser = (subjectType: UserListItem['subjectType']): ((subject: JSONType) => UserListItem['subject']) => {
	switch (subjectType) {
		case 'MicroCourse':
			return parseToMicroCourse;
		case 'Chapter':
			return parseToChapter;
		case 'Pathway':
			return parseToPathway;
		default:
		case 'Post':
			return parseToPost;
	}
};

export const parseRecentlyViewedToUserList = (obj: { total: number; results: JSONType[] }, userId: string): { count: number; recentlyViewed: UserList } => {
	return {
		count: obj.total,
		recentlyViewed: parseToUserList({
			name: 'Recently Viewed',
			userId,
			id: recentlyViewedSlug,
			slug: recentlyViewedSlug,
			type: recentlyViewedSlug,
			userListItemIds: obj.results.map((item) => item.id),
			userListItems: obj.results.map(parseToUserListItem),
		}),
	};
};

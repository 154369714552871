import { Chapter } from 'Models/Chapter/@types';
import { ExploreListDocumentType } from 'Models/ExploreList/@types';
import { MicroCourse } from 'Models/MicroCourse/@types';
import { Pathway } from 'Models/Pathway/@types';
import { Post } from 'Models/Post/@types';
import { request } from 'Resources/AxiosUtils';
import { JSONType, Params } from 'Typings/@types';
import { ExploreHome, ListDocument } from './@types';
import { parseTagEntities, parseToExploreHome } from './ExploreHomeParsers';

class ExploreHomeModel {
	static getAllExploreHome = async (params?: JSONType): Promise<ExploreHome[]> => {
		const res = await request<JSONType[]>({
			url: '/exploreHomes/get-data',
			method: 'GET',
			params,
		});
		return res.map(parseToExploreHome);
	};

	static getExploreHome = async (id: string, params?: JSONType): Promise<ExploreHome> => {
		const res = request<JSONType>({
			url: `/exploreHomes/${id}`,
			method: 'GET',
			params,
		});
		return parseToExploreHome(res);
	};

	static constructExploreHomeItem = (microCourseList: MicroCourse[], name: string): ExploreHome => {
		return {
			id: '1630672241290',
			subjectId: '1630672241291',
			subjectType: 'ExploreList',
			subject: {
				id: '1630672241292',
				name,
				listDocuments: microCourseList.map((microCourse: MicroCourse, index: number) => ({
					id: `1630672241293${index}`,
					subjectId: '1630672241294',
					subject: microCourse,
					subjectType: 'MicroCourse',
					order: index,
					created: Date.now().toString(),
					updated: Date.now().toString(),
				})),
				created: Date.now().toString(),
				updated: Date.now().toString(),
				type: Array(microCourseList.length).map(() => 'MicroCourse'),
			},
			order: -1,
		};
	};

	static getForYou = async (userId: string, params?: Params): Promise<JSONType[]> => {
		const res = await request<JSONType[]>({
			url: `users/${userId}/my-feed`,
			method: 'GET',
			params,
		});
		return res;
	};

	static getTagEntities = async (tagId: string, params?: Params): Promise<ListDocument[]> => {
		// eslint-disable-next-line camelcase
		const res = await request<Array<(MicroCourse | Pathway | Chapter | Post) & { doc_type: ExploreListDocumentType }>>({
			url: `Tags/${tagId}/get-entities`,
			method: 'GET',
			params,
		});
		return parseTagEntities(res);
	};

	/* This will fail on production since it will be unauthorized. */
	static dangerouslyRegenerateFeed = async (userId: string): Promise<void> => {
		await request({
			url: 'userFeeds/generate-feed',
			method: 'POST',
			data: {
				userId,
				listType: '*',
				persist: false,
			},
		});
	};
}

export default ExploreHomeModel;

import React, { FC } from 'react';
import { makeStyles, createStyles, Box } from '@material-ui/core';
import { User } from 'Models/User/@types';
import clsx from 'clsx';
import UserModel from 'Models/User';
import ProfileImageWithLogo, { ProfileImageWithLogoProps } from './ProfileImageWithLogo';

export interface CuratorAvatarsProps {
	curators: User[];
	classes?: CuratorAvatarsClasses;
	innerElementProps?: Partial<Omit<ProfileImageWithLogoProps, 'profileImage' | 'companyLogo' | 'name'>>;
}

interface CuratorAvatarsClasses {
	root?: string;
}

const CuratorAvatars: FC<CuratorAvatarsProps> = (props) => {
	const { curators, classes: propClasses, innerElementProps } = props;
	const classes = useStyles();
	const data = curators.map((curator) => ({ profileImage: curator.profileImage?.url, companyLogo: curator.companyLogo?.url, name: UserModel.getUserFullName(curator) })).slice(0, 6) || [];

	const getStyles = (i: number): React.CSSProperties => {
		return {
			marginLeft: i === 0 ? 0 : -12,
			zIndex: -i,
		};
	};

	return (
		<Box pl={data.length > 5 ? 2 : undefined} className={clsx(classes.avatarsContainer, propClasses?.root)}>
			<Box width="fit-content" display="flex" position="relative" zIndex={1}>
				{data.map((curatorInfo, i) => (
					<ProfileImageWithLogo
						styles={getStyles(i)}
						key={curatorInfo.profileImage || Math.random().toString()}
						avatarClass={clsx(classes.avatar, { [classes.smallAvatar]: curators.length > 1 })}
						profileSize={70}
						logoSize={22}
						{...innerElementProps}
						profileImage={curatorInfo.profileImage}
						companyLogo={curatorInfo.companyLogo}
						name={curatorInfo.name}
					/>
				))}
			</Box>
		</Box>
	);
};

export default CuratorAvatars;

const useStyles = makeStyles(() =>
	createStyles({
		avatarsContainer: {
			display: 'flex',
			justifyContent: 'center',
			width: '100%',
			boxSizing: 'border-box',
		},
		avatar: {
			border: '4px solid white',
			boxSizing: 'border-box',
		},
		smallAvatar: {
			width: 52,
			height: 52,
		},
	})
);

import React, { FC } from 'react';
import { getFieldError, IFieldProps, MUIFileInput, processFilesWithCallback, TFile } from 'react-forms';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Box, makeStyles, createStyles, Typography, FormHelperText, CircularProgress, Fab, Avatar, Theme } from '@material-ui/core';
import { Picture } from 'Models/Picture/@types';
import PictureModel from 'Models/Picture';
import { parseToPicture } from 'Models/Picture/PictureParsers';
import useAsyncTask from 'Hooks/useAsyncTask';
import clsx from 'clsx';
import ICONS from 'Constants/icons.json';
import { JSONType } from 'Typings/@types';
import { THEME_PALETTE } from 'Theme/themeConstants';
import SvgIcon from './SvgIcon';
import ImagePickerLabel from './ImagePickerLabel';

export interface ImagePickerFieldProps {
	name: string;
	label: string;
	buttonLabel?: string | JSX.Element;
	imageWidth: number; // This has to be a number because this is the width that will be applied for transforming the url using imageKit's api.
	imagePlaceholderHeight: number | string;
	customParser?: (img: unknown) => Picture | string;
	placeholderClasses?: string[] | string;
	rootClass?: string;
}

export interface ImagePickerProps extends IFieldProps {
	fieldProps?: ImagePickerFieldProps;
}

const ImagePicker: FC<ImagePickerProps> = (props) => {
	const { fieldProps = {} as ImagePickerFieldProps, formikProps } = props;
	const { label = '', buttonLabel = <ImagePickerLabel />, name = '', imageWidth = 72, imagePlaceholderHeight = 250, customParser, placeholderClasses } = fieldProps;
	const fieldError = getFieldError(name, formikProps as JSONType);
	const value: Picture | string | null | undefined = get(formikProps, `values.${name}`);
	const classes = useStyles({ imgWidth: imageWidth });
	const pictureUpload = async (prop: { imgs: TFile[]; _rem: unknown[] }) => {
		const { imgs } = prop;
		const image = await PictureModel.upload(imgs[0]);
		if (image) {
			if (!customParser) formikProps?.setFieldValue(name, parseToPicture(image));
			else formikProps?.setFieldValue(name, customParser(image));
		}
	};
	const pictureUploadTask = useAsyncTask(pictureUpload);

	const wrapWith = (input: JSX.Element) => {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={2}>
				<Box mt={0}>
					<Typography variant="subtitle2" component="span" align="center" className={classes.label}>
						{label}
					</Typography>
				</Box>
				<Box width="100%" mb={1} display="flex" justifyContent="center">
					<Box display="flex" alignItems="center" justifyContent="center" width={imageWidth} height={imagePlaceholderHeight} className={clsx(classes.imgPlaceholder, placeholderClasses)}>
						{pictureUploadTask.status !== 'PROCESSING' ? (
							<>
								{value && !isEmpty(value) ? (
									<Box position="relative" display="flex" justifyContent="center" alignItems="flex-end">
										<Avatar
											alt="uploaded image"
											src={PictureModel.getTransformedUrl(typeof value !== 'string' ? value.url : value, imageWidth, imageWidth, 'face')}
											className={classes.avatar}
										/>
										<Fab className={classes.editIcon} size="small" color="primary">
											{input}
											<SvgIcon icon={ICONS.pencil} size={20} variant="light" />
										</Fab>
									</Box>
								) : (
									<>
										{buttonLabel}
										{input}
									</>
								)}
							</>
						) : (
							<CircularProgress />
						)}
					</Box>
				</Box>
				{(fieldError || (pictureUploadTask.status === 'ERROR' && pictureUploadTask.message === 'request entity too large')) && (
					<FormHelperText error>{fieldError.url || (typeof fieldError !== 'object' ? fieldError : false) || pictureUploadTask.message}</FormHelperText>
				)}
			</Box>
		);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		files && processFilesWithCallback(files, (prop: { imgs: TFile[]; _rem: unknown[] }) => pictureUploadTask.run(prop));
	};

	return (
		<Box>
			<MUIFileInput
				fieldProps={{
					name,
					wrapWith,
					nativeInputProps: { onChange: handleChange },
				}}
				formikProps={formikProps}
			/>
		</Box>
	);
};

export default ImagePicker;

const useStyles = makeStyles<Theme, { imgWidth: number }>((theme) =>
	createStyles({
		imgPlaceholder: {
			color: theme.palette.primary.main,
			position: 'relative',
			border: `2px solid ${theme.palette.primary.main}`,
			borderRadius: '50%',
			backgroundColor: THEME_PALETTE.grey.B400,
		},
		img: {
			borderRadius: '50%',
		},
		editIcon: {
			position: 'absolute',
			backgroundColor: THEME_PALETTE.blue.A100,
			width: 34,
			height: 34,
			bottom: -17,
		},
		avatar: {
			width: ({ imgWidth }) => imgWidth - 4, // -4 for border width
			height: ({ imgWidth }) => imgWidth - 4,
		},
		label: {
			color: theme.palette.grey['500'],
		},
	})
);

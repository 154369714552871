import React from 'react';
import { UilCheck } from '@iconscout/react-unicons';
import theme from 'Theme';
import { Box } from '@material-ui/core';

interface CustomCheckIconProps {
	checked: boolean;
}

const CustomCheckIcon: React.FC<CustomCheckIconProps> = ({ checked }) => {
	return (
		<Box
			{...{
				height: 16,
				width: 16,
				borderRadius: 4,
				border: `1px solid ${checked ? theme.palette.primary.main : theme.palette.grey['600']}`,
				boxShadow: `0px 1px 4px 0px ${theme.palette.grey['800']}29`,
				display: 'flex',
				alignItems: 'center',
				color: theme.palette.common.white,
			}}
			style={{ backgroundColor: checked ? theme.palette.primary.main : undefined }}
		>
			{checked ? <UilCheck size={16} /> : null}
		</Box>
	);
};

export default CustomCheckIcon;

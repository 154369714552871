import { request } from 'Resources/AxiosUtils';
import { JSONType, Params } from 'Typings/@types';
import { Job } from './@types';
import { parseToJobListing } from './JobListingParsers';

class JobListingModel {
	static getAllJobListing = async (params?: Params): Promise<Job[]> => {
		const res = await request<JSONType[]>({
			url: '/jobListings',
			method: 'GET',
			params,
		});
		return res.map(parseToJobListing);
	};

	static getJobListing = async (id: string, params?: Params): Promise<Job> => {
		const res = await request<JSONType>({
			url: `/jobListings/${id}`,
			method: 'GET',
			params,
		});
		return parseToJobListing(res);
	};

	static followJobListing = async (id: string): Promise<JSONType> => {
		const subscription = await request<JSONType>({
			url: `jobListings/${id}/follow`,
			method: 'POST',
		});
		return subscription;
	};

	static unFollowJobListing = async (followId: string): Promise<{ count: number }> => {
		const subscription = await request<{ count: number }>({
			url: `follows/${followId}/unfollow`,
			method: 'DELETE',
		});
		return subscription;
	};
}

export default JobListingModel;

import { MicroCourse } from 'Models/MicroCourse/@types';
import { action, Action, thunk, Thunk } from 'easy-peasy';
import UserModel from 'Models/User';
import { User, ExploreFeed, Follow } from 'Models/User/@types';
// eslint-disable-next-line import/no-cycle
import { RootStore } from 'Stores';
import { Params } from 'Typings/@types';
import { addFakeLikeCounts } from 'Resources/EntityUtils';

export interface UserState {
	userFollowing?: User[];
	subscribedMicroCourses?: MicroCourse[];
	globalFeed?: ExploreFeed;
	exploreFeed?: ExploreFeed;

	getUserFollowing: Thunk<UserState, { id: string; params?: Params }, null, RootStore, Promise<User[]>>;
	getSubscribedMicroCourses: Thunk<UserState, { id: string; params?: Params }, null, RootStore, Promise<MicroCourse[]>>;
	followCurator: Thunk<UserState, string, null, RootStore, Promise<Follow | false>>;
	unFollowCurator: Thunk<UserState, string, null, RootStore, Promise<{ count: number } | false>>;
	getExploreFeed: Thunk<UserState, { params: Params; resetOrAppend: 'reset' | 'append' }, null, RootStore, Promise<ExploreFeed>>;
	getGlobalFeed: Thunk<UserState, { params: Params; resetOrAppend: 'reset' | 'append' }, null, RootStore, Promise<ExploreFeed>>;

	setUserFollowing: Action<UserState, User[] | undefined>;
	setSubscribedMicroCourses: Action<UserState, MicroCourse[] | undefined>;
	setExploreFeed: Action<UserState, ExploreFeed | undefined>;
	setGlobalFeed: Action<UserState, ExploreFeed | undefined>;
}

const UserStore: UserState = {
	userFollowing: [],
	exploreFeed: undefined,
	globalFeed: undefined,

	followCurator: thunk(async (actions, id, { getStoreState }) => {
		const user = getStoreState().Auth;
		// const { curator } = getState();
		if (user && id) {
			const success = await UserModel.followUser(id);
			return success;
		}
		return false;
	}),
	unFollowCurator: thunk(async (actions, followId, { getStoreState }) => {
		const user = getStoreState().Auth;
		if (user && followId) {
			const success = await UserModel.unFollowUser(followId);
			return success;
		}
		return false;
	}),
	getUserFollowing: thunk(async (actions, { id, params }) => {
		const following = await UserModel.getFollowedCurators(id, params);
		actions.setUserFollowing(following);
		return following;
	}),
	getSubscribedMicroCourses: thunk(async (actions, { id, params }) => {
		const following = await UserModel.getFollowedMicroCourses(id, params);
		actions.setSubscribedMicroCourses(following);
		return following;
	}),

	getExploreFeed: thunk(async (actions, { params, resetOrAppend }, { getState }) => {
		const partialExploreFeed = await UserModel.getUserFeed(params);
		switch (resetOrAppend) {
			default:
			case 'reset':
				actions.setExploreFeed({ ...partialExploreFeed, results: addFakeLikeCounts(partialExploreFeed.results ?? []) });
				break;
			case 'append': {
				const { exploreFeed } = getState();
				actions.setExploreFeed({ total: partialExploreFeed.total, results: (exploreFeed?.results ?? []).concat([...addFakeLikeCounts(partialExploreFeed?.results ?? [])]) });
				break;
			}
		}
		return partialExploreFeed;
	}),
	getGlobalFeed: thunk(async (actions, { params, resetOrAppend }, { getState }) => {
		const partialExploreFeed = await UserModel.getGlobalFeed(params);
		switch (resetOrAppend) {
			default:
			case 'reset':
				actions.setGlobalFeed({ ...partialExploreFeed, results: addFakeLikeCounts(partialExploreFeed.results ?? []) });
				break;
			case 'append': {
				const { globalFeed } = getState();
				actions.setGlobalFeed({ total: partialExploreFeed.total, results: (globalFeed?.results ?? []).concat([...addFakeLikeCounts(partialExploreFeed?.results ?? [])]) });
				break;
			}
		}
		return partialExploreFeed;
	}),
	setExploreFeed: action((state, payload) => {
		state.exploreFeed = payload;
	}),

	setGlobalFeed: action((state, payload) => {
		state.globalFeed = payload;
	}),
	setUserFollowing: action((state, following) => {
		state.userFollowing = following;
	}),
	setSubscribedMicroCourses: action((state, microCourses) => {
		state.subscribedMicroCourses = microCourses;
	}),
};

export default UserStore;

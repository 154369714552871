import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';
import ABExperiment from 'Features/ABExperiment/Explore';
import EntityCard from 'Features/EntityCard';
import { Post } from 'Models/Post/@types';
import React, { FC, useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { POST_PATH } from 'Routes/RouteConstants';

interface PostCardProps {
	post: Post;
	classes?: { root: string };
	shouldUseExperiment?: boolean;
}

const PostCard: FC<PostCardProps> = ({ post, classes: propClasses, shouldUseExperiment }) => {
	const classes = useStyles();

	const ContentInsideLink = useMemo(() => <EntityCard entityType="Post" entity={post} />, [post]);

	if (shouldUseExperiment) {
		return <ABExperiment controlPath={generatePath(POST_PATH, { postSlug: post.slug })}>{ContentInsideLink}</ABExperiment>;
	}

	return (
		<Link className={clsx(classes.link, propClasses?.root)} to={generatePath(POST_PATH, { postSlug: post.slug })}>
			{ContentInsideLink}
		</Link>
	);
};

export default PostCard;

const useStyles = makeStyles((theme) =>
	createStyles({
		link: {
			textDecoration: 'none',
			display: 'flex',
			flex: 1,
			// width: 296
		},
		imageCardRoot: {
			height: 170,
			display: 'flex',
			width: 296,
			boxSizing: 'border-box',
			boxShadow: `0px 2px 20px 0px ${theme.palette.text.secondary}26`,
		},
		coverImage: {
			height: 'inherit',
		},
		descriptionContainer: {
			paddingTop: 16,
			paddingBottom: 10,
			boxSizing: 'border-box',
			margin: 'unset',
			flex: 1,
		},
		description: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			'-webkit-line-clamp': 2,
			'-webkit-box-orient': 'vertical',
			fontSize: theme.typography.caption.fontSize,
			paddingRight: 4,
			lineHeight: 1.2,
		},
		coverImageContainer: {
			alignSelf: 'flex-start',
			marginBottom: 'unset',
			marginRight: 12,
			height: 'inherit',
			overflow: 'hidden',
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			backgroundColor: 'unset',
			width: 118,
		},
		aboutUserRoot: {
			position: 'absolute',
			bottom: 30,
		},
		title: {
			// height: 44,
			marginBottom: '8px',
			fontSize: theme.typography.body2.fontSize,
			lineHeight: 1.2,
		},
	})
);

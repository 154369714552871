import React, { FC } from 'react';
import { Avatar, Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PictureModel from 'Models/Picture';

type UserImageWithLogoSquareVariant = 'large' | 'small';

interface UserImageWithLogoSquareProps {
	profileImage?: string;
	companyLogo?: string;
	variant?: UserImageWithLogoSquareVariant;
}

const UserImageWithLogoSquare: FC<UserImageWithLogoSquareProps> = ({ profileImage, companyLogo, variant = 'large' }) => {
	const classes = styles();
	if (variant === 'small') {
		return (
			<Box className={classes.rootSmall}>
				<Avatar src={PictureModel.getTransformedUrl(profileImage ?? '', 192, 192, 'face')} variant="rounded" className={classes.avatarSmall} />
				<Avatar src={PictureModel.getTransformedUrl(companyLogo ?? '', 40, 40, 'auto')} variant="circular" className={classes.companyLogoSmall} />
			</Box>
		);
	}

	return (
		<Box className={classes.largeRoot}>
			<Avatar src={PictureModel.getTransformedUrl(profileImage ?? '', 192, 192, 'face')} variant="rounded" className={clsx(classes.avatarLarge, classes.profileImageLarge)} />
			<Avatar src={PictureModel.getTransformedUrl(companyLogo ?? '', 192, 192, 'auto')} variant="rounded" className={clsx(classes.avatarLarge, classes.companyLogoLarge)} />
		</Box>
	);
};

export default UserImageWithLogoSquare;

const styles = makeStyles((theme) => ({
	largeRoot: {
		display: 'flex',
		width: 192,
		height: 96,
	},
	avatarLarge: {
		width: 96,
		height: 96,
		borderRadius: 16,
		borderTop: `2px solid ${theme.palette.grey[700]}`,
		borderBottom: `2px solid ${theme.palette.grey[700]}`,
	},
	profileImageLarge: {
		borderLeft: `2px solid ${theme.palette.grey[700]}`,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},
	companyLogoLarge: {
		borderRight: `2px solid ${theme.palette.grey[700]}`,
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
	},
	rootSmall: {
		position: 'relative',
		width: 34,
		height: 34,
	},
	avatarSmall: {
		// borderRadius: 16,
		border: `3px solid ${theme.palette.common.white}`,
		width: 34,
		height: 34,
	},
	companyLogoSmall: {
		position: 'absolute',
		bottom: 0,
		right: 0,
		border: `2px solid ${theme.palette.common.white}`,
		width: 16,
		height: 16,
	},
}));

import React, { FC, useContext } from 'react';
import EnrollViaEmailBanner from 'Screens/MicroCourse/EnrollViaEmailBanner';
import { SecondaryFooterContext } from './SecondaryFooterCTX';

interface SecondaryFooterProps {}

const SecondaryFooter: FC<SecondaryFooterProps> = () => {
	// const classes = styles();
	const { footerVariant, additionalProps } = useContext(SecondaryFooterContext);
	if (footerVariant === 'email-reminder' && additionalProps) {
		return <EnrollViaEmailBanner {...additionalProps} />;
	}
	return null;
	// return (
	// 	<Box className={classes.root}>
	// 		<Typo weight="bold" component="p" align="center" font="Font1">
	// 			Become A Curator
	// 		</Typo>
	// 		<Typo component="span" align="center">
	// 			Share your expertise &amp; give back to the community!
	// 		</Typo>
	// 		<Button component={Link} to={CURATORS_INFO_PATH} variant="text" color="primary">
	// 			Apply Now
	// 		</Button>
	// 	</Box>
	// );
};

export default SecondaryFooter;

// const styles = makeStyles((theme) => ({
// 	root: {
// 		padding: theme.spacing(0, 2.5),
// 		backgroundColor: THEME_PALETTE.blue['200'],
// 		height: 228,
// 		width: '100%',
// 		display: 'flex',
// 		flexDirection: 'column',
// 		justifyContent: 'center',
// 		alignItems: 'center',
// 		gap: theme.spacing(2),
// 		'& > p': {
// 			color: theme.palette.common.white,
// 			fontSize: 32,
// 		},
// 		'& > span': {
// 			color: theme.palette.common.white,
// 			marginBottom: theme.spacing(1.5),
// 		},
// 		'& a': {
// 			backgroundColor: theme.palette.common.white,
// 			color: theme.palette.primary.main,
// 			paddingLeft: theme.spacing(6),
// 			paddingRight: theme.spacing(6),
// 			'&:hover': { backgroundColor: theme.palette.common.white },
// 		},
// 	},
// }));

import { CircularProgress } from '@material-ui/core';
import { SecondaryFooterCTXProvider } from 'Features/SecondaryFooter/SecondaryFooterCTX';
import React from 'react';
import CacheBuster from 'react-cache-buster';
import { version } from '../../package.json';
import AppDialogProvider from './AppDialogContext';
import AppToastProvider from './AppToastContext';
import SnackbarProvider from './SnackbarContext';
import HeaderProvider from './HeaderContext';

const RootContextProviders: React.FC = (props) => {
	const { children } = props;
	return (
		<CacheBuster
			currentVersion={version}
			isEnabled // If false, the library is disabled.
			isVerboseMode={false} // If true, the library writes verbose logs to console.
			loadingComponent={<CircularProgress />} // If not pass, nothing appears at the time of new version check.
		>
			<SnackbarProvider>
				<AppDialogProvider>
					<SecondaryFooterCTXProvider>
						<AppToastProvider>
							<HeaderProvider>{children}</HeaderProvider>
						</AppToastProvider>
					</SecondaryFooterCTXProvider>
				</AppDialogProvider>
			</SnackbarProvider>
		</CacheBuster>
	);
};

export default RootContextProviders;

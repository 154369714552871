// eslint-disable-next-line import/no-cycle
import { RootStore } from 'Stores';
import { action, Action, thunk, Thunk } from 'easy-peasy';
import ExploreHomeModel from 'Models/ExploreHome';
import { ExploreHome, ListDocument } from 'Models/ExploreHome/@types';
import { parseForYouToExploreHomeItems } from 'Models/ExploreHome/ExploreHomeParsers';
import UserModel from 'Models/User';
import { User } from 'Models/User/@types';
import { JSONType, Params } from '../../Typings/@types';

export interface ExploreHomeState {
	featuredUsers?: User[] /* Plural just in case there is a requirement at some point for multiple featured curators */;

	useListDocuments?: boolean;
	listDocuments?: ListDocument[];
	exploreItems?: ExploreHome[];
	getAllExploreItems: Thunk<ExploreHomeState, { params?: JSONType; shouldAppend?: boolean }, null, RootStore, Promise<ExploreHome[]>>;
	getForYou: Thunk<ExploreHomeState, { params?: Params; shouldAppend?: boolean }, null, RootStore, Promise<ExploreHome[] | null>>;
	fetchListDocumentsForTag: Thunk<ExploreHomeState, { tagId: string; params: Params }, null, RootStore, Promise<ListDocument[]>>;
	getFeaturedUsers: Thunk<ExploreHomeState, { params: Params }, null, RootStore, Promise<User[] | null>>;

	appendToExploreItems: Action<ExploreHomeState, ExploreHome[]>;
	setAllExploreItems: Action<ExploreHomeState, ExploreHome[] | undefined>;
	setUseListDocuments: Action<ExploreHomeState, boolean>;
	setListDocuments: Action<ExploreHomeState, ListDocument[]>;
	setFeaturedUsers: Action<ExploreHomeState, undefined | User[]>;
}

const ExploreHomeStore: ExploreHomeState = {
	featuredUsers: [],
	exploreItems: [],
	useListDocuments: false,
	setUseListDocuments: action((state, payload) => {
		state.useListDocuments = payload;
	}),
	setListDocuments: action((state, payload) => {
		state.listDocuments = payload;
	}),
	getAllExploreItems: thunk(async (actions, { params, shouldAppend }) => {
		const res = await ExploreHomeModel.getAllExploreHome(params);
		if (res) {
			if (shouldAppend) actions.appendToExploreItems(res as ExploreHome[]);
			else actions.setAllExploreItems(res);
			actions.setUseListDocuments(false);
		}
		return res ?? [];
	}),
	fetchListDocumentsForTag: thunk(async (actions, { tagId, params }, { getState }) => {
		const res = await ExploreHomeModel.getTagEntities(tagId, params);
		actions.setListDocuments([...(getState().listDocuments ?? []), ...res]);
		actions.setUseListDocuments(true);
		return res;
	}),
	getForYou: thunk(async (actions, { params, shouldAppend }, { getStoreState }) => {
		const userId = getStoreState().Auth.user?.id;
		const { appConstants } = getStoreState().App;
		if (userId && appConstants) {
			const res = parseForYouToExploreHomeItems(await ExploreHomeModel.getForYou(userId, params), appConstants);
			if (res) {
				if (shouldAppend) actions.appendToExploreItems(res);
				else actions.setAllExploreItems(res);
				actions.setUseListDocuments(false);
			}
			return res;
		}
		return null;
	}),
	setAllExploreItems: action((state, payload) => {
		state.exploreItems = payload;
	}),
	appendToExploreItems: action((state, payload) => {
		state.exploreItems = [...(state.exploreItems ?? []), ...payload];
	}),
	getFeaturedUsers: thunk(async (actions, { params }) => {
		const featuredUsers = await UserModel.getFeaturedUsers(params);
		actions.setFeaturedUsers(featuredUsers);
		return featuredUsers;
	}),
	setFeaturedUsers: action((state, payload) => {
		state.featuredUsers = payload;
	}),
};

export default ExploreHomeStore;

import { action, Action, thunk, Thunk } from 'easy-peasy';
import MicroCourseModel from 'Models/MicroCourse';
import { MicroCourse } from 'Models/MicroCourse/@types';
// eslint-disable-next-line import/no-cycle
import { RootStore } from 'Stores';
// eslint-disable-next-line import/no-cycle
import { IncludeArray, Params } from 'Typings/@types';
import _set from 'lodash/set';
import { Chapter } from 'Models/Chapter/@types';

export interface CuratorStudioState {
	microCourse?: MicroCourse;
	draftMicroCourses: MicroCourse[];
	draftCount?: number;
	inReviewMicroCourses: MicroCourse[];
	inReviewCount?: number;
	publishedMicroCourses: MicroCourse[];
	chapters?: Chapter[];
	chapter?: Chapter;

	getChapters: Thunk<CuratorStudioState, { userId: string; params?: Params }, RootStore, Promise<Chapter[]>>;
	getChapter: Thunk<CuratorStudioState, { userId: string; chapterSlug: string; params?: Params }, RootStore, Promise<Chapter>>;
	setChapters: Action<CuratorStudioState, Chapter[] | undefined>;
	setChapter: Action<CuratorStudioState, Chapter | undefined>;

	createMicroCourse: Thunk<CuratorStudioState, Partial<MicroCourse>, RootStore, Promise<MicroCourse>>;
	updateMicroCourse: Thunk<CuratorStudioState, { data: Partial<MicroCourse>; id: string }>;

	createChapter: Thunk<CuratorStudioState, { data: Partial<Chapter> }>;
	updateChapter: Thunk<CuratorStudioState, { data: Partial<Chapter>; id: string }>;

	getPublishedMicroCourses: Thunk<CuratorStudioState, { userId: string; params?: Params }, RootStore, Promise<MicroCourse[]>>;
	setPublishedMicroCourses: Action<CuratorStudioState, MicroCourse[]>;

	getMicroCourse: Thunk<CuratorStudioState, { userId: string; params?: Params }, RootStore, Promise<MicroCourse>>;
	setMicroCourse: Action<CuratorStudioState, MicroCourse | undefined>;

	getDraftMicroCourses: Thunk<CuratorStudioState, { userId: string; params?: Params }, RootStore, Promise<MicroCourse[]>>;
	setDraftMicroCourses: Action<CuratorStudioState, MicroCourse[]>;
	setDraftCount: Action<CuratorStudioState, number>;

	getInReviewMicroCourses: Thunk<CuratorStudioState, { userId: string; params?: Params }, RootStore, Promise<MicroCourse[]>>;
	setInReviewMicroCourses: Action<CuratorStudioState, MicroCourse[]>;
	setInReviewCount: Action<CuratorStudioState, number>;

	deleteMicroCourse: Thunk<CuratorStudioState, string, RootStore, Promise<boolean>>;
	deleteChapter: Thunk<CuratorStudioState, string, RootStore, Promise<boolean>>;
	sendReviewRequest: Thunk<CuratorStudioState, string>;
	// getMyCourses: Thunk<CuratorStudioState, { userId: string; params?: Params }, RootStore, Promise<MicroCourse[]>>;
}

const CuratorStudioStore: CuratorStudioState = {
	microCourse: undefined,
	chapter: undefined,
	draftMicroCourses: [],
	draftCount: 0,
	inReviewMicroCourses: [],
	inReviewCount: 0,
	publishedMicroCourses: [],
	chapters: [],

	getChapters: thunk(async (actions, { userId, params }) => {
		const { results: myCourses } = await MicroCourseModel.getMyCourses(userId, params);
		actions.setChapters(myCourses[0].chapters);
		return myCourses[0].chapters;
	}),
	getChapter: thunk(async (actions, { userId, chapterSlug, params }) => {
		const { results: myCourses } = await MicroCourseModel.getMyCourses(userId, params);
		// eslint-disable-next-line prefer-destructuring
		if (myCourses && myCourses[0].chapters) actions.setChapter((myCourses[0].chapters?.filter((chapter) => chapter.slug === chapterSlug))[0]);
		else actions.setChapter(undefined);
		return myCourses[0].chapters;
	}),

	setChapters: action((state, payload) => {
		state.chapters = payload;
	}),

	setChapter: action((state, payload) => {
		state.chapter = payload;
	}),

	getDraftMicroCourses: thunk(async (actions, { userId, params }) => {
		const { count, results } = await MicroCourseModel.getDraftMicroCourses(userId, { ...params, filter: { ...params?.filter, include: ['curators'] } });
		actions.setDraftMicroCourses(results);
		actions.setDraftCount(count);
		return results;
	}),

	getInReviewMicroCourses: thunk(async (actions, { userId, params }) => {
		const { count, results } = await MicroCourseModel.getInReviewMicroCourses(userId, { ...params, filter: { ...params?.filter, include: ['curators'] } });
		actions.setInReviewMicroCourses(results);
		actions.setInReviewCount(count);
		return results;
	}),

	getPublishedMicroCourses: thunk(async (actions, { userId, params }) => {
		let finalParams = params;
		finalParams = _set(params ?? {}, 'filter.where.curatorIds.inq', [userId]);
		finalParams = _set(finalParams ?? {}, 'filter.include', [...((params?.filter?.include as IncludeArray) || []), { relation: 'curators' }]);
		const microCourses = await MicroCourseModel.getAllMicroCourses(finalParams);
		actions.setPublishedMicroCourses(microCourses);
		return microCourses;
	}),

	getMicroCourse: thunk(async (actions, { userId, params }) => {
		const { results } = await MicroCourseModel.getMyCourses(userId, params);
		actions.setMicroCourse(results[0]);
		return results[0];
	}),

	deleteMicroCourse: thunk(async (actions, id) => {
		const deleted = await MicroCourseModel.deleteMicroCourse(id);
		return deleted;
	}),

	deleteChapter: thunk(async (actions, id, { getState }) => {
		const { chapters } = getState();
		const deleted = await MicroCourseModel.deleteChapter(id);
		if (chapters) actions.setChapters(chapters.filter((chapter) => chapter.id !== id));
		return deleted;
	}),

	createMicroCourse: thunk(async (_actions, data: Partial<MicroCourse>) => {
		const microCourse = await MicroCourseModel.createMicroCourse(data);
		_actions.setMicroCourse(microCourse);
		return microCourse;
	}),

	updateMicroCourse: thunk(async (actions, dataRef: { data: Partial<MicroCourse>; id: string }) => {
		const updatedMicroCourse = await MicroCourseModel.updateMicroCourse(dataRef.data, dataRef.id);
		actions.setMicroCourse(updatedMicroCourse);
		return updatedMicroCourse;
	}),

	createChapter: thunk(async (_actions, dataRef: { data: Partial<MicroCourse> }) => {
		const createdChapter = await MicroCourseModel.createChapter(dataRef.data);
		return createdChapter;
	}),

	updateChapter: thunk(async (_actions, dataRef: { data: Partial<MicroCourse>; id: string }) => {
		const updatedChapter = await MicroCourseModel.updateChapter(dataRef.data, dataRef.id);
		return updatedChapter;
	}),

	sendReviewRequest: thunk(async (_actions, id: string) => {
		const reviewData = await MicroCourseModel.sendReviewRequest(id);
		return reviewData;
	}),

	setDraftMicroCourses: action((state, payload) => {
		state.draftMicroCourses = payload;
	}),

	setInReviewMicroCourses: action((state, payload) => {
		state.inReviewMicroCourses = payload;
	}),

	setPublishedMicroCourses: action((state, payload) => {
		state.publishedMicroCourses = payload;
	}),

	setMicroCourse: action((state, payload) => {
		state.microCourse = payload;
	}),
	setDraftCount: action((state, payload) => {
		state.draftCount = payload;
	}),
	setInReviewCount: action((state, payload) => {
		state.inReviewCount = payload;
	}),
};

export default CuratorStudioStore;

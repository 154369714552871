import { createStore, createTypedHooks, State } from 'easy-peasy';
import _isEqual from 'lodash/isEqual';
import logger from 'redux-logger';
import Config from 'config';
import { JSONType } from 'Typings/@types';
import Tag from './Tag';
import Library from './Library';
import Elite from './Elite';
import Search from './Search';
import CuratorStudio from './CuratorStudio';
import App from './App';
import UserContent from './UserContent';
import Pathway from './Pathway';
import ExploreList from './ExploreList';
import Post from './Post';
import ExploreHome from './ExploreHome';
import Chapter from './Chapter';
import MicroCourse from './MicroCourse';
import User from './User';
import Auth from './Auth';
import Curator from './Curator';
import JobListing from './JobListing';
import Company from './Company';

export type RootStore = typeof RootStore;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const RootStore = {
	Company,
	JobListing,
	Tag,
	Library,
	Search,
	CuratorStudio,
	UserContent,
	Pathway,
	ExploreList,
	Post,
	ExploreHome,
	Chapter,
	MicroCourse,
	User,
	Auth,
	App,
	Elite,
	Curator,
};

const typedHooks = createTypedHooks<RootStore>();

export const { useStoreActions } = typedHooks;
export const { useStoreDispatch } = typedHooks;
export const useStoreState = <Result>(a: (state: State<RootStore>) => Result, b?: (value: JSONType, other: JSONType) => boolean): Result => typedHooks.useStoreState(a, b || _isEqual);

export default createStore(RootStore, {
	// Put your dependency injections here
	middleware: [...(Config.get('CONSOLE_LOGGING') ? [logger] : [])],
});

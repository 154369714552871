import axios, { AxiosError, AxiosRequestConfig } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
export const request = async <T = Record<string, any>>(config: AxiosRequestConfig, log = true): Promise<T> => {
	if (!axios.defaults.baseURL) {
		throw new Error('Error: Base Url is not provided');
	}
	const resp = await axios.request<T>(config);
	return resp.data;
};

const AxiosUtils = {
	setBaseAPI_URL: (url: string): void => {
		axios.defaults.baseURL = url;
	},

	setHeader: (type = 'Content-Type', value = 'application/json'): void => {
		axios.defaults.headers.post[type] = value;
	},

	setAuthHeader: (accessToken?: string): void => {
		axios.defaults.headers.common.Authorization = accessToken;
	},

	throwError: (error: AxiosError): void => {
		// eslint-disable-next-line no-console
		console.error('Error', error.response);
		throw error;
	},
};

export default AxiosUtils;

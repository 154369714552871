import React, { FC, useMemo } from 'react';
import { alpha, Box, Button, CircularProgress, makeStyles, Menu, Paper, PopoverOrigin } from '@material-ui/core';
import { User } from 'Models/User/@types';
import { Chapter } from 'Models/Chapter/@types';
import { Post } from 'Models/Post/@types';
import { MicroCourse } from 'Models/MicroCourse/@types';
import clsx from 'clsx';
import UserModel from 'Models/User';
import Typo from 'Components/Typo';
import { getIcon } from 'Features/Chapters/ChapterUtils';
import { getTimeLargest } from 'Utils/TimeUtils';
import { UilLayerGroup, UilBookmark } from '@iconscout/react-unicons';
import { UisBookmark } from '@iconscout/react-unicons-solid';
import IconText from 'Components/IconText';
import { recentlyViewedSlug, UserList, UserListItem } from 'Models/UserList/@types';
import { isEmpty } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { useStoreActions, useStoreState } from 'Stores';
import { bindMenu, bindToggle } from 'material-ui-popup-state';
// eslint-disable-next-line import/no-cycle
import UserListMenu from 'Features/Library/UserListMenu';
import { usePopupState } from 'material-ui-popup-state/hooks';
import Users from './Users';

export type Entity = 'MicroCourse' | 'Post' | 'Chapter' | 'User'; // No design for User available atm. Return UserListicleCard for now.

interface EntityCardProps {
	entityType: Entity;
	entity: MicroCourse | Post | Chapter | User;
}

const EntityCard: FC<EntityCardProps> = ({ entity, entityType }) => {
	const classes = styles();
	const {
		userLists,
		defaultLists,
		user: appUser,
		loadingUserLists,
	} = useStoreState(({ Auth: { user }, Library: { userLists, defaultLists, loadingUserLists } }) => ({
		user,
		userLists,
		defaultLists,
		loadingUserLists,
	}));
	const lists = (defaultLists ?? []).filter((value) => value.slug !== recentlyViewedSlug).concat(userLists ?? []);
	const popupState = usePopupState({ variant: 'popper', popupId: 'addToListPopper' });

	const { setUserLists, setDefaultLists } = useStoreActions(({ Library: { setUserLists, setDefaultLists } }) => ({ setUserLists, setDefaultLists }));
	const { onClick, ...toggleProps } = bindToggle(popupState);
	const { onClose, ...menuProps } = bindMenu(popupState);

	const updateLists = (lists: UserList[]) => {
		const userLists: UserList[] = [];
		const defaultLists: UserList[] = [];
		lists.forEach((list) => {
			if (list.type === 'uncategorized') {
				defaultLists.push(list);
				return;
			}
			userLists.push(list);
		});
		setUserLists(userLists);
		setDefaultLists(defaultLists);
	};

	const { users, shouldHaveStack, userNameText, userDescriptionText, title, icon, iconText, isItemSavedToList } = useMemo(() => {
		let users: User[] = [];
		let shouldHaveStack = false;
		let userNameText = '';
		let userDescriptionText = '';
		let title = '';
		let icon: JSX.Element | null = null;
		let iconText = '';
		switch (entityType) {
			case 'Post': {
				const post = entity as Post;
				if (post.user) {
					users = [post.user];
					userNameText = UserModel.getUserFullName(post.user);
					userDescriptionText = `${post.user.recentJobTitle?.trim() || ''} ${post.user.recentCompany ? ` · ${post.user.recentCompany.trim()}` : ''}`;
				}
				title = post.title;
				if (post.contentDuration) {
					icon = getIcon(post.type);
					iconText = getTimeLargest(post.contentDuration);
				}
				break;
			}
			case 'Chapter': {
				const chapter = entity as Chapter;
				users = chapter.microCourse?.curators ? chapter.microCourse.curators : [];
				if ((users.length ?? 0) > 1) {
					userNameText = `${users?.length} curators from`;
					userDescriptionText =
						users
							?.map((curator) => curator.recentCompany)
							.filter((companyName) => !!companyName)
							.join(' • ') ?? '';
				} else if (users?.length) {
					const user = users[0];
					userNameText = UserModel.getUserFullName(user);
					userDescriptionText = `${user.recentCompany?.trim() || ''} ${user.recentJobTitle ? ` · ${user.recentJobTitle.trim()}` : ''}`;
				}
				if (chapter.user) {
					userNameText = UserModel.getUserFullName(chapter.user);
					userDescriptionText = `${chapter.user.recentJobTitle?.trim() || ''} ${chapter.user.recentCompany ? ` · ${chapter.user.recentCompany.trim()}` : ''}`;
				}
				title = chapter.title;
				if (chapter.contentDuration) {
					icon = getIcon(chapter.type);
					iconText = getTimeLargest(chapter.contentDuration);
				}
				break;
			}
			case 'MicroCourse': {
				const microCourse = entity as MicroCourse;
				users = microCourse.curators ?? [];
				shouldHaveStack = (microCourse.chapterIds?.length ?? 0) > 1;
				if ((microCourse.curators?.length ?? 0) > 1) {
					userNameText = `${microCourse.curators?.length} curators from`;
					userDescriptionText =
						microCourse.curators
							?.map((curator) => curator.recentCompany)
							.filter((companyName) => !!companyName)
							.join(' • ') ?? '';
				} else if (microCourse.curators?.length) {
					const user = microCourse.curators[0];
					userNameText = UserModel.getUserFullName(user);
					userDescriptionText = `${user.recentCompany?.trim() || ''} ${user.recentJobTitle ? ` · ${user.recentJobTitle.trim()}` : ''}`;
				}
				title = microCourse.name;
				icon = <UilLayerGroup size={18} />;
				iconText = `${microCourse.chapterIds.length} link${microCourse.chapterIds.length > 1 ? 's' : ''}`;
				break;
			}
			default:
			case 'User': {
				const user = entity as User;
				users = [user];
				shouldHaveStack = false;
				userNameText = UserModel.getUserFullName(user);
				userDescriptionText = `${user.recentCompany?.trim() || ''} ${user.recentJobTitle ? ` · ${user.recentJobTitle.trim()}` : ''}`;
				break;
			}
		}
		let isItemSavedToList = false;
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < lists.length; ++i) {
			if ((lists[i].userListItems?.findIndex((item) => item.subjectId === entity.id) ?? 0) > -1) {
				isItemSavedToList = true;
				break;
			}
		}
		return { users, shouldHaveStack, userDescriptionText, userNameText, title, icon, iconText, isItemSavedToList };
	}, [entity, lists]);

	const SaveToListElement = useMemo(() => {
		if (loadingUserLists) return <CircularProgress />;
		return isItemSavedToList ? <UisBookmark size={18} /> : <UilBookmark size={18} />;
	}, [loadingUserLists, isItemSavedToList]);

	return (
		<Box position="relative" zIndex={1} mb={3} height="fit-content" width="100%">
			<Box className={clsx({ [classes.stack]: shouldHaveStack })} />
			<Paper className={classes.root}>
				<Users users={users.slice(0, 6)} />
				<Box mt={1.5}>
					<Typo weight="bold" variant="body1">
						{userNameText}
					</Typo>
					<Typo limitLines={2} className={classes.descriptionText} variant="subtitle2">
						{userDescriptionText}
					</Typo>
				</Box>
				{title && (
					<Box mt={1.5}>
						<Typo variant="h4" limitLines={3}>
							{title}
						</Typo>
					</Box>
				)}
				{icon && iconText && (
					<Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
						<Box>
							<Box className={classes.contentInfo}>
								<IconText typoProps={{ weight: 'bold', variant: 'caption' }} icon={icon} text={iconText} />{' '}
							</Box>
						</Box>
						{entityType !== 'User' && !isEmpty(appUser) ? (
							<>
								<Button
									{...toggleProps}
									className={classes.bookmarkBtn}
									onClick={(event) => {
										event.preventDefault();
										onClick(event);
									}}
									disabled={loadingUserLists}
								>
									{SaveToListElement}
								</Button>
								{
									<Menu
										anchorOrigin={
											{
												// vertical: 'bottom',
												horizontal: 'right',
											} as PopoverOrigin
										}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										onClose={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
											event.preventDefault();
											event.stopPropagation();
											onClose();
										}}
										{...menuProps}
										className={classes.popper}
									>
										<UserListMenu
											// Probably not the best way to filter out the recently viewed list
											lists={lists}
											userId={appUser?.id ?? ''}
											subjectType={entityType as UserListItem['subjectType']}
											subjectId={entity.id}
											setUpdatedLists={updateLists}
										/>
									</Menu>
								}
							</>
						) : null}
					</Box>
				)}
			</Paper>
		</Box>
	);
};

export default EntityCard;

const styles = makeStyles((theme) => ({
	descriptionText: {
		color: alpha(theme.palette.common.black, 0.5),
	},
	popper: {
		zIndex: 2,
	},
	root: {
		borderRadius: 32,
		padding: 20,
		boxShadow: `0px 4px 12px 0px ${alpha(theme.palette.common.black, 0.14)}`,
		position: 'relative',
		zIndex: 1,
		width: '100%',
	},
	stack: {
		'&:before': {
			content: "' '",
			zIndex: -1,
			backgroundColor: theme.palette.grey['200'],
			boxShadow: `0px 4px 12px 0px ${alpha(theme.palette.common.black, 0.14)}`,
			width: 'calc(100% - 16px)',
			height: 100,
			position: 'absolute',
			bottom: -8,
			left: 8,
			borderRadius: 32,
		},
		'&:after': {
			content: "' '",
			backgroundColor: theme.palette.grey['200'],
			zIndex: -2,
			boxShadow: `0px 4px 12px 0px ${alpha(theme.palette.common.black, 0.14)}`,
			bottom: -16,
			width: 'calc(100% - 48px)',
			left: 24,
			height: 100,
			position: 'absolute',
			borderRadius: 32,
		},
	},
	contentInfo: {
		borderRadius: 8,
		backgroundColor: theme.palette.grey['200'],
		padding: theme.spacing(0.5, 1.5),
	},
	bookmarkBtn: {
		backgroundColor: theme.palette.grey['200'],
		height: 28,
		borderRadius: 8,
	},
}));

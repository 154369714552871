import { action, Action, thunk, Thunk } from 'easy-peasy';
import { chapterCompleted } from 'Features/MicroCourse/ChapterCompletedEvent';
import get from 'lodash/get';
import { ChapterModel } from 'Models/Chapter';
import MicroCourseModel from 'Models/MicroCourse';
import { MicroCourse } from 'Models/MicroCourse/@types';
import PathwayModel from 'Models/Pathway';
import { Pathway } from 'Models/Pathway/@types';
// eslint-disable-next-line import/no-cycle
import { MicroCoursePageState } from 'Screens/MicroCourse';
// eslint-disable-next-line import/no-cycle
import { RootStore } from 'Stores';
// eslint-disable-next-line import/no-cycle
import utils from 'Utils';

// type ThunkAction<T = void, R = void> = Thunk<PathwayState, T, any, RootStore, Promise<R>>;
// type StaticAction<T> = Action<PathwayState, T>;

export interface PathwayState {
	pathway?: Pathway;
	setPathway: Action<PathwayState, Pathway | undefined>;
	fetchPathway: Thunk<PathwayState, string, null, RootStore, Promise<void>>;

	microCourse?: MicroCourse;
	setMicroCourse: Action<PathwayState, MicroCourse | undefined>;
	fetchMicroCourse: Thunk<PathwayState, string, null, RootStore, Promise<void>>;

	loading: boolean;
	setLoading: Action<PathwayState, boolean>;
	loadingMicroCourse: boolean;
	setLoadingMicroCourse: Action<PathwayState, boolean>;

	pageState: MicroCoursePageState;
	setPageState: Action<PathwayState, MicroCoursePageState>;

	postMicroCourseViews: Thunk<PathwayState, MicroCourse, null, RootStore, Promise<void>>;
	updateCompletedCount: Action<PathwayState, string>;
	markChapterCompleted: Thunk<PathwayState, string, null, RootStore, Promise<void>>;
}

export const PATHWAY_DETAIL_INCLUDE_FILTER = {
	include: [
		'tags',
		{
			relation: 'pathwaySteps',
			scope: {
				include: {
					relation: 'microCourses',
					scope: {
						include: ['curators', 'tags'],
					},
				},
			},
		},
	],
};

export const PATHWAY_MICROCOURSE_INCLUDE_FILTER = {
	include: ['curators', 'chapters'],
};

const PathwayStore: PathwayState = {
	loading: true,
	pageState: {
		completedChapterSlugs: [],
		isCurrentChapterComplete: false,
		shouldShowNextChapterPrompt: false,
	},
	loadingMicroCourse: true,
	setPathway: action((state, payload) => {
		state.pathway = payload;
	}),
	setPageState: action((state, payload) => {
		state.pageState = { ...state.pageState, ...payload };
	}),
	setMicroCourse: action((state, payload) => {
		state.microCourse = payload;
	}),
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	setLoadingMicroCourse: action((state, payload) => {
		state.loadingMicroCourse = payload;
	}),
	fetchPathway: thunk(async (actions, id) => {
		actions.setLoading(true);
		const res = await PathwayModel.getPathwayOverview(id).catch((err) => {
			if (get(err, 'response.data.error.message.isMicroCourse')) {
				window.location.replace(window.location.toString().replace('/pathway/', '/microCourse/'));
			}
		});
		// const res = await PathwayModel.getPathway(id, { filter: { ...PATHWAY_DETAIL_INCLUDE_FILTER } })
		if (res) actions.setPathway(res);
		actions.setLoading(false);
	}),
	fetchMicroCourse: thunk(async (actions, id) => {
		actions.setLoadingMicroCourse(true);
		const microCourse = await MicroCourseModel.getMicroCourse(id, { filter: { ...PATHWAY_MICROCOURSE_INCLUDE_FILTER } });
		actions.setMicroCourse(microCourse);
		actions.setLoadingMicroCourse(false);
	}),
	markChapterCompleted: thunk(async (actions, id, { getStoreState, getStoreActions, getState }) => {
		const { isCordova } = getStoreState().App;
		if (isCordova) {
			const accessToken = utils.getAccessToken();
			if (!accessToken) return;
			chapterCompleted(id, accessToken);
			return;
		}

		const { microCourse, pathway } = getState();
		const res = await ChapterModel.markCompleted(id, pathway?.id);
		if (res && microCourse) {
			actions.updateCompletedCount(id);
			getStoreActions().Auth.addMicroCourseToOngoing(microCourse);
		}
	}),
	postMicroCourseViews: thunk(async (_actions, microCourse, { getStoreState }) => {
		// We need to track microCourse views, If a logged in user has opened a microCourse(engaged)
		const user = getStoreState().Auth?.user;
		if (!microCourse.isViewed) {
			const success = await MicroCourseModel.postMicroCourseView(microCourse.id, !user);
			if (success) microCourse.rawViewCount = (microCourse.rawViewCount || 0) + 1;
		}
	}),
	updateCompletedCount: action((state, chapterId) => {
		const { microCourse } = state;
		if (microCourse) {
			microCourse.chapters = microCourse.chapters?.map((chapter) =>
				chapter.id === chapterId ? { ...chapter, isMarkedComplete: true, markCompletedCount: (chapter.markCompletedCount || 0) + 1 } : chapter
			);
		}
	}),
};

export default PathwayStore;

import React, { FC, useMemo } from 'react';
import { Box, makeStyles, Paper, Link as MuiLink, Avatar } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'Stores';
import { LOGO, HEADER_DESKTOP_HEIGHT } from 'Features/Header/Constants';
import { LogOut } from 'react-feather';
import Typo from 'Components/Typo';
import { CURATOR_STUDIO_PATH, EXPLORE_HOME_PATH, USER_HOME_PROFILE_PATH } from 'Routes/RouteConstants';
import clsx from 'clsx';
import PictureModel from 'Models/Picture';
import UserCircle from 'Assets/icons/user-circle.svg';
import HeaderMenu, { HeaderMenuItem } from 'Components/HeaderMenu';
import { CURATOR_STUDIO_FAQ_PATH } from 'Routes/CuratorStudioNavigation';
import { getMenuItems } from 'Features/Header/utils';
import { THEME_PALETTE } from 'Theme/themeConstants';

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
	const history = useHistory();
	const { user } = useStoreState(({ Auth: { user } }) => ({ user }));
	const { logout } = useStoreActions(({ Auth: { logout } }) => ({ logout }));
	const handleLogout = async () => {
		await logout();
		history.push('/');
	};
	const classes = styles();
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const menuItems: HeaderMenuItem[] = useMemo(() => getMenuItems(user!, handleLogout), []);

	const userIcon = useMemo(() => {
		return (
			<Avatar
				alt="user profile"
				component={Link}
				to={USER_HOME_PROFILE_PATH}
				src={user?.profileImage?.url ? PictureModel.getTransformedUrl(user?.profileImage.url, 64, 64, 'face') : UserCircle}
				imgProps={{ height: 32, width: 32 }}
				className={clsx(classes.avatar, { [classes.avatarWithBorder]: !!user?.profileImage?.url })}
			/>
		);
	}, [user]);
	return (
		<Paper className={classes.root} square>
			<Box className={classes.restrictedContainer}>
				<MuiLink component={Link} to={EXPLORE_HOME_PATH} color="primary">
					<LogOut className={classes.exitIcon} />
				</MuiLink>
				<Box ml={5}>
					<MuiLink component={Link} to={CURATOR_STUDIO_PATH} underline="none" className={classes.logo}>
						<img src={LOGO} alt="Chet logo" height={32} />
						<Typo variant="h5" color="primary" weight="bold" className={classes.studio}>
							Studio
						</Typo>
					</MuiLink>
				</Box>
				<Box justifyContent="space-between" display="flex" className={classes.linksContainer}>
					{links.map((link) => (
						<Link key={link.id} to={link.path} className={classes.headerLink}>
							{link.label}
						</Link>
					))}
				</Box>
				{user && <HeaderMenu hoverOver={userIcon} menuItems={menuItems} />}
			</Box>
		</Paper>
	);
};

export default Header;

const styles = makeStyles((theme) => ({
	root: {
		position: 'sticky',
		top: 0,
		zIndex: 5,
		boxSizing: 'border-box',
		boxShadow: `0px 2px 0px 0px ${theme.palette.grey['600']}`,
		minHeight: HEADER_DESKTOP_HEIGHT,
		padding: theme.spacing(2.5, '172px'),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.up('xl')]: {
			width: '100%',
			margin: '0 auto',
			maxWidth: '100%',
		},
	},
	restrictedContainer: {
		maxWidth: 1440,
		margin: '0 auto',
		width: '100%',
		display: 'flex',
	},
	logo: {
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		borderRadius: '50%',
		boxSizing: 'border-box',
		height: 35,
		width: 35,
		[theme.breakpoints.up('sm')]: {
			height: 40,
			width: 40,
		},
	},
	avatarWithBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
	},
	studio: {
		marginLeft: '13px',
	},
	headerLink: {
		textDecoration: 'none',
		color: theme.palette.text.primary,
		fontSize: '1.125rem',
		lineHeight: 1.2,
		fontWeight: 500,
		'&:visited': { color: theme.palette.text.primary },
		'&:hover': { color: THEME_PALETTE.grey.A300 },
	},
	linksContainer: {
		marginLeft: 'auto',
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(9),
	},
	exitIcon: {
		transform: 'rotate(180deg)',
		height: 29,
		width: 32,
		color: theme.palette.primary.main,
		'&:visited': { color: theme.palette.primary.main },
	},
}));

const links = [
	{
		id: 'faq',
		path: CURATOR_STUDIO_FAQ_PATH,
		label: 'Studio FAQ',
	},
];

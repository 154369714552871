import { Box, makeStyles, Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';
import { User } from 'Models/User/@types';
import React, { FC } from 'react';
import useResponsive from 'Hooks/useResponsive';
import ProfileImageWithLogo, { ProfileImageWithLogoProps } from 'Components/ProfileImageWithLogo';
import UserModel from 'Models/User';
import { Font3Regular } from 'Theme';
import Typo from 'Components/Typo';
// TODO: Standardize component
interface AboutUserProps {
	user: User;
	onClick?: (event: React.MouseEvent, user: User) => void;
	color?: TypographyProps['color'];
	profileImageSize?: number;
	logoSize?: number;
	additionalInfo?: string | JSX.Element;
	classes?: AboutUserClasses;
	shouldReplaceDescriptionWithAdditionalInfo?: boolean;
	profileImageVariant?: ProfileImageWithLogoProps['profileImageVariant'];
}

interface AboutUserClasses {
	name?: string;
	aboutText?: string;
	root?: string;
	description?: string;
	avatarContainer?: string;
}

const AboutUser: FC<AboutUserProps> = (props) => {
	const { user, color, profileImageSize = 82, logoSize = 32, additionalInfo, classes: propClasses, shouldReplaceDescriptionWithAdditionalInfo = false, onClick, profileImageVariant } = props;
	const classes = useStyles();
	const { isMobile } = useResponsive();

	return (
		<Box mb={1} display="flex" alignItems={isMobile ? 'center' : undefined} className={clsx({ [classes.pointer]: !!onClick }, propClasses?.root)} onClick={(event) => onClick?.(event, user)}>
			<div className={clsx(classes.avatarContainer, propClasses?.avatarContainer)}>
				<ProfileImageWithLogo
					profileSize={profileImageSize}
					logoSize={logoSize}
					profileImage={user.profileImage?.url}
					companyLogo={user.companyLogo?.url}
					profileImageVariant={profileImageVariant}
				/>
			</div>
			<Box ml={1} className={clsx(propClasses?.aboutText, classes.aboutText)}>
				{(user.name || user.firstName) && (
					<Typography className={clsx(classes.userName, propClasses?.name)} color={color} variant="subtitle2">
						{UserModel.getUserFullName(user)}
					</Typography>
				)}
				<Typo variant="caption" limitLines={2} className={clsx(classes.aboutPerson, propClasses?.description)}>
					{shouldReplaceDescriptionWithAdditionalInfo && additionalInfo
						? additionalInfo
						: `${user.recentJobTitle?.trim() || ''} ${user.recentCompany ? `at ${user.recentCompany.trim()}` : ''}`}
				</Typo>
				{shouldReplaceDescriptionWithAdditionalInfo ? null : additionalInfo}
			</Box>
		</Box>
	);
};
export default AboutUser;

const useStyles = makeStyles((theme) => ({
	aboutText: {
		flex: 1,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		marginTop: 4,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginLeft: theme.spacing(1),
	},
	profileImage: {
		borderRadius: '50%',
		border: '0 solid transparent',
		width: 45,
		height: 45,
		background: theme.palette.primary.main,
	},
	largeProfileImage: {
		width: 82,
		height: 82,
		[theme.breakpoints.up('sm')]: {
			width: 104,
			height: 104,
		},
	},
	aboutPerson: {
		whiteSpace: 'pre-wrap',
		// whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: theme.palette.grey['700'],
	},
	pointer: { cursor: 'pointer' },
	avatarContainer: {
		position: 'relative',
		height: '100%',
	},
	companyLogo: {
		position: 'absolute',
		right: -6,
		bottom: 0,
		height: 22,
		width: 22,
	},
	companyLogoLarge: {
		height: 28,
		width: 28,
	},
	userName: {
		fontFamily: Font3Regular,
		fontSize: 16,
		fontWeight: 600,
	},
}));

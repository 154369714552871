import { action, Action, thunk, Thunk } from 'easy-peasy';
import { ChapterModel } from 'Models/Chapter';
// eslint-disable-next-line import/no-cycle
import { RootStore } from 'Stores';
import { JSONType, Params } from 'Typings/@types';
import type { Comment } from 'Models/Chapter/@types';
import helperUtils from 'Utils/helpers';

export interface ChapterState {
	comments?: Comment[];
	addComment: Thunk<ChapterState, { id: string; comment: { text: string } }, null, RootStore, Promise<JSONType | null>>;
	getComments: Thunk<ChapterState, { id: string; params: Params }, null, RootStore, Promise<Comment[]>>;
	likeComment: Thunk<ChapterState, { commentId: string; userId: string }, null, RootStore, Promise<Comment[]>>;
	removeLikeComment: Thunk<ChapterState, { commentId: string; likeId: string }, null, RootStore, Promise<Comment[]>>;
	setComments: Action<ChapterState, Comment[] | undefined>;
}

const ChapterStore: ChapterState = {
	comments: [],
	addComment: thunk(async (_actions, data, { getState, getStoreState }) => {
		const comment = await ChapterModel.addComment(data.id, data.comment);
		const state = getState();
		const storeState = getStoreState();
		state.comments = helperUtils.updateItemList(state.comments || [], { ...comment, user: storeState.Auth.user ?? undefined }, 'ADD');
		return comment;
	}),
	getComments: thunk(async (_actions, { id, params }, helpers) => {
		const comments = await ChapterModel.getComments(id, params);
		helpers.getState().comments = comments || [];
		return comments;
	}),
	likeComment: thunk(async (actions, { commentId, userId }, helpers) => {
		const res = await ChapterModel.likeComment({ commentId, userId });
		const { comments } = helpers.getState();
		const newComments = comments?.map((comment) => (comment.id === commentId ? { ...comment, isLiked: true, likeId: res.id } : comment));
		actions.setComments(newComments);
		return newComments || [];
	}),
	removeLikeComment: thunk(async (actions, { commentId, likeId }, helpers) => {
		await ChapterModel.removeLike(likeId);
		const { comments } = helpers.getState();
		const newComments = comments?.map((comment) => (comment.id === commentId ? { ...comment, isLiked: false } : comment));
		actions.setComments(newComments);
		return newComments || [];
	}),
	setComments: action((state, payload) => {
		state.comments = payload;
	}),
};

export default ChapterStore;

import React, { FC, useMemo, useState } from 'react';
import { alpha, Badge, Box, ButtonBase, Chip, ChipProps, Collapse, FormHelperText, makeStyles } from '@material-ui/core';
import { NameValuePair } from 'Typings/@types';
import { FormConfig, IFieldProps } from 'react-forms';
import { FormikProps } from 'formik';
import get from 'lodash/get';
import useResponsive from 'Hooks/useResponsive';
import clsx from 'clsx';
import { Font2Bold } from 'Theme';
import Spacer from './Spacer';
import ArrowRotatable from './ArrowRotatable';

export interface CategorisedChipSelectCategory {
	name: string | JSX.Element;
	key: string;
	options: NameValuePair[];
}

export interface CategorisedChipSelectProps extends IFieldProps {
	fieldProps?: CategorisedChipSelectFieldProps;
}
export interface CategorisedChipSelectFieldProps {
	categories: CategorisedChipSelectCategory[];
	chipProps?: Partial<Omit<ChipProps, 'onClick' | 'clickable' | 'label' | 'variant'>>;
	selectedClass?: string | string[];
	unselectedClass?: string | string[];
	defaultClass?: string | string[];
}

const CategorisedChipSelect: FC<CategorisedChipSelectProps> = ({ fieldConfig = {} as FormConfig, fieldProps = {} as CategorisedChipSelectFieldProps, formikProps = {} as FormikProps<unknown> }) => {
	const { categories, chipProps, selectedClass, unselectedClass, defaultClass } = fieldProps;
	const { valueKey } = fieldConfig;
	const classes = styles();
	const [selectedCategory, setSelectedCategory] = useState<CategorisedChipSelectCategory | undefined>();
	const values = get(formikProps, `values.${valueKey}`);
	const error = get(formikProps, `errors.${valueKey}`);
	const { isMobile } = useResponsive();
	const CollapseItem = useMemo(
		() => (
			<Collapse in={!!selectedCategory}>
				<Box className={classes.optionsContainer}>
					{selectedCategory?.options.map((option) => {
						const isSelected = values?.includes(option.value);
						return (
							<Chip
								{...chipProps}
								classes={{ ...chipProps?.classes, root: clsx(defaultClass), outlined: clsx(unselectedClass), colorPrimary: clsx(selectedClass) }}
								clickable
								color={isSelected ? 'primary' : undefined}
								variant={isSelected ? 'default' : 'outlined'}
								onClick={() => {
									// const tagWithTagValue = selectedCategory?.options.find((item) => item.value === option.value);
									if (!isSelected)
										formikProps.setFieldValue(
											valueKey,
											[...(values ?? []), option.value].filter((value) => !!value)
										);
									else
										formikProps.setFieldValue(
											valueKey,
											values?.filter((item: string) => item !== option.value)
										);
								}}
								// link="#"
								// tag={option}
								key={option.value}
								label={option.name}
							/>
						);
					})}
				</Box>
			</Collapse>
		),
		[selectedCategory?.key, values /* , chipProps, selectedClass, unselectedClass, defaultClass */]
	);
	return (
		<Box>
			<FormHelperText error={!!error}>{error ?? ' '}</FormHelperText>
			<Box className={classes.categoriesContainer}>
				{categories.map((category) => {
					const numSelectedValuesInCategory = category.options.filter((option) => values.includes(option.value)).length;
					return (
						<>
							<Badge color="primary" badgeContent={numSelectedValuesInCategory} showZero={false}>
								<ButtonBase
									className={clsx(classes.categoryName, { [classes.selectedCategory]: selectedCategory?.key === category.key })}
									onClick={() => {
										if (selectedCategory?.key === category.key) setSelectedCategory(undefined);
										else if (selectedCategory !== undefined) {
											setSelectedCategory(undefined);
											setTimeout(() => setSelectedCategory(category), 300);
										} else {
											setSelectedCategory(category);
										}
									}}
								>
									{category.name}
									<Spacer />
									<ArrowRotatable shouldBeRotated={selectedCategory?.key === category.key} className={classes.arrowRotatable} />
								</ButtonBase>
							</Badge>
							{isMobile && selectedCategory?.key === category.key ? CollapseItem : null}
						</>
					);
				})}
			</Box>
			{!isMobile ? CollapseItem : null}
		</Box>
	);
};

export default CategorisedChipSelect;

const styles = makeStyles((theme) => ({
	categoryName: {
		padding: theme.spacing(2),
		backgroundColor: theme.palette.grey['300'],
		borderRadius: theme.spacing(1),
		boxShadow: `0px 1px 4px 0px ${alpha(theme.palette.grey['800'], 0.11)}`,
		fontFamily: Font2Bold,
		flex: 1,
		minWidth: 335,
		maxWidth: 335,
		fontSize: 18,
		[theme.breakpoints.down('sm')]: {
			minWidth: 'unset',
			maxWidth: '100%',
		},
	},
	selectedCategory: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.primary.main,
	},
	categoriesContainer: {
		display: 'flex',
		gap: theme.spacing(2),
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	optionsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(3),
		gap: theme.spacing(1),
		borderRadius: theme.spacing(2.5),
		[theme.breakpoints.down('sm')]: {
			paddingTop: 0,
		},
		[theme.breakpoints.up('lg')]: {
			backgroundColor: theme.palette.grey['300'],
			marginTop: theme.spacing(3),
		},
	},
	arrowRotatable: {
		color: theme.palette.primary.main,
	},
}));

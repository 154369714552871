import { ContentDuration } from 'Models/Post/@types';
import _isEmpty from 'lodash/isEmpty';

export const getTime = (contentDuration?: ContentDuration, format?: TimeCustomizationFormat): string => {
	if (_isEmpty(contentDuration) || !contentDuration) return '';
	const { h, hs, m, ms, s, ss } = format || {};
	let time = '';
	const { hours, minutes, sec } = contentDuration;
	if (hours) {
		time = `${hours} ${hours > 1 ? hs || ' hrs ' : h || ' hr '}`;
	}
	if (minutes) {
		time += `${minutes} ${minutes > 1 ? m || 'min' : ms || 'min'} `;
	}
	if (sec) {
		time = `${time} ${sec} ${sec > 1 ? s || 's' : ss || 's'}`;
	}
	return time;
};

export const getTimeInMinutes = (contentDuration?: ContentDuration): number => {
	if (!contentDuration) return 0;
	const { hours, minutes, sec } = contentDuration;
	return Math.floor(hours * 60 + minutes + sec / 60);
};

export interface TimeCustomizationFormat {
	h?: string;
	hs?: string;
	m?: string;
	ms?: string;
	s?: string;
	ss?: string;
}

export const getTimeLargest = (contentDuration: ContentDuration, format?: Omit<TimeCustomizationFormat, 'ss'>, noPadding?: boolean): string => {
	const { hours, minutes, sec } = contentDuration;
	// eslint-disable-next-line no-nested-ternary
	return `${hours ? `${hours}:` : ''}${minutes || ''}${sec ? (minutes ? `:${sec}` : sec) : noPadding ? '' : ':00'}${
		// eslint-disable-next-line no-nested-ternary
		hours ? (hours > 1 ? format?.hs || 'hrs' : format?.h || 'hr') : minutes ? (minutes > 1 ? format?.ms || 'm' : format?.m || 'm') : format?.s || 's'
	}.`;
};

export const getTimeLargestOnly = (contentDuration: ContentDuration, format?: TimeCustomizationFormat): string => {
	const { hours, minutes, sec } = contentDuration;
	if (hours) return `${hours}${hours > 1 ? format?.hs || 'hrs' : format?.h || 'hr'}`;
	if (minutes) return `${minutes}${minutes > 1 ? format?.ms || 'm' : format?.m || 'm'}`;
	if (sec) return `${sec}${sec > 1 ? format?.ss || 's' : format?.s || 's'}`;
	return '';
};

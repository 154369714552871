import { request } from 'Resources/AxiosUtils';
import { JSONType, Params } from 'Typings/@types';
import { MainTagCategoryType, Tag, TagCategory } from './@types';
import { parseToTag } from './TagParsers';

class TagModel {
	static getAllTags = async (params?: Params): Promise<Tag[]> => {
		const res = await request<JSONType[]>({
			url: '/tags',
			method: 'GET',
			params,
		});
		return res.map(parseToTag);
	};

	static getTag = async (id: string, params?: Params): Promise<Tag> => {
		const res = await request<JSONType>({
			url: `/tags/${id}`,
			method: 'GET',
			params,
		});
		return parseToTag(res);
	};

	static getAllWebOnlyTagCategories = async (params?: Params): Promise<TagCategory[]> => {
		const res = await request<TagCategory[]>({
			url: 'tagCategories',
			params: { filter: { include: 'tags', where: { type: MainTagCategoryType }, includeWeb: true, ...params } },
		});
		return res?.map((tc) => ({ ...tc, tags: tc.tags?.map(parseToTag) }));
	};

	static getTagCategories = async (params?: Params): Promise<TagCategory[]> => {
		const res = await request<TagCategory[]>({
			url: 'tagCategories',
			params: { filter: { include: 'tags', ...params } },
		});
		return res?.map((tc) => ({ ...tc, tags: tc.tags?.map(parseToTag) }));
	};
}

export default TagModel;

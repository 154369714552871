/* eslint-disable camelcase */
import { MicroCourse } from 'Models/MicroCourse/@types';
import { Pathway } from 'Models/Pathway/@types';
import { Picture } from 'Models/Picture/@types';
import { parseUnsplashImagesToPicture } from 'Models/Picture/PictureParsers';
import { Post } from 'Models/Post/@types';
import { FeedEntity, UserFeedResult } from 'Models/User/@types';
import { request } from 'Resources/AxiosUtils';
import { JSONType } from 'Typings/@types';
import { AppConstants, RandomUserWithImage, SearchRes } from './@types';
import { parseToAppConstants } from './AppParsers';

class AppModel {
	static getAppConstants = async (): Promise<AppConstants> => {
		const appConstants = await request<JSONType>({
			url: 'appModels/constants',
			method: 'GET',
		});
		return parseToAppConstants(appConstants);
	};

	static setNoSignUpPrompt = (value: boolean): void => {
		localStorage.setItem('noPrompt', value.toString());
	};

	static getNoSignUpPrompt = (): boolean => {
		return localStorage.getItem('noPrompt') === 'true';
	};

	static setViewedAny = (value: boolean): void => {
		localStorage.setItem('viewedAny', value.toString());
	};

	static getViewedAny = (): boolean => {
		return localStorage.getItem('viewedAny') === 'true';
	};

	static isFeedMicroCourse = (feed: UserFeedResult['hit']): feed is MicroCourse & { doc_type: FeedEntity } => {
		return feed.doc_type === 'microCourse';
	};

	static isFeedPathway = (feed: UserFeedResult['hit']): feed is Pathway & { doc_type: FeedEntity } => {
		return feed.doc_type === 'pathway';
	};

	static isFeedPost = (feed: UserFeedResult['hit']): feed is Post & { doc_type: FeedEntity } => {
		return feed.doc_type === 'post';
	};

	static getUnsplashImages = async (query: string, page = 1): Promise<Picture[]> => {
		const res = await request<JSONType[]>({
			url: `appModels/unsplash/get-images`,
			params: {
				query,
				page,
				perPage: 16,
			},
		});
		return res?.map(parseUnsplashImagesToPicture);
	};

	static search = async (query: JSONType): Promise<SearchRes> => {
		const res = await request<SearchRes>({
			url: 'appModels/home-search',
			params: query,
		});
		return res;
	};

	static applyCurator = async (data: JSONType): Promise<JSONType> => {
		return request<JSONType>({
			url: 'appModels/become-curator-email',
			method: 'POST',
			data,
		});
	};

	static contactUs = async (data: JSONType): Promise<JSONType> => {
		return request<JSONType>({
			url: 'appModels/contact-us',
			method: 'POST',
			data,
		});
	};

	static getRandomUsersWithImage = (count = 3): Promise<RandomUserWithImage[]> => {
		return request<RandomUserWithImage[]>({
			url: '/AppModels/users-w-img',
			method: 'GET',
			params: { count },
		});
	};
}

export default AppModel;

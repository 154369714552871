import PathwayStepModel from 'Models/PathwayStep';
import { request } from 'Resources/AxiosUtils';
import { JSONType } from 'Typings/@types';
import { Pathway } from './@types';
import { parseToPathway } from './PathwayParsers';

class PathwayModel {
	static PATHWAY_VIEW_COOKIE_KEY = 'pathwayView';

	static getPathwayOverview = async (id: string): Promise<Pathway> => {
		const res = await request<JSONType>({
			url: `/pathways/${id}/overview`,
			method: 'GET',
		});
		return parseToPathway(res);
	};

	static getAllPathway = async (params?: JSONType): Promise<Pathway[]> => {
		const res = await request<JSONType[]>({
			url: '/pathways',
			method: 'GET',
			params,
		});
		return res.map(parseToPathway);
	};

	static getPathway = async (id: string, params?: JSONType): Promise<Pathway> => {
		const res = await request<JSONType>({
			url: `/pathways/${id}`,
			method: 'GET',
			params,
		});
		return parseToPathway(res);
	};

	static createView = async (id: string, userId: string): Promise<boolean> => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [_, success] = await request<[unknown, boolean]>({
			url: `/pathways/${id}/view`,
			method: 'post',
			data: {
				userId,
			},
		});
		return success;
	};

	static createRawView = async (id: string): Promise<JSONType> => {
		const res = await request<JSONType>({
			url: `/views/rvp`,
			method: 'post',
			data: {
				entityId: id,
				entityType: 'Pathway',
			},
		});
		return res;
	};

	static getCompletedPathwayStepsLS = (pathway: Pathway): number => {
		let completedPathwayStepsNonLoggedInUser = 0;
		pathway?.pathwaySteps?.forEach((ps) => {
			const completedMicroCourse = PathwayStepModel.getCompletedMicroCoursesLS(ps);
			// eslint-disable-next-line no-plusplus
			if (ps.microCourses?.length === completedMicroCourse) completedPathwayStepsNonLoggedInUser++;
		});
		return completedPathwayStepsNonLoggedInUser;
	};

	static isPathwayViewedLS = (id: string): boolean => {
		const ids = JSON.parse(localStorage.getItem(PathwayModel.PATHWAY_VIEW_COOKIE_KEY) || '[]') as string[];
		return ids.includes(id);
	};

	static setPathwayViewLS = (id: string): void => {
		const ids = JSON.parse(localStorage.getItem(PathwayModel.PATHWAY_VIEW_COOKIE_KEY) || '[]') as string[];
		if (ids.includes(id)) return;
		ids.push(id);
		localStorage.setItem(PathwayModel.PATHWAY_VIEW_COOKIE_KEY, JSON.stringify(ids));
	};

	static clearPathwayViewLS = (): void => localStorage.removeItem(PathwayModel.PATHWAY_VIEW_COOKIE_KEY);
}

export default PathwayModel;
